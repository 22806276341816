import React from "react";
import {observer} from "mobx-react";
import Timeline, {
    TodayMarker,
    CursorMarker,
    TimelineHeaders,
    SidebarHeader,
    DateHeader
} from 'react-calendar-timeline';
import moment from "moment";
import ModelDetailsIndex from "app/modules/main/components/admin/books/details/modal";
import {blue} from "@mui/material/colors";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {useInstance} from "react-ioc";
import {TimelineStore} from "app/modules/main/components/admin/books/timeline/TimelineStore";
import {Box} from "@mui/material";

export default observer(() => {
    let store = useInstance(TimelineStore);

    return <Box className={"timeline"}>
        <br/>
        <br/>
        <Timeline
            groups={store.groups}
            items={store.groupItem}
            defaultTimeStart={moment().add(-3, 'day')}
            defaultTimeEnd={moment().add(1, 'month')}
            rightSidebarWidth={200}
            sidebarWidth={350}

        >
            <TodayMarker />
            <CursorMarker />
            <TimelineHeaders style={{ background: blue[50] }}>
                <SidebarHeader>
                    {({ getRootProps }) => {
                        return <div {...getRootProps()}><BoxCenter sx={{ pt: 2, fontWeight: "bold" }}>Property</BoxCenter></div>
                    }}
                </SidebarHeader>
                <SidebarHeader variant="right" headerData={{someData: 'extra'}}>
                    {({ getRootProps, data }) => {
                        return <div {...getRootProps()}><BoxCenter sx={{ pt: 2, fontWeight: "bold" }}>Total</BoxCenter></div>
                    }}
                </SidebarHeader>
                <DateHeader
                    unit="month"
                    labelFormat="MMMM, YYYY" />
                <DateHeader
                    unit="day"
                    labelFormat="DD"
                    intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
                        return <div {...getIntervalProps()}>
                            <BoxCenter sx={{ paddingTop: "4px" }}>{intervalContext.intervalText}</BoxCenter>
                        </div>
                    }}/>
            </TimelineHeaders>
        </Timeline>
        { store.selectedBookId > 0 && <ModelDetailsIndex close={() => store.selectBook(null)} bookId={store.selectedBookId}/>}
    </Box>;
});
