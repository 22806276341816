export const StringHelper = {
    formatYesNo: (value) => {
        return value ? "Yes" : "No";
    },
    formatNull: (value) => {
        return value ? value : "N/A";
    },
    formatActive: (value) => {
        return value ? "Active" : "Deactivated";
    },
    formatTenantOwnerStatus: (value) => {
        switch (value) {
            case 1:
                return "Active";
            case 2:
                return "Inactive";
            case 3:
                return "Suspended";
            case 4:
                return "InfoRequested";
            default:
                return "Invited";
        }

        return value ? "Active" : "Deactivated";
    },
    format24Hours: (value: number) => {
        if(value == 0) return "12am";
        if (value == 12) return "12pm";
        if(value < 12) return value + "am";
        return value - 12 + "pm";
    },
    formatPhoneNumber: (phoneNumberString) => {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            let intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }

        return null;
    },

}
