import {observer} from "mobx-react";
import React from "react";
import {Link, Stack, Typography} from "@mui/material";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {green, red, yellow} from "@mui/material/colors";
import {
    GetReservationCompletionInfoModelStatus,
    PropertyReservationStatus
} from "app/modules/main/api/main-clients.api";
import * as moment from "moment";

export interface IReservationPaymentMode {
    paymentStatus?: GetReservationCompletionInfoModelStatus | null;
    receiptUrl?: string | null;
    paymentCreatedAt?: moment.Moment | null;
    status?: PropertyReservationStatus | null;
}

export default observer((props : { details: IReservationPaymentMode }) => {
    return <React.Fragment>
        <BoxCenter>
            {props.details.paymentStatus == GetReservationCompletionInfoModelStatus.Success &&
            <Stack direction={"column"} spacing={1} textAlign={"center"}>
                <Typography variant={"body2"} sx={{ fontSize: "14px", fontStyle: "italic", color: green[800] }}>Payment Status: <b>Success</b></Typography>
                {props.details.status == PropertyReservationStatus.Requested && <Typography variant={"body2"} sx={{ fontSize: "14px", fontStyle: "italic" }}>Our Agent will reach you out to confirm this reservation.</Typography>}
            </Stack>}

            {props.details.paymentStatus == GetReservationCompletionInfoModelStatus.Failed &&
            <Stack direction={"column"} spacing={1} textAlign={"center"}>
                <Typography variant={"body2"} sx={{ fontSize: "14px", fontStyle: "italic", color: red[800] }}>Payment Status: <b>FAILED</b></Typography>
                {props.details.status == PropertyReservationStatus.Requested && <Typography variant={"body2"} sx={{ fontSize: "14px", fontStyle: "italic" }}>Our Agent will reach you out to confirm this reservation, and help with a payment method.</Typography>}
            </Stack>}

            {props.details.paymentStatus == GetReservationCompletionInfoModelStatus.Pending &&
            <Stack direction={"column"} spacing={1} textAlign={"center"}>
                <Typography variant={"body2"} sx={{ fontSize: "14px", fontStyle: "italic", color: yellow[800] }}>Payment Status: <b>Pending</b></Typography>
                {props.details.status == PropertyReservationStatus.Requested && <Typography variant={"body2"} sx={{ fontSize: "14px", fontStyle: "italic" }}>Our Agent will reach you out to confirm this reservation.</Typography>}
            </Stack>}
        </BoxCenter>
        {props.details.paymentStatus != GetReservationCompletionInfoModelStatus.Failed && props.details.receiptUrl?.length > 0 && <BoxCenter>
            <Link href={props.details.receiptUrl} variant={"subtitle2"} target={"_blank"}>Download Receipt</Link>
        </BoxCenter>}
    </React.Fragment>
})
