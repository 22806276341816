import React, {useEffect} from "react";
import TimelineIndex from "app/modules/main/components/admin/books/timeline/timeline";
import {observer} from "mobx-react";
import {provider, useInstance} from "react-ioc";
import {TimelineStore} from "app/modules/main/components/admin/books/timeline/TimelineStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";

export default provider(TimelineStore)(observer(() => {
    let adminStore = useInstance(AdminStore);
    let store = useInstance(TimelineStore);

    useEffect(
        () => {
            adminStore.selectMenu(AdminMenu.Books);
            store.init();
        },[]);

    if(store.isInitLoading) {
        return <Loading />
    }

    return <TimelineIndex/>;
}));
