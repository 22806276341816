import {Breadcrumbs, Button, Grid, Pagination, Typography} from "@mui/material";
import React from "react";
import {observer} from "mobx-react";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {useInstance} from "react-ioc";
import {SearchStore} from "app/modules/main/components/main/search/SearchStore";
import {MainStore} from "app/modules/main/stores/MainStore";
import {ResultItem} from "app/modules/main/components/main/search/result-item";

export default observer(() => {
    const searchStore = useInstance(SearchStore);
    const mainStore = useInstance(MainStore);

    let state = searchStore.searchParams.state?.length > 0 ? mainStore.clientData.states.find(x => x.code == searchStore.searchParams.state) : null;

    let bread = <Breadcrumbs separator="›">
        {searchStore.searchParams.state?.length > 0 ? <Button variant={"text"} sx={{ minWidth: 0, padding: 0, fontSize: "12px" }} onClick={() => searchStore.clickOnCountry()}>
            USA
        </Button> : <Typography color="text.primary" sx={{ fontSize: "12px" }}>USA</Typography>}

        {searchStore.searchParams.city?.length > 0 ?
            <Button variant={"text"} sx={{ minWidth: 0, padding: 0, fontSize: "12px" }} onClick={() => searchStore.clickOnState()}>
                {state?.name}
            </Button> : state?.name?.length > 0 && <Typography color="text.primary" sx={{ fontSize: "12px" }}>{state?.name}</Typography>}

        {searchStore.searchParams.city?.length > 0 && <Typography color="text.primary" sx={{ fontSize: "12px" }}>{searchStore.searchParams.city}</Typography>}
    </Breadcrumbs>;

    return (<Grid container>
        <Grid item xs={12} sx={{ display: { xs: "block", md: "none" }, p: 1 }}>
            <Grid container>
                <Grid item xs={12} sx={{ pb: 2 }}>{bread}</Grid>
                <Grid item xs={12}><Typography variant={"h6"} align={"center"}>{searchStore.getTitle() + " Vacation Rentals"}</Typography></Grid>
                <Grid item xs={12} textAlign={"center"}>({searchStore.rangeStart()}-{searchStore.rangeEnd()} of {searchStore.totalRows} rentals)</Grid>
            </Grid>
            <Grid container>
                {searchStore.items?.map((x) => <ResultItem key={x.id} item={x} />)}
            </Grid>
            <BoxCenter sx={{ pt: 1, pb: 2 }}>
                <Pagination count={searchStore.totalPages()} size={"large"} page={searchStore.currentPage()} onChange={(e, page) => searchStore.changePage(page)} />
            </BoxCenter>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "block" }, pl: 2, pr: 2 }}>
            <Grid container>
                <Grid item xs={8}><Typography variant={"h6"}>{searchStore.getTitle() + " Vacation Rentals"}</Typography></Grid>
                <Grid item xs={4} textAlign={"right"} sx={{ pr: 2 }}>{searchStore.rangeStart()}-{searchStore.rangeEnd()} of {searchStore.totalRows} rentals</Grid>
                <Grid item xs={12}>{bread}</Grid>
            </Grid>
            <Grid container>
                {searchStore.items?.map((x) => <ResultItem key={x.id} item={x} />)}
            </Grid>
            <BoxCenter sx={{ pt: 1, pb: 2 }}>
                <Pagination count={searchStore.totalPages()} size={"large"} page={searchStore.currentPage()} onChange={(e, page) => searchStore.changePage(page)} />
            </BoxCenter>
        </Grid>
    </Grid>);
})
