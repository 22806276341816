import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {action, makeObservable, observable} from "mobx";
import {
    IntegrationReservationTimeLine,
    PropertyTimeLineGroup,
    PropertyTimeLineReservation
} from "app/modules/main/api/admin-clients.api";
import moment from "moment";
import {CSSProperties} from "@mui/styles";
import {ReactNode} from "react";
import React from "react";
import {Box} from "@mui/material";

export class TimelineStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    items: PropertyTimeLineGroup[] = null;

    groups: { id: string, title: ReactNode, type: string }[] = [];
    groupItem: { id: number, item: any, group: string, title: string, start_time: moment.Moment, end_time: moment.Moment, canMove: boolean, canResize: boolean, canChangeGroup: boolean, bgColor?: string, itemProps: { onDoubleClick: () => void, style?: CSSProperties } }[] = [];

    selectedBook: PropertyTimeLineReservation = null;
    selectedExternalBook: PropertyTimeLineReservation = null;
    selectedIntegrationExternalBook: IntegrationReservationTimeLine = null;

    constructor() {
        super();

        makeObservable(this, {
            items: observable,
            selectedBook: observable,
            groupItem: observable,
            groups: observable,
            selectedExternalBook: observable,
            selectedIntegrationExternalBook: observable,

            selectBook: action,
            deleteExternalBook : action
        });
    }

    selectBook = (item: PropertyTimeLineReservation) => {
        this.selectedBook = item;
        this.selectedExternalBook = null;
        this.selectedIntegrationExternalBook = null;
    };

    selectExternalBook = (item: PropertyTimeLineReservation) => {
        this.selectedBook = null;
        this.selectedExternalBook = item;
        this.selectedIntegrationExternalBook = null;
    };

    selectIntegrationExternalBook = (item: IntegrationReservationTimeLine) => {
        this.selectedBook = null;
        this.selectedExternalBook = null;
        this.selectedIntegrationExternalBook = item;
    };

    deleteExternalBook = async () => {
        await this.wrapLoadingApiCall(() => {
            this.apiClients.integrationClient.remove(this.selectedExternalBook.id);
            this.groupItem = this.groupItem.filter(x => x.item != this.selectedExternalBook);

            this.selectedExternalBook = null;
        });
    }

    init = async () => {
        await this.wrapInitLoadingApiCall(async () => {
            const result = await this.apiClients.calendarClient.getTimeLineItems();
            const resultIntegration = await this.apiClients.integrationClient.getIntegrationReservationTimeLineGroups();

            let groups= result.map(x => ({
                id: x.id,
                title: x.name,
            }));

            for(let i = 0; i < resultIntegration.length; i++) {
                let item = resultIntegration[i];

                if(!groups.find(x => x.id == item.id)) {
                    groups.push({
                        id: item.id,
                        title: item.name
                    });
                }
            }

            for(let i = 0; i < groups.length; i++) {
                this.groups.push({
                    id: groups[i].id.toString(),
                    title: groups[i].title,
                    type: null
                });

                let integration = resultIntegration.find(x => x.id == groups[i].id);
                let discrepancies = integration?.reservations.filter(x => !x.reservationId).length;

                this.groups.push({
                    id: 'integration_' + groups[i].id,
                    title: <Box component={"span"}>Integration: {groups[i].title} {(discrepancies > 0 ? <Box component={"span"} sx={{ color: "#d31b1b" }}>({discrepancies.toString()})</Box> : '')}</Box>,
                    type: 'Hosttools'
                });
            }

            console.log(this.groups);

            this.groupItem.length = 0;

            for(let i = 0; i < result.length; i++) {
                let group = result[i];
                for(let j = 0; j < group?.reservations?.length; j++) {
                    let item = group.reservations[j];

                    this.groupItem.push({
                        id: item.id,
                        group: group.id.toString(),
                        title: item.name,
                        canChangeGroup: false,
                        canMove: false,
                        canResize: false,
                        start_time: item.from,
                        end_time: item.to,
                        bgColor: "rgb(33, 150, 243)",
                        itemProps: {
                            onDoubleClick: () => item.type == "External" ? this.selectExternalBook(item) : this.selectBook(item)
                        },
                        item: item
                    });
                }
            }

            for(let i = 0; i < resultIntegration.length; i++) {
                let group = resultIntegration[i];
                for(let j = 0; j < group?.reservations?.length; j++) {
                    let item = group.reservations[j];

                    this.groupItem.push({
                        id: item.id,
                        group: 'integration_' + group.id,
                        title: `${item.guestName}`,
                        canChangeGroup: false,
                        canMove: false,
                        canResize: false,
                        start_time: item.from,
                        end_time: item.to,
                        bgColor: item.reservationId > 0 ? "#539c0b" : "#d31b1b",
                        itemProps: {
                            onDoubleClick: () => this.selectIntegrationExternalBook(item)
                        },
                        item: item
                    });
                }
            }
        });
    }
}
