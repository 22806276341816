import {action, makeObservable, observable, runInAction} from "mobx";
import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {
    CalendarMonthGroup,
    PropertyListFilters,
    PropertyShortDetailsModel,
    PropertyShortListModel, UpdateRateRequest
} from "app/modules/main/api/admin-clients.api";
import {FieldState, FormState} from "formstate";
import {int} from "app/modules/common/form/validators";

export class CalendarStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    propertyId: number = null;
    propertyDetails: PropertyShortDetailsModel = null;

    year: number = null;
    month: number = null;
    selectedDates: number[] = [];

    monthGroups: CalendarMonthGroup[] = null;
    properties: PropertyShortListModel[] = null;

    formState = new FormState({
        defaultRate: new FieldState(null).validators(int),
    });

    selectedFormState = new FormState({
        rate: new FieldState(null),
        discountRate: new FieldState(null),
    });

    constructor() {
        super();

        makeObservable(this, {
            propertyId: observable,
            selectedDates: observable,
            propertyDetails: observable,

            loadData: action,
            init: action,
            selectProperty: action,
            selectDate: action,
            loadMonthData: action,
            markAsAvailable: action,
            markAsUnavailable: action,
            loadPropertyData: action,
            listProperty: action,
            delistProperty: action
        });
    }

    loadData = async () => {
        [this.monthGroups, this.propertyDetails] = [
            await this.apiClients.calendarClient.getMonths(this.propertyId, this.year, this.month, 12),
            await this.apiClients.adminPropertyClient.getShortDetails(this.propertyId)
        ];

        this.formState.$.defaultRate.value = this.propertyDetails.rate;
    }

    loadMonthData = async () => {
        [this.monthGroups] = [
            await this.apiClients.calendarClient.getMonths(this.propertyId, this.year, this.month, 12)
        ];
    }

    loadPropertyData = async () => {
        const [details] = [
            await this.apiClients.adminPropertyClient.getShortDetails(this.propertyId)
        ];

        runInAction(() => {
            this.propertyDetails = details;

            let found = this.properties.find(x => x.id == details.id);

            if(found != null) {
                found.status = details.status;
            }
        })
    }

    markAsAvailable = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.calendarClient.markDatesAsAvailable(this.propertyId, this.selectedDates);
            await this.loadMonthData();
        });

        runInAction(() => {
            this.selectedDates.length = 0;
        })
    }

    markAsUnavailable = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.calendarClient.markDatesAsUnavailable(this.propertyId, this.selectedDates);
            await this.loadMonthData();

            runInAction(() => {
                this.selectedDates.length = 0;
            })
        });
    }

    listProperty = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.adminPropertyClient.list(this.propertyId);
        });

        await this.loadPropertyData();
    }

    delistProperty = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.adminPropertyClient.delist(this.propertyId);
        });

        await this.loadPropertyData();
    }

    updateDefaultRate = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.adminPropertyClient.setDefaultPrice(this.propertyId, this.formState.$.defaultRate.value);
        });

        await this.loadData();
    }

    updateSelectedRate = async () => {
        await this.selectedFormState.validate();

        if(this.selectedFormState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.calendarClient.updateRate(this.propertyId, new UpdateRateRequest({
                dates: this.selectedDates,
                rate: this.selectedFormState.$.rate.value ?? null,
                discountRate: this.selectedFormState.$.discountRate.value ?? null
            }));
        });

        await this.loadData();

        runInAction(() => {
            this.selectedDates.length = 0;
            this.selectedFormState.$.rate.value = null;
            this.selectedFormState.$.discountRate.value = null;
        })
    }

    selectProperty = async (propertyId) => {
        if(this.propertyId != propertyId) {
            this.propertyId = propertyId;
            await this.wrapLoadingApiCall(async () => {
                await this.loadData();
            });
        }
    }

    selectDate = (date) => {
        let index = this.selectedDates.indexOf(date);
        if(index >= 0) {
            this.selectedDates.splice(index, 1);
        } else {
            this.selectedDates.push(date);
        }
    }

    init = async () => {
        this.year = (new Date()).getFullYear();
        let month = (new Date()).getMonth() + 1;
        let periods = Math.floor(month/3);
        this.month = Math.floor(month/3) * 3;

        if(periods%3 == 0) {
            this.month -= 3;
        }

        if(this.month < 0) {
            this.year -= 1;
            this.month = 12 + this.month;
        }

        await this.wrapInitLoadingApiCall(async () => {
            [this.properties] = [
                await this.apiClients.adminPropertyClient.getShortList(new PropertyListFilters({}))
            ];

            if(this.properties?.length > 0) {
                this.propertyId = this.properties[0].id;

                await this.loadData();
            }
        })
    }
}
