import {BaseStore} from "app/modules/auth/services/BaseStore";
import {FieldState, FormState} from "formstate";
import {required} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/auth/services/AxiosBaseClient";
import {action, makeObservable, observable, runInAction} from "mobx";
import {ApiErrorHandler, ApiErrorTryGetMessage} from "app/modules/common/static/ErrrorHandler";
import {ResetPasswordRequest} from "app/modules/auth/api/clients.api";
import queryString from 'query-string';
import {AnalyticsService} from "app/modules/main/stores/AnalyticsService";

export class SetPasswordStore extends BaseStore {
    @inject apiClients: ApiClients;
    @inject analyticsService: AnalyticsService;

    isLoading: boolean = false;
    isSuccess: boolean = false;
    isWrongParams: boolean = false;
    errorMessage: string = null;

    email: string = null;
    token: string = null;

    formState = new FormState({
        password: new FieldState('').validators(required),
        confirmPassword: new FieldState('').validators(required)
    });

    constructor() {
        super();

        makeObservable(this, {
            isLoading: observable,
            isSuccess: observable,
            isWrongParams: observable,
            errorMessage: observable,

            submit: action,
            init: action
        });
    }

    submit = async () => {
        this.errorMessage = null;

        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        if(this.formState.$.password.value != this.formState.$.confirmPassword.value) {
            this.formState.$.confirmPassword.setError("Passwords don't match.");
            return;
        }

        runInAction(() => { this.isLoading = true; })

        this.executeRecaptcha(async (token) => {
            try {
                let result = await this.apiClients.authClient.resetPasswordRequest(new ResetPasswordRequest({
                    token: this.token,
                    email: this.email,
                    password: this.formState.$.password.value
                }), token);

                if(result.success) {
                    this.analyticsService.resetPasswordPassword();
                    runInAction(() => { this.isSuccess = true; });
                } else {
                    runInAction(() => {
                        this.errorMessage = result.error;
                    });
                }
            } catch(ex) {
                if(!ex.isCodedExceptionApi) {
                    runInAction(() => {
                        this.errorMessage = "Unexpected error";
                    });
                }

                const errorMessage = ApiErrorTryGetMessage(ex);

                if(errorMessage?.length > 0) {
                    runInAction(() => {
                        this.errorMessage = errorMessage;
                    });
                } else {
                    ApiErrorHandler(ex);
                }
            }
            finally {
                runInAction(() => { this.isLoading = false; })
            }
        });
    }

    init = () => {
        const parsed = queryString.parse(location.search);

        this.email = parsed.email as string;
        this.token = parsed.token as string;

        this.isWrongParams = !(this.email?.length > 0 && this.token.length > 0);
    }
}
