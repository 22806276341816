import React from "react";
import {BookRatesDetails} from "app/modules/main/api/main-clients.api";
import {Grid, Stack, Typography} from "@mui/material";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import {blue} from "@mui/material/colors";

export default (props : { rates: BookRatesDetails }) => {
    return <Grid container>
        <Grid item xs={6}>
            <Stack>
                <Typography variant={"body2"} sx={{ pb: 1 }}><b>Rent ({props.rates.dayRates.length} Night{props.rates.dayRates.length > 1 ? "s" : ""})</b></Typography>
                <Typography variant={"body2"}>Fees</Typography>
                {props.rates.fees.map((x, index) => <Typography key={index} variant={"body2"} sx={{ fontSize: "12px", pl: 2 }}>{x.name}</Typography>)}
                {props.rates.discounts.length > 0 && <Typography variant={"body2"} sx={{ pt: 1 }}>Discounts</Typography>}
                {props.rates.discounts.length > 0 && props.rates.discounts.map((x, index) => <Typography key={index} variant={"body2"} sx={{ fontSize: "12px", pl: 2 }}>{x.name}</Typography>)}
                <Typography variant={"body2"} sx={{ pt: 1 }}>Taxes</Typography>
                {props.rates.taxes.map((x, index) => <Typography key={index} variant={"body2"} sx={{ fontSize: "12px", pl: 2 }}>{x.name}</Typography>)}
            </Stack>
        </Grid>
        <Grid item xs={6}>
            <Stack sx={{ textAlign: "right", pr: 1 }}>
                <Typography variant={"body2"} sx={{ pb: 1 }}><b>{NumberHelper.formatUSDollar(props.rates.totalDayRent)}</b></Typography>
                <Typography variant={"body2"}>{NumberHelper.formatUSDollar(props.rates.totalFees)}</Typography>
                {props.rates.fees.map((x, index) => <Typography key={index} variant={"body2"} sx={{ fontSize: "12px" }}>{NumberHelper.formatUSDollar(x.rate)}</Typography>)}
                {props.rates.discounts?.length > 0 && <Typography variant={"body2"} sx={{ pt: 1 }}>{NumberHelper.formatUSDollar(-1*props.rates.totalDiscounts)}</Typography>}
                {props.rates.discounts?.length > 0 && props.rates.discounts.map((x, index) => <Typography key={index} variant={"body2"} sx={{ fontSize: "12px" }}>{NumberHelper.formatUSDollar(-1*x.rate)}</Typography>)}
                <Typography variant={"body2"} sx={{ pt: 1 }}>{NumberHelper.formatUSDollar(props.rates.totalTaxes)}</Typography>
                {props.rates.taxes.map((x, index) => <Typography key={index} variant={"body2"} sx={{ fontSize: "12px" }}>{NumberHelper.formatUSDollar(x.rate)}</Typography>)}
            </Stack>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: blue[200], p: 1, mt: 1 }}>
            <Stack>
                <Typography variant={"body2"}><b>Total</b></Typography>
            </Stack>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: blue[200], p: 1, pr: 1, mt: 1 }}>
            <Stack sx={{ textAlign: "right" }}>
                <Typography variant={"body2"}><b>{NumberHelper.formatUSDollar(props.rates.total)}</b></Typography>
            </Stack>
        </Grid>
    </Grid>
}
