import {Grid, Typography} from "@mui/material";
import React from "react";

const BreakElement = () => <div>
    <br/>
    <br/>
</div>

const Paragraph = (props: { children: any }) => <div>
    <Typography variant={"body1"} sx={{ paddingBottom: 1 }}>
        {props.children}
    </Typography>
</div>

const Title = (props: { children: any }) => <div>
    <BreakElement/>
    <Typography variant={"h4"}>
        {props.children}
    </Typography>
    <BreakElement/>
</div>

export default () => {
    return <Grid container sx={{ width: "100%", paddingTop: "20px" }}>
        <Grid item xs={0} sm={1} md={2} />
        <Grid item xs={12} sm={10} md={8}>
            <Title>
                Information security at SolArt Group Inc
            </Title>
            <Paragraph>
                We are committed to ensuring your information is safe with SolArt Group Inc. For issues, concerns, or questions about SolArt Group Inc information security, please contact us at <a href="mailto:security@solartgroupinc.com" target="_blank" rel="noopener noreferrer">security@solartgroupinc.com</a>.
            </Paragraph>
            <Paragraph>
                If you're a security researcher and believe you've discovered a security-related issue with our systems, we appreciate your help in disclosing the issue to us responsibly.
            </Paragraph>
            <Paragraph>
                If you’d like to submit encrypted reports to us, please contact <a href="mailto:security@solartgroupinc.com" target="_blank" rel="noopener noreferrer">security@solartgroupinc.com</a> for the proper keys and reporting channels.
            </Paragraph>
            <BreakElement/>
            <BreakElement/>
            <Title>
                Security Capabilities and Policy For Transmission Of Payment
            </Title>
            <Paragraph>
                <b>1. What information do we collect and how do we use it?</b>
                <br/>
                When you place an order through solartgroupinc.com you need to be registered and logged into your account. From your account, we will capture your name, email address, phone number. You will be required to manually enter your credit card details. This information allows us to process and fulfill your order successfully.
            </Paragraph>
            <Paragraph>
                <b>2. How does SolArt Group Inc protect customer information?</b>
                <br/>
                When you place orders, we use a secure server. The secure server software (SSL) encrypts all information you input before it is sent to us. Furthermore, all of the sensitive customer data we collect is protected by several layers of encryption and several layers of security to prevent unauthorized access.
            </Paragraph>
            <Paragraph>
                <b>3. Credit Card Details</b>
                <br/>
                All credit card payments are processed securely through Payment Gateway. Payment by credit card is the only way to place an order. SolArt Group Inc does not store any credit card information on our servers.
            </Paragraph>
            <Paragraph>
                <b>4. Payment Information</b>
                <br/>
                When you initially provide or update your payment information, we transmit it via an encrypted connection to Payment Processor. Payment Processor uses and processes your payment information in accordance with Privacy Policy. SolArt Group Inc does not store your payment information, other than your zip code and country, which we require for billing and to comply with tax and other government regulations.
            </Paragraph>
            <Paragraph>
                <b>5. Your Consent</b>
                <br/>
                By using our website, you consent to the collection and use of this information. If we decide to change our privacy policy, we will post those changes so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.
            </Paragraph>
        </Grid>
        <Grid item xs={0} sm={1} md={2} />
    </Grid>
}
