import {
    AppBar,
    Avatar,
    Divider, Drawer,
    IconButton, Link, List, ListItem, ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Toolbar,
    Typography
} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import React from "react";
import {observer} from "mobx-react";
import {blue, blueGrey} from "@mui/material/colors";
import Icon from "../../../../../assets/favicon-32x32.png";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from "react-router-dom";
import {useInstance} from "react-ioc";
import {MainStore} from "app/modules/main/stores/MainStore";

export default observer(() => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const mainStore = useInstance(MainStore);

    return (<AppBar position="static">
            <Toolbar sx={{ backgroundColor: blueGrey[800] }}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                    onClick={() => setOpen(true)}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    anchor={"left"}
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                >
                    <List sx={{ minWidth: "250px" }}>
                        <ListItem>
                            <ListItemText primary="Company" />
                        </ListItem>
                        <ListItem>
                            <ListItemButton>
                                <ListItemText primary="About Us" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary="Help" />
                        </ListItem>
                        <ListItem sx={{ pl: 4 }}>
                            <ListItemText primary="+1 (916) 520-8246" />
                        </ListItem>
                    </List>
                </Drawer>
                <BoxCenter sx={{ flexGrow: 1, cursor: "pointer" }} onClick={() => navigate("/")}>
                    <Avatar sx={{ m: 1, bgcolor: '#010101' }}>
                        <img src={Icon}/>
                    </Avatar>
                    <Typography variant="subtitle1" component="div"  textAlign={"center"}>
                        SolArt Group Inc
                    </Typography>
                </BoxCenter>
                <BoxCenter sx={{ display: { xs: "none", md: "block" }, pr: 4, cursor: "pointer" }}>
                    <Link color={blue[50]}><Typography variant="subtitle2" component="div"  textAlign={"center"}>About Us</Typography></Link>
                </BoxCenter>
                <BoxCenter sx={{ display: { xs: "none", md: "block" } }}>
                    <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }} textAlign={"center"}>
                        Support: +1 (916) 520-8246
                    </Typography>
                </BoxCenter>
                <div>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuList dense>
                            {mainStore.userData?.isAuthenticated && <MenuItem onClick={() => navigate("/account")}>
                                <ListItemText>Account</ListItemText>
                            </MenuItem>}
                            {mainStore.userData?.isAdmin && <MenuItem onClick={() => navigate("/admin")}>
                                <ListItemText>Admin Panel</ListItemText>
                            </MenuItem>}
                            {mainStore.userData?.isAuthenticated && <Divider />}
                            {!mainStore.userData?.isAuthenticated && <MenuItem  onClick={() => navigate("/login")}>
                                <ListItemText>Login</ListItemText>
                            </MenuItem>}
                            <MenuItem onClick={() => navigate("/auth/forgot-password")}>
                                <ListItemText>Forgot Password</ListItemText>
                            </MenuItem>
                            {!mainStore.userData?.isAuthenticated && <MenuItem onClick={() => navigate("/auth/register")}>
                                <ListItemText>Register</ListItemText>
                            </MenuItem>}
                            {mainStore.userData?.isAuthenticated && <Divider />}
                            {mainStore.userData?.isAuthenticated && <MenuItem onClick={() => mainStore.logout()}>
                                <ListItemText>Logout</ListItemText>
                            </MenuItem>}
                        </MenuList>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>);
})
