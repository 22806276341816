import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {int, int100Required, int24hours, int24hoursRequired, required} from "app/modules/common/form/validators";
import {action, makeObservable, observable, runInAction} from "mobx";
import {
    AcceptedGuestType,
    CancelPolicyChargeType,
    PolicyCancel,
    PolicyEditModel, PolicyNoShow, PolicyOther, PolicyPets, PolicyPetsType, PolicyStay, PolicyStringPreviewModel,
    PolicyType
} from "app/modules/main/api/admin-clients.api";

export class DetailsStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    policyId: number = null;
    name: string = null;

    policyString: string = null;

    formState = new FormState({
        name: new FieldState('').validators(required),
        type: new FieldState<PolicyType>(PolicyType.Stay).validators(required)
    });

    formPolicyOtherState = new FormState({
        text: new FieldState('').validators(required)
    });

    formPolicyPetsState = new FormState({
        type: new FieldState<PolicyPetsType>(PolicyPetsType.NotAllowed).validators(required)
    });

    formPolicyNoShowState = new FormState({
        penaltyPercentage: new FieldState<number>(0).validators(int100Required)
    });

    formPolicyCancelState = new FormState({
        penaltyAfterReservation: new FieldState<number>(null).validators(int),
        penaltyAfterReservationType: new FieldState<CancelPolicyChargeType>(CancelPolicyChargeType.Percentage).validators(int),
        deadlineDays: new FieldState<number>(null).validators(int),
        penaltyAfterDeadline: new FieldState<number>(null).validators(int),
        penaltyAfterDeadlineType: new FieldState<CancelPolicyChargeType>(CancelPolicyChargeType.Percentage).validators(int),
    });

    formPolicyStayState = new FormState({
        checkInTimeFrom: new FieldState<number>(16).validators(int24hoursRequired),
        checkInTimeTo: new FieldState<number>(null).validators(int24hours),
        checkOutTimeFrom: new FieldState<number>(10).validators(int24hoursRequired),
        checkOutTimeTo: new FieldState<number>(null).validators(int24hours),
        guestType: new FieldState<AcceptedGuestType>(AcceptedGuestType.AdultOnly).validators(int),
    });

    constructor() {
        super();

        makeObservable(this, {
            policyId: observable,
            policyString: observable,

            save: action,
            getRuleString: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if (this.formState.hasError) {
            return;
        }

        let policy = await this.getPolicy();

        if(policy == null) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            let model = new PolicyEditModel({
                name: this.formState.$.name.value,
                type: this.formState.$.type.value,
                policy: JSON.stringify(policy),
                id: this.policyId
            });

            if(this.policyId > 0) {
                await this.apiClients.policyClient.upsertPolicy(model);
            } else {
                this.policyId = await this.apiClients.policyClient.upsertPolicy(model);

                runInAction(() => {
                    this.isSavedSuccessfully = true;
                });
            }
        });
    }

    getRuleString = async () => {
        let policy = await this.getPolicy();

        if(policy == null) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            this.policyString = await this.apiClients.policyClient.getPolicyString(new PolicyStringPreviewModel({
                type: this.formState.$.type.value,
                policy: JSON.stringify(policy)
            }));
        });
    }

    getPolicy = async () => {
        switch (this.formState.$.type.value) {
            case PolicyType.Stay: return await this.formatPolicyStayFrom();
            case PolicyType.Cancel: return await this.formatPolicyCancelFrom();
            case PolicyType.NoShow: return await this.formatPolicyNoShowFrom();
            case PolicyType.Pets: return await this.formatPolicyPetsFrom();
            case PolicyType.Other: return await this.formatPolicyOtherFrom();
        }
    }

    formatPolicyStayTo = (policy: PolicyStay) => {
        this.formPolicyStayState.$.checkInTimeFrom.value = policy.checkInTimeFrom;
        this.formPolicyStayState.$.checkInTimeTo.value = policy.checkInTimeTo;
        this.formPolicyStayState.$.checkOutTimeFrom.value = policy.checkOutTimeFrom;
        this.formPolicyStayState.$.checkOutTimeTo.value = policy.checkOutTimeTo;
        this.formPolicyStayState.$.guestType.value = policy.guestType;
    }
    formatPolicyStayFrom = async () => {
        await this.formPolicyStayState.validate();

        if (this.formPolicyStayState.hasError) {
            return null;
        }

        return new PolicyStay({
            checkInTimeFrom: +this.formPolicyStayState.$.checkInTimeFrom.value,
            checkInTimeTo: +this.formPolicyStayState.$.checkInTimeTo.value,
            checkOutTimeFrom: +this.formPolicyStayState.$.checkOutTimeFrom.value,
            checkOutTimeTo: +this.formPolicyStayState.$.checkOutTimeTo.value,
            guestType: this.formPolicyStayState.$.guestType.value,
        });
    }

    formatPolicyCancelTo = (policy: PolicyCancel) => {
        this.formPolicyCancelState.$.deadlineDays.value = policy.deadlineDays;
        this.formPolicyCancelState.$.penaltyAfterDeadline.value = policy.penaltyAfterDeadline;
        this.formPolicyCancelState.$.penaltyAfterDeadlineType.value = policy.penaltyAfterDeadlineType;
        this.formPolicyCancelState.$.penaltyAfterReservation.value = policy.penaltyAfterReservation;
        this.formPolicyCancelState.$.penaltyAfterReservationType.value = policy.penaltyAfterReservationType;
    }
    formatPolicyCancelFrom = async () => {
        await this.formPolicyCancelState.validate();

        if (this.formPolicyCancelState.hasError) {
            return null;
        }

        return new PolicyCancel({
            deadlineDays: +this.formPolicyCancelState.$.deadlineDays.value,
            penaltyAfterDeadline: +this.formPolicyCancelState.$.penaltyAfterDeadline.value,
            penaltyAfterDeadlineType: this.formPolicyCancelState.$.penaltyAfterDeadlineType.value,
            penaltyAfterReservation: +this.formPolicyCancelState.$.penaltyAfterReservation.value,
            penaltyAfterReservationType: this.formPolicyCancelState.$.penaltyAfterReservationType.value
        });
    }

    formatPolicyNoShowTo = (policy: PolicyNoShow) => {
        this.formPolicyNoShowState.$.penaltyPercentage.value = policy.penaltyPercentage;
    }
    formatPolicyNoShowFrom = async () => {
        await this.formPolicyNoShowState.validate();

        if (this.formPolicyNoShowState.hasError) {
            return null;
        }

        return new PolicyNoShow({
            penaltyPercentage: +this.formPolicyNoShowState.$.penaltyPercentage.value
        });
    }

    formatPolicyPetsTo = (policy: PolicyPets) => {
        this.formPolicyPetsState.$.type.value = policy.type;
    }
    formatPolicyPetsFrom = async () => {
        await this.formPolicyPetsState.validate();

        if (this.formPolicyPetsState.hasError) {
            return null;
        }

        return new PolicyPets({
            type: this.formPolicyPetsState.$.type.value
        });
    }

    formatPolicyOtherTo = (policy: PolicyOther) => {
        this.formPolicyOtherState.$.text.value = policy.text;
    }
    formatPolicyOtherFrom = async () => {
        await this.formPolicyOtherState.validate();

        if (this.formPolicyOtherState.hasError) {
            return null;
        }

        return new PolicyOther({
            text: this.formPolicyOtherState.$.text.value
        });
    }

    init = async (id?: number) => {
        this.policyId = id;

        await this.wrapInitLoadingApiCall(async () => {
            if(this.policyId > 0) {
                let result = await this.apiClients.policyClient.getDetails(this.policyId);

                runInAction(() => {
                    this.name = result.name;

                    this.formState.$.name.value = result.name;
                    this.formState.$.type.value = result.type;

                    switch (result.type) {
                        case PolicyType.Stay: return this.formatPolicyStayTo(PolicyStay.fromJS(JSON.parse(result.policy)));
                        case PolicyType.Cancel: return this.formatPolicyCancelTo(PolicyCancel.fromJS(JSON.parse(result.policy)));
                        case PolicyType.NoShow: return this.formatPolicyNoShowTo(PolicyNoShow.fromJS(JSON.parse(result.policy)));
                        case PolicyType.Pets: return this.formatPolicyPetsTo(PolicyPets.fromJS(JSON.parse(result.policy)));
                        case PolicyType.Other: return this.formatPolicyOtherTo(PolicyOther.fromJS(JSON.parse(result.policy)));
                    }
                });
            }
        });
    }
}
