import {Component} from "react";
import {inject} from "react-ioc";
import {MainStore} from "app/modules/main/stores/MainStore";
import React from "react";
import Search from "app/modules/main/components/main/search/search-short";
import {Loading} from "app/modules/common/layout/components/loading";
import {SearchStore} from "app/modules/main/components/main/search/SearchStore";
import {observer} from "mobx-react";

@observer
export class Index extends Component<any, any> {
    @inject mainStore: MainStore;
    @inject searchStore: SearchStore;

    render() {
        if(this.searchStore.isInitLoading) {
            return <Loading/>;
        }

        return <React.Fragment>
            <Search/>
        </React.Fragment>;
    }

    async componentDidMount() {
        await this.searchStore.init();
    }
}
