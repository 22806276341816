import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {useNavigate, useParams} from "react-router-dom";
import {Loading} from "app/modules/common/layout/components/loading";
import {AnalyticsService} from "app/modules/main/stores/AnalyticsService";

export default observer(() => {
    let apiClient = useInstance(ApiClients)
    const { code } = useParams();
    let navigate = useNavigate();
    let analytics = useInstance(AnalyticsService);

    useEffect(() => {
        const fetchData = async () => {
            let result = await apiClient.marketingClient.link(code);

            if(result?.length > 0) {
                analytics.marketingLink(code);
                navigate(result);
            } else {
                navigate("/");
            }

            return result;
        };

        fetchData();
    },[]);

    return (<Loading />);
})
