import {Component} from "react";
import React from "react";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {Grid} from "@mui/material";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Loading} from "app/modules/common/layout/components/loading";
import {inject, provider} from "react-ioc";
import {ListStore} from "app/modules/main/components/admin/promo-codes/ListStore";
import {observer} from "mobx-react";
import {PropertyPromoCodeListModel} from "app/modules/main/api/admin-clients.api";
import {Link} from "react-router-dom";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import {DateHelper} from "app/modules/main/components/common/DateHelper";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyPromoCodeListModel>) => <Link to={"/admin/promo-codes/" + (params.row as PropertyPromoCodeListModel).id}>
            {(params.row as PropertyPromoCodeListModel).id}
        </Link>,
    },
    {
        field: 'property',
        headerName: 'Property',
        maxWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyPromoCodeListModel>) => (params.row as PropertyPromoCodeListModel).property,
    },
    {
        field: 'promoCode',
        headerName: 'Promo Code',
        maxWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyPromoCodeListModel>) => <Link to={"/admin/promo-codes/" + (params.row as PropertyPromoCodeListModel).id}>
            {(params.row as PropertyPromoCodeListModel).promoCode}
        </Link>,
    },
    {
        field: 'amount',
        headerName: 'Amount',
        maxWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyPromoCodeListModel>) => NumberHelper.formatUSDollar((params.row as PropertyPromoCodeListModel).amount),
    },
    {
        field: 'expiredAt',
        headerName: 'ExpiredAt',
        maxWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyPromoCodeListModel>) => DateHelper.formatMomentDate((params.row as PropertyPromoCodeListModel).expiredAt),
    },
    {
        field: 'createdAt',
        headerName: 'CreatedAt',
        maxWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyPromoCodeListModel>) => DateHelper.formatMomentDate((params.row as PropertyPromoCodeListModel).createdAt),
    },
    {
        field: 'createdBy',
        headerName: 'CreatedBy',
        maxWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyPromoCodeListModel>) => (params.row as PropertyPromoCodeListModel).createdBy,
    },
    {
        field: 'active',
        headerName: 'Active',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyPromoCodeListModel>) => StringHelper.formatYesNo((params.row as PropertyPromoCodeListModel).active),
    },
    {
        field: 'appliedAt',
        headerName: 'AppliedAt',
        maxWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyPromoCodeListModel>) => DateHelper.formatMomentDate((params.row as PropertyPromoCodeListModel).appliedAt),
    },
    {
        field: 'propertyReservationId',
        headerName: 'Reservation',
        maxWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyPromoCodeListModel>) => (params.row as PropertyPromoCodeListModel).propertyReservationId && <Link to={"/book/" + (params.row as PropertyPromoCodeListModel).id}>
            Details
        </Link>,
    }
];

@provider(ListStore)
@observer
export class Index extends Component<any, any> {
    @inject listStore: ListStore;

    render() {
        if(this.listStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={"Promo Codes"} TotalRows={this.listStore.totalRows}/>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={"/admin/promo-codes/new"} Text="New Promo Code"/>
            </Grid>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.listStore.items}
                    columns={columns}
                    paginationModel={{ page: this.listStore.skipRows/this.listStore.pageSize, pageSize: this.listStore.pageSize }}
                    pageSizeOptions={this.listStore.pageSizeOptions}
                    rowCount={this.listStore.totalRows}
                    loading={this.listStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    rowSelection={false}
                    checkboxSelection={false}
                    onPaginationModelChange={(model) => this.listStore.setPage(model.page, model.pageSize)}/>
            </DataGridBody>
        </DataGridContainer>;
    }

    async componentDidMount() {
        this.listStore.init();
    }
}
