import {action, makeObservable, observable} from "mobx";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {LocalStorageService} from "app/modules/common/stores/LocalStorageService";
import {StartBookingModel} from "app/modules/main/api/main-clients.api";
import {AnalyticsService} from "app/modules/main/stores/AnalyticsService";

export class CurrentBookStore {
    @inject apiClients: ApiClients;
    @inject localStorageService: LocalStorageService;
    @inject analyticsService: AnalyticsService;

    localStorageKeyName: string = 'CurrentBookId';
    bookId: string = null;

    constructor() {
        makeObservable(this, {
            bookId: observable,

            setBookInfo: action,
        });
    }

    cancelBook = async () => {
        await this.apiClients.propertyMainClient.cancelBooking(this.bookId);
        this.bookId = null;
    }

    async setBookInfo(propertyId: number, from: number, to: number, adults: number, children: number, pets: number) {
        if(this.bookId != null) {
            await this.cancelBook();
        }

        this.bookId = await this.apiClients.propertyMainClient.startBooking(new StartBookingModel({
            propertyId: propertyId,
            from: from,
            to: to,
            adults: adults,
            children: children,
            pets: pets
        }));

        this.localStorageService.setItem(this.localStorageKeyName, this.bookId);

        this.analyticsService.startBookingProperty(propertyId);
    }

    getBookId = () : string => this.localStorageService.getItem(this.localStorageKeyName);
}
