import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {BoxCenter, Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {
    Alert,
    Button,
    Card,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    MenuItem,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {provider, useInstance} from "react-ioc";
import {DetailsStore, PropertyTabType} from "app/modules/main/components/admin/properties/details/DetailsStore";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {
    SectionCheckBoxField,
    SectionNumberTextField,
    SectionSelectField,
    SectionTextAreaField,
    SectionTextField
} from "app/modules/common/details/text-field";
import {Editor} from "react-draft-wysiwyg";
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import queryString from "query-string";

import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import {Navigate, useParams} from "react-router-dom";
import {MainStore} from "app/modules/main/stores/MainStore";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {Loading} from "app/modules/common/layout/components/loading";
import PolicySelection from "app/modules/main/components/admin/properties/details/policy-section";
import {
    IntegrationType,
    PolicyType,
    PropertyAdditionalImageType,
    PropertyRateType
} from "app/modules/main/api/admin-clients.api";
import {PolicySelectorResult} from "app/modules/main/components/admin/properties/details/dialog.policy.selector";
import {FormState} from "formstate";
import Rates from "app/modules/main/components/common/details/rates";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default provider(DetailsStore)(observer(() => {
    let store = useInstance(DetailsStore);
    let mainStore = useInstance(MainStore);
    let adminStore = useInstance(AdminStore);

    const urlParams = useParams();
    const { id } = urlParams;

    useEffect(
        () => {
            store.init(!!id && parseInt(id) || null);
            adminStore.selectMenu(AdminMenu.Properties);
        },
        [],
    );

    const fileInput = useRef(null);
    const fileAdditionalInput = useRef(null);

    const removeImage = async (imageId) => {
        store.dialogStore.addConfirmDialog({
            okColor: "error",
            title: "Remove Image",
            description: "Are you sure, you want to remove this image?",
            cancelText: "Close",
            okText: "Remove",
            okAction: async () => {
                await store.wrapLoadingApiCall(async () => {
                    await store.apiClients.adminPropertyClient.deletePropertyImage(store.propertyId, imageId);
                    await store.reloadImages();
                });
            }
        });
    }

    const removeAdditionalImage = async (imageId) => {
        store.dialogStore.addConfirmDialog({
            okColor: "error",
            title: "Remove Image",
            description: "Are you sure, you want to remove this image?",
            cancelText: "Close",
            okText: "Remove",
            okAction: async () => {
                await store.wrapLoadingApiCall(async () => {
                    await store.apiClients.adminPropertyClient.deletePropertyAdditionalImage(store.propertyId, imageId, PropertyAdditionalImageType.CheckInCheckOut);
                    await store.reloadAdditionalImages();
                });
            }
        });
    }

    if(store.isInitLoading) {
        return <Loading/>;
    }

    if(store.isSavedSuccessfully) {
        return <Navigate to={"/admin/properties/" + store.propertyId} replace={true} />;
    }

    let rateAddNewItem = (type: PropertyRateType) => <IconButton color="primary" onClick={() => store.addNewRate(type)} sx={{ width: "50px" }}>
        <AddIcon fontSize={"large"}/>
    </IconButton>;

    let rateAction = (type: PropertyRateType, array: any[], item: FormState<any>) => <React.Fragment>
        <IconButton color="primary" onClick={() => store.saveRate(type, item)}>
            <CheckIcon fontSize={"large"}/>
        </IconButton>
        <IconButton color="error" onClick={() => store.deleteRate(array, item)}>
            <RemoveIcon fontSize={"large"}/>
        </IconButton>
    </React.Fragment>

    return <Container>
        <PageTitleDetails Title={store.name || "New Property"} Size={8}/>
        <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
            {store.activeTab == PropertyTabType.Basic && <LoadingButton loading={store.isLoading} disabled={store.formState.hasError} variant="contained" onClick={store.save}>
                Save
            </LoadingButton>}

            {store.activeTab == PropertyTabType.Integrations && <LoadingButton loading={store.isLoading} disabled={store.formHostToolsWebHookState.hasError || store.formHostToolsSyncState.hasError} variant="contained" onClick={store.saveIntegrations}>
                Save
            </LoadingButton>}
        </Grid>
        {store.propertyId > 0 && <Grid item xs={12}>
            <Tabs value={store.activeTab} onChange={(e, tabType) => store.changeTab(tabType)} variant={"fullWidth"}>
                <Tab label="Basic" value={PropertyTabType.Basic} />
                <Tab label="Rates" value={PropertyTabType.Rates} />
                <Tab label="Integrations" value={PropertyTabType.Integrations} />
            </Tabs>
        </Grid>}

        {store.activeTab == PropertyTabType.Integrations && <ContainerDetails>
            <SectionName title="HostTools Webhook" description="Webhook integration (Airbnb/Booking)" />
            <Section>
                <Stack spacing={2} direction={"row"}>
                    <SectionSelectField
                        field={store.formHostToolsWebHookState.$.integration}
                        width={"33%"}
                        none={true}
                        label={"Integration"}
                        items={() => store.integrations.filter(x => x.type == IntegrationType.HosttoolsWebHook).map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                    />
                    <SectionTextField
                        label={"Airbnb Key"}
                        field={store.formHostToolsWebHookState.$.airbnb}
                        width={"33%"}
                    />
                    <SectionTextField
                        label={"Booking Key"}
                        field={store.formHostToolsWebHookState.$.booking}
                        width={"34%"}
                    />
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="HostTools Sync" description="Use a calendar link, replace calc to json in a path." />
            <Section>
                <Stack spacing={2} direction={"row"}>
                    <SectionSelectField
                        field={store.formHostToolsSyncState.$.integration}
                        width={"50%"}
                        none={true}
                        label={"Integration"}
                        items={() => store.integrations.filter(x => x.type == IntegrationType.HosttoolsApi).map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                    />
                    <SectionTextField
                        label={"Url Path (JSON)"}
                        field={store.formHostToolsSyncState.$.path}
                        width={"50%"}
                    />
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="Hostway Sync" />
            <Section>
                <Stack spacing={2} direction={"row"}>
                    <SectionSelectField
                        field={store.formHostwaySyncState.$.integration}
                        width={"50%"}
                        none={true}
                        label={"Integration"}
                        items={() => store.integrations.filter(x => x.type == IntegrationType.HostawayApi).map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                        onChange={() => store.onHostToolsSyncTypeChange()}
                    />
                    <SectionSelectField
                        field={store.formHostwaySyncState.$.listingId}
                        width={"50%"}
                        none={true}
                        label={"Listing"}
                        items={() => store.hostawayListingList.map((x) => <MenuItem value={x.id} key={x.id}>{x.internalListingName}</MenuItem>)}
                    />
                </Stack>
            </Section>
        </ContainerDetails>}
        {store.activeTab == PropertyTabType.Basic && <ContainerDetails>
            <SectionName title="INFO" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <SectionTextField
                        label={"Internal Name"}
                        field={store.formState.$.internalName}
                        fullWidth={true}
                    />
                    <SectionTextField
                        label={"Name"}
                        field={store.formState.$.name}
                        fullWidth={true}
                    />
                    <br/>
                    <br/>
                    <Typography variant={"h6"}>Address</Typography>
                    <Divider/>
                    <Stack spacing={2} direction={"row"}>
                        <SectionSelectField
                            field={store.formState.$.state}
                            width={"20%"}
                            label={"State"}
                            items={() => mainStore.clientData.states.map((x) => <MenuItem value={x.code} key={x.code}>{x.name}</MenuItem>)}
                        />
                        <SectionTextField
                            label={"City"}
                            field={store.formState.$.city}
                            width={"20%"}
                        />
                        <SectionTextField
                            label={"Address"}
                            field={store.formState.$.address}
                            width={"50%"}
                        />
                        <SectionTextField
                            label={"Zip"}
                            field={store.formState.$.zip}
                            width={"10%"}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionNumberTextField
                            label={"Show Address Before Days"}
                            field={store.formState.$.showAddressBeforeDays}
                            width={"100%"}
                        />
                    </Stack>
                    <br/>
                    <Typography variant={"h6"}>Location Point</Typography>
                    <Divider/>
                    <Stack spacing={2} direction={"row"}>
                        <SectionNumberTextField
                            label={"Latitude"}
                            field={store.formState.$.latitude}
                            width={"50%"}
                        />
                        <SectionNumberTextField
                            label={"Longitude"}
                            field={store.formState.$.longitude}
                            width={"50%"}
                        />
                    </Stack>
                    <br/>
                    <br/>
                    <Typography variant={"h6"}>Description</Typography>
                    <Divider/>
                    <Editor
                        editorState={store.formState.$.editor.value}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={store.formState.$.editor.onChange}
                    />
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="DETAILS" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <Stack spacing={2} direction={"row"}>
                        <SectionSelectField
                            field={store.formState.$.type}
                            width={"50%"}
                            label={"Type"}
                            items={() => mainStore.clientData.propertyTypes.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                        />
                        <SectionNumberTextField
                            label={"Default Rate"}
                            field={store.formState.$.defaultRate}
                            width={"50%"}
                            prefix="$"
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionNumberTextField
                            label={"Minimum Nights"}
                            field={store.formState.$.minNights}
                            width={"50%"}
                        />
                        <SectionNumberTextField
                            label={"Maximum Nights (Optional)"}
                            field={store.formState.$.maxNights}
                            width={"50%"}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionNumberTextField
                            label={"Guests Max (May stay at any moment)"}
                            field={store.formState.$.guestsMax}
                            width={"33%"}
                        />
                        <SectionNumberTextField
                            label={"Pet Max"}
                            field={store.formState.$.petMax}
                            width={"33%"}
                        />
                        <SectionNumberTextField
                            label={"Bathrooms"}
                            field={store.formState.$.bathrooms}
                            width={"34%"}
                        />
                    </Stack>
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="HOUSE RULES" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <Stack spacing={2} direction={"row"}>
                        <SectionCheckBoxField
                            field={store.formState.$.partiesAllowed}
                            label="Parties Allowed"
                            width={"50%"}
                        />
                        <SectionCheckBoxField
                            field={store.formState.$.smokingAllowed}
                            label="Smoking Allowed"
                            width={"50%"}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionCheckBoxField
                            field={store.formState.$.quietHoursEnabled}
                            onChange={() => {
                                if (!store.formState.$.quietHoursEnabled.value) {
                                    store.formState.$.quietHoursStartTime.onChange(null);
                                    store.formState.$.quietHoursEndTime.onChange(null);
                                }
                            }}
                            label="Quiet Hours Enabled"
                            width={"33%"}
                        />
                        <SectionNumberTextField
                            label={"Start Time (Hours 0-23)"}
                            field={store.formState.$.quietHoursStartTime}
                            isReadOnlyMode={!store.formState.$.quietHoursEnabled.value}
                            width={"33%"}
                        />
                        <SectionNumberTextField
                            label={"End Time (Hours 0-23)"}
                            field={store.formState.$.quietHoursEndTime}
                            isReadOnlyMode={!store.formState.$.quietHoursEnabled.value}
                            width={"34%"}
                        />
                    </Stack>
                    <br/>
                    <Typography variant={"subtitle1"}>Rules</Typography>
                    <Divider/>
                    <SectionTextAreaField
                        label={"Rules"}
                        field={store.formState.$.houseRules}
                        fullWidth
                    />
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="SLEEPING ARRANGEMENTS" description="" />
            <Section>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {store.formState.$.sleepingArrangements?.$?.map((x, index) => <Grid key={index} item xs={12} sm={12} md={12} lg={12} xl={6}>
                            <Card sx={{ width: "100p%", p: 2 }}>
                                <Stack spacing={2} direction={"column"}>
                                    <Stack spacing={2} direction={"row"}>
                                        <BoxCenter sx={{ width: `calc(100% - ${35}px)` }}>
                                            <Typography variant={"h6"} textAlign={"center"} sx={{ paddingLeft: "35px" }}>Bedroom</Typography>
                                        </BoxCenter>
                                        <IconButton color="error" title={"Remove Room"} onClick={() => store.removeRoom(x)}>
                                            <CloseIcon fontSize={"large"}/>
                                        </IconButton>
                                    </Stack>
                                    <Divider/>
                                    <Stack spacing={2} direction={"row"}>
                                        <SectionSelectField
                                            field={x.$.type}
                                            width={"100%"}
                                            label={"Type"}
                                            items={() => mainStore.clientData.propertyRoomTypes.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                                        />
                                    </Stack>
                                    <Stack spacing={2} direction={"column"}>
                                        {x.$.beds?.$?.map((bed, bi) => <Stack key={bi} spacing={2} direction={"row"}>
                                            <SectionSelectField
                                                field={bed.$.type}
                                                width={"50%"}
                                                label={"Type"}
                                                items={() => mainStore.clientData.propertySleepingArrangementBedTypes.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                                            />
                                            <SectionNumberTextField
                                                label={"People"}
                                                field={bed.$.people}
                                                width={"50%"}
                                            />
                                            <IconButton color="error" title={"Remove Bed"} onClick={() => store.removeRoomBed(x, bed)}>
                                                <RemoveIcon fontSize={"small"} />
                                            </IconButton>
                                        </Stack>)}
                                        <Button href="#text-buttons" onClick={() => store.addRoomBed(x)}>Add</Button>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Grid>)}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} sx={{ height: "100%", minHeight: "300px" }}>
                            <BoxCenter sx={{ height: "100%", minHeight: "300px" }}>
                                <Stack spacing={2} direction={"column"}>
                                    <Typography variant={"h6"} textAlign={"center"}>ADD ROOM</Typography>
                                    <IconButton color="primary" onClick={store.addRoom}>
                                        <AddIcon fontSize={"large"}/>
                                    </IconButton>
                                </Stack>
                            </BoxCenter>
                        </Grid>
                    </Grid>
                </Grid>
            </Section>

            <SectionSeparator/>
            <SectionName title="AMENITIES" description="" />
            <Section>
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                    <FormGroup>
                        {mainStore.clientData.amenities.map((x) => (
                            <FormControlLabel key={x.id} control={<Checkbox checked={store.ifAmenitySelected(x.id)} onChange={(event, checked) => store.amenitySelect(x.id, checked)} name={"amenity_" + x.id} />} label={x.name} />
                        ))}
                    </FormGroup>
                </FormControl>
            </Section>

            {store.propertyId > 0 && <React.Fragment>
                <SectionSeparator/>
                <SectionName title="IMAGES" description="" />
                <Section>
                    <ImageList sx={{ width: "100%" }} cols={4}>
                        {store.images.map((item, index) => (
                            <ImageListItem key={index}>
                                <img
                                    srcSet={`/adminController/Property/GetFile?path=${item.thumbnailUrl}`}
                                    src={`/adminController/Property/GetFile?path=${item.url}`}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    title={"Remove"}
                                    actionPosition={"left"}
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            onClick={() => removeImage(item.id)}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    }
                                />
                                <ImageListItemBar
                                    actionPosition={"right"}
                                    position={"top"}
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            onClick={() => store.makeThumbnail(item.id)}
                                        >
                                            {item.isThumbnail ? <StarIcon/> : <StarOutlineIcon/>}
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                    <Grid item md={4} lg={3} xl={3} sx={{ height: "100%", minHeight: "300px" }}>
                        <BoxCenter sx={{ height: "100%", minHeight: "300px" }}>
                            <Stack spacing={2} direction={"column"}>
                                <Typography variant={"h6"} textAlign={"center"}>ADD IMAGE</Typography>
                                <IconButton color="primary" onClick={() => fileInput.current.click()}>
                                    <AddIcon fontSize={"large"}/>
                                </IconButton>
                                {store.fileError?.length > 0 && <Alert severity="error">{store.fileError}</Alert>}
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    multiple={false}
                                    ref={fileInput}
                                    onChange={e => {
                                        store.setFileLoading(true);

                                        let formData = new FormData();
                                        formData.append("file", e.target.files[0]);

                                        // @ts-ignore
                                        let obj = queryString.stringify({ propertyId: store.propertyId });

                                        store.apiClients.axiosInstance.post("/adminController/property/Upload" + "?" + obj,
                                            formData, {
                                                // @ts-ignore
                                                headers: {
                                                    'Content-Type': 'application/x-www-url-formencoded'
                                                }
                                            }).then(async (value) => {
                                                if(value.status == 400) {
                                                    store.setFileError(value?.data?.Message || "Unexpected Error");
                                                    fileInput.current.value = '';
                                                } else {
                                                    store.setFileError(null);
                                                    await store.reloadImages();
                                                    fileInput.current.value = '';
                                                }
                                            }).catch((ex) => {
                                                store.setFileError('File is not uploaded.');
                                                e.target.value = '';
                                            }).finally(() => {
                                                store.setFileLoading(false);
                                            })
                                    }}
                                />
                            </Stack>
                        </BoxCenter>
                    </Grid>
                </Section>
            </React.Fragment>}

            {store.propertyId > 0 && <React.Fragment>
                <SectionSeparator/>
                <SectionName title="Instructions: Check In/Out" description="" />
                <Section>
                    <br/>
                    <Typography variant={"subtitle1"}>Instructions</Typography>
                    <Divider/>
                    <SectionTextAreaField
                        label={"Rules"}
                        field={store.formState.$.checkInCheckOutInstructions}
                        fullWidth
                    />
                    <br/>
                    <Stack spacing={2} direction={"column"} sx={{width: "100%"}}>
                        {adminStore.propertyParams.map(x => {
                            return <CopyToClipboard key={x} text={x}><Button variant={"text"}>{x}</Button></CopyToClipboard>;
                        })}
                    </Stack>
                    <br/>
                    <ImageList sx={{ width: "100%" }} cols={4}>
                        {store.additionalImages.map((item, index) => (
                            <ImageListItem key={index}>
                                <img
                                    srcSet={`/adminController/Property/GetFile?path=${item.url}`}
                                    src={`/adminController/Property/GetFile?path=${item.url}`}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    title={"Remove"}
                                    actionPosition={"left"}
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            onClick={() => removeAdditionalImage(item.id)}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                    <Grid item md={4} lg={3} xl={3} sx={{ height: "100%", minHeight: "300px" }}>
                        <BoxCenter sx={{ height: "100%", minHeight: "300px" }}>
                            <Stack spacing={2} direction={"column"}>
                                <Typography variant={"h6"} textAlign={"center"}>ADD IMAGE</Typography>
                                <IconButton color="primary" onClick={() => fileAdditionalInput.current.click()}>
                                    <AddIcon fontSize={"large"}/>
                                </IconButton>
                                {store.fileError?.length > 0 && <Alert severity="error">{store.fileError}</Alert>}
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    multiple={false}
                                    ref={fileAdditionalInput}
                                    onChange={e => {
                                        store.setFileLoading(true);

                                        let formData = new FormData();
                                        formData.append("file", e.target.files[0]);

                                        // @ts-ignore
                                        let obj = queryString.stringify({ propertyId: store.propertyId, type: PropertyAdditionalImageType.CheckInCheckOut });

                                        store.apiClients.axiosInstance.post("/adminController/property/UploadByType" + "?" + obj,
                                            formData, {
                                                // @ts-ignore
                                                headers: {
                                                    'Content-Type': 'application/x-www-url-formencoded'
                                                }
                                            }).then(async (value) => {
                                                if(value.status == 400) {
                                                    store.setFileError(value?.data?.Message || "Unexpected Error");
                                                    fileAdditionalInput.current.value = '';
                                                } else {
                                                    store.setFileError(null);
                                                    await store.reloadAdditionalImages();
                                                    fileAdditionalInput.current.value = '';
                                                }
                                            }).catch((ex) => {
                                                store.setFileError('File is not uploaded.');
                                                e.target.value = '';
                                            }).finally(() => {
                                                store.setFileLoading(false);
                                            })
                                    }}
                                />
                            </Stack>
                        </BoxCenter>
                    </Grid>
                </Section>
            </React.Fragment>}

            <SectionSeparator/>
            <SectionName title="POLICIES" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <PolicySelection type={PolicyType.Stay} add={(item: PolicySelectorResult) => store.addPolicy(item)} remove={(policyId) => store.removePolicy(policyId)} items={store.policies} />
                    <Divider/>
                    <PolicySelection type={PolicyType.Cancel} add={(item: PolicySelectorResult) => store.addPolicy(item)} remove={(policyId) => store.removePolicy(policyId)} items={store.policies} />
                    <Divider/>
                    <PolicySelection type={PolicyType.NoShow} add={(item: PolicySelectorResult) => store.addPolicy(item)} remove={(policyId) => store.removePolicy(policyId)} items={store.policies} />
                    <Divider/>
                    <PolicySelection type={PolicyType.Pets} add={(item: PolicySelectorResult) => store.addPolicy(item)} remove={(policyId) => store.removePolicy(policyId)} items={store.policies} />
                    <Divider/>
                    <PolicySelection type={PolicyType.Other} add={(item: PolicySelectorResult) => store.addPolicy(item)} remove={(policyId) => store.removePolicy(policyId)} items={store.policies} />
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="CONTACT" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <SectionSelectField
                        field={store.formState.$.contactId}
                        width={"100%"}
                        label={"Contact"}
                        items={() => store.contacts?.map((x) => <MenuItem value={x.id} key={x.id}>{x.name} - {x.phone}</MenuItem>)}
                    />
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="Advance" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <SectionCheckBoxField
                        label={"Testing (Not REAL)"}
                        field={store.formState.$.isTest}
                        width={"100%"}
                    />
                </Stack>
            </Section>
        </ContainerDetails>}
        {store.activeTab == PropertyTabType.Rates && <ContainerDetails>
            <SectionName title="General" description="Collected extra charges" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    {store.rates.$.general.$.map(x => <Stack spacing={2} direction={"row"} key={x.$.id.value}>
                        <SectionTextField
                            label={"Name"}
                            field={x.$.name}
                            width={"50%"}
                        />
                        <SectionNumberTextField
                            label={"Rate"}
                            field={x.$.rate}
                            width={"50%"}
                            prefix="$"
                        />
                        {rateAction(PropertyRateType.General, store.rates.$.general.$, x)}
                    </Stack>)}
                    {rateAddNewItem(PropertyRateType.General)}
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="General Per Night" description="Collected extra charges per night" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    {store.rates.$.generalPerNight.$.map(x => <Stack spacing={2} direction={"row"} key={x.$.id.value}>
                        <SectionTextField
                            label={"Name"}
                            field={x.$.name}
                            width={"50%"}
                        />
                        <SectionNumberTextField
                            label={"Rate"}
                            field={x.$.rate}
                            width={"50%"}
                            prefix="$"
                        />
                        {rateAction(PropertyRateType.GeneralPerNight, store.rates.$.generalPerNight.$, x)}
                    </Stack>)}
                    {rateAddNewItem(PropertyRateType.GeneralPerNight)}
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="Pet Fee" description="Collected charges per pet per night/stay" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    {store.rates.$.petPerNight.$.map(x => <Stack spacing={2} direction={"row"} key={x.$.id.value}>
                        <SectionTextField
                            label={"Name"}
                            field={x.$.name}
                            width={"25%"}
                        />
                        <SectionCheckBoxField
                            label={"Is Per Night"}
                            field={x.$.isPerNight}
                            width={"25%"}
                        />
                        <SectionNumberTextField
                            label={"Start From"}
                            field={x.$.startFrom}
                            width={"25%"}
                        />
                        <SectionNumberTextField
                            label={"Rate"}
                            field={x.$.rate}
                            width={"25%"}
                            prefix="$"
                        />
                        {rateAction(PropertyRateType.PetPerNight, store.rates.$.petPerNight.$, x)}
                    </Stack>)}
                    {rateAddNewItem(PropertyRateType.PetPerNight)}
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="Extra Guest Charges" description="Collected charges per guest per night/stay" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    {store.rates.$.guestPerNight.$.map(x => <Stack spacing={2} direction={"row"} key={x.$.id.value}>
                        <SectionTextField
                            label={"Name"}
                            field={x.$.name}
                            width={"25%"}
                        />
                        <SectionCheckBoxField
                            label={"Is Per Night"}
                            field={x.$.isPerNight}
                            width={"25%"}
                        />
                        <SectionNumberTextField
                            label={"Start From"}
                            field={x.$.startFrom}
                            width={"25%"}
                        />
                        <SectionNumberTextField
                            label={"Rate"}
                            field={x.$.rate}
                            width={"25%"}
                            prefix="$"
                        />
                        {rateAction(PropertyRateType.GuestPerNight, store.rates.$.guestPerNight.$, x)}
                    </Stack>)}
                    {rateAddNewItem(PropertyRateType.GuestPerNight)}
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="On subtotal charges (%)" description="Collected charges in % on subtotal" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    {store.rates.$.onSubTotal.$.map(x => <Stack spacing={2} direction={"row"} key={x.$.id.value}>
                        <SectionTextField
                            label={"Name"}
                            field={x.$.name}
                            width={"33%"}
                        />
                        <SectionCheckBoxField
                            label={"Is Taxable"}
                            field={x.$.isTaxable}
                            width={"33%"}
                        />
                        <SectionNumberTextField
                            label={"Rate"}
                            field={x.$.rate}
                            width={"34%"}
                            prefix="%"
                        />
                        {rateAction(PropertyRateType.OnSubTotal, store.rates.$.onSubTotal.$, x)}
                    </Stack>)}
                    {rateAddNewItem(PropertyRateType.OnSubTotal)}
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="Taxes (%)" description="Collected taxes on all subtotal or total amount" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    {store.rates.$.taxes.$.map(x => <Stack spacing={2} direction={"row"} key={x.$.id.value}>
                        <SectionTextField
                            label={"Name"}
                            field={x.$.name}
                            width={"50%"}
                        />
                         <SectionNumberTextField
                            label={"Rate"}
                            field={x.$.rate}
                            width={"50%"}
                            prefix="%"
                        />
                        {rateAction(PropertyRateType.Taxes, store.rates.$.taxes.$, x)}
                    </Stack>)}
                    {rateAddNewItem(PropertyRateType.Taxes)}
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="Stay Discount" description="Discount For Stay" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    {store.rates.$.stayDiscount.$.map(x => <Stack spacing={2} direction={"row"} key={x.$.id.value}>
                        <SectionTextField
                            label={"Name"}
                            field={x.$.name}
                            width={"33%"}
                        />
                        <SectionNumberTextField
                            label={"Start From"}
                            field={x.$.startFrom}
                            width={"33%"}
                        />
                        <SectionNumberTextField
                            label={"Rate"}
                            field={x.$.rate}
                            width={"34%"}
                            prefix="%"
                        />
                        {rateAction(PropertyRateType.StayDiscount, store.rates.$.stayDiscount.$, x)}
                    </Stack>)}
                    {rateAddNewItem(PropertyRateType.StayDiscount)}
                </Stack>
            </Section>

            <SectionSeparator/>
            <SectionName title="Preview" description="Preview for 5 nights, 10 guests, 3 pets." />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <Stack spacing={2} direction={"row"}>
                        <SectionNumberTextField
                            label={"Nights"}
                            field={store.formRatePreviewState.$.nights}
                            width={"25%"}
                        />
                        <SectionNumberTextField
                            label={"Guests"}
                            field={store.formRatePreviewState.$.guests}
                            width={"25%"}
                        />
                        <SectionNumberTextField
                            label={"Pets"}
                            field={store.formRatePreviewState.$.pets}
                            width={"25%"}
                        />
                        <SectionTextField
                            label={"Promo Code"}
                            field={store.formRatePreviewState.$.promoCode}
                            width={"25%"}
                        />
                        <IconButton color="primary" onClick={() => store.previewRate()} sx={{ width: "50px" }}>
                            <VisibilityIcon fontSize={"large"}/>
                        </IconButton>
                    </Stack>
                    {store.previewRateData && <Rates rates={store.previewRateData}/>}
                </Stack>
            </Section>
        </ContainerDetails>}
        </Container>;
}));
