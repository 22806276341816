import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

const BreakElement = () => <div>
    <br/>
    <br/>
</div>

const Paragraph = (props: { children: any }) => <div>
    <Typography variant={"body1"} sx={{ paddingBottom: 1 }} component={"div"}>
        {props.children}
    </Typography>
</div>

const Title = (props: { children: any, id?: string }) => <div>
    <BreakElement/>
    <Typography variant={"h4"} id={props.id}>
        {props.children}
    </Typography>
    <BreakElement/>
</div>

const Bold = (props: { children: any }) =>
    <Typography variant={"body1"} component={"span"} sx={{ fontWeight: "bold" }}>
        {props.children}
    </Typography>

const BackToTop = () => <div>
    <BreakElement/>
    <Typography variant={"h6"}>
        <a href={"#top"}>BACK TO TOP</a>
    </Typography>
    <BreakElement/>
</div>

export default () => {
    return <Grid container sx={{ width: "100%", paddingTop: "20px" }}>
        <Grid item xs={0} sm={1} md={2} />
        <Grid item xs={12} sm={10} md={8}>
            <Box id={"top"}>
                <Paragraph>
                    * If you'd like to opt out of our direct mail marketing, please use this <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">link</a>.
                </Paragraph>
            </Box>

            <Title>
                Policies
            </Title>
            <Paragraph>
                Here's everything you need to know about our privacy and cookie policies. Visit <Link to={"/security"}>Information Security at SolArt Group Inc</Link> for concerns about information security. If you're looking for a summary of our rental policies, including our cancellation policy, view our <Link to={"/terms"}>full rental agreement</Link>.
            </Paragraph>
            <Title>
                SOLART GROUP INC PRIVACY POLICY
            </Title>
            <Paragraph>
                Last Updated: January 1, 2024
            </Paragraph>
            <Title>
                TABLE OF CONTENTS
            </Title>
            <Paragraph>
                <ol>
                    <li><a href={"#section1"}>What does this policy cover?</a></li>
                    <li><a href={"#section2"}>Information collected or received</a></li>
                    <li><a href={"#section3"}>How we use your information</a></li>
                    <li><a href={"#section4"}>How we share or disclose your information</a></li>
                    <li><a href={"#section5"}>Your rights and preferences</a></li>
                    <li><a href={"#section6"}>Your privacy and our global operations</a></li>
                    <li><a href={"#section7"}>How we secure your information</a></li>
                    <li><a href={"#section8"}>Links to third-party sites</a></li>
                    <li><a href={"#section9"}>Our sites and children</a></li>
                    <li><a href={"#section10"}>Changes to this privacy policy and WVRNA notice</a></li>
                    <li><a href={"#section11"}>Contacting us</a></li>
                    <li><a href={"#section12"}>Cookie notice</a></li>
                    <li><a href={"#section13"}>California privacy notice</a></li>
                    <li><a href={"#section14"}>Nevada privacy notice</a></li>
                    <li><a href={"#section15"}>Privacy Notice of Other U.S. States</a></li>
                    <li><a href={"#section16"}>SolArt Group Inc data request form</a></li>
                </ol>
            </Paragraph>
            <Paragraph>
                This Privacy Policy describes the privacy practices of SolArt Group Inc, its subsidiaries, and its related entities (referred to as “SolArt Group Inc,” “we,” “our,” or “us”), and in particular how we collect, use, and disclose certain information.
            </Paragraph>
            <Title id={"section1"}>
                1. WHAT DOES THIS POLICY COVER?
            </Title>
            <Paragraph>
                This Privacy Policy applies to our interactions with our customers and visitors, including but not limited to property rentals, rental management, marketing of rental listings, provision of guest services, your use of SolArt Group Inc websites or applications, social media, and advertisements or communications relating to any services we offer (“Services”), but excluding any SolArt Group Inc services that state that they are offered under a different privacy policy.
            </Paragraph>
            <Paragraph>
                This Privacy Policy does not apply to any third-party websites that may be accessible through a SolArt Group Inc website, or to third-party websites on which SolArt Group Inc advertises.
            </Paragraph>
            <BackToTop/>

            <Title id={"section2"}>
                2. INFORMATION COLLECTED OR RECEIVED
            </Title>
            <Paragraph>
                We collect certain personal information about you as necessary to provide services that you request, to fulfill the terms of a contract between you and us, or to comply with our legal obligations. If you refuse to provide this type of personal information, we may not be able to provide services to you.
            </Paragraph>
            <Paragraph>
                <Bold>When you reserve, pay for, or stay in a property through SolArt Group Inc.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        <Bold>Contact information.</Bold> When you reserve or stay in a property through SolArt Group Inc, we may collect information such as your first and last name, contact phone number, email address, and other digital contact information. We may also collect names and contact information that you provide about other renters or guests.</li>
                    <li>
                        <Bold>Payment information.</Bold> When you make a purchase, we collect your payment information, such as information from your credit card, debit card, or check, and your billing address, to allow us to process payments.
                    </li>
                    <li>
                        <Bold>Identity information.</Bold> To prevent fraud, to comply with the requirements of applicable law, or to meet the requirements of a hotel, resort or community association, we may collect proof of identity or proof of age, such as a government-issued driver’s license, passport, or other identity information in connection with your stay.
                    </li>
                    <li>
                        <Bold>Other information.</Bold> We may collect information about your automobile(s) and license plate number(s) in connection with your stay at a property that requires this information for parking permits. We may also collect information about your age and the ages of other guests in connection with your stay at a property that imposes a minimum renter age or requires this information for check-in. We may collect additional personal information in connection with your stay to comply with the requirements of applicable law, or to meet the requirements of a hotel, resort or community association.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>When we provide rental management or marketing services.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        <Bold>Contact information.</Bold> When we contract with you to provide rental management or marketing services for your property, we may collect information such as your first and last name; the address of your property; your mailing address, contact phone numbers, email addresses, and other digital contact information; and information concerning any entity you own or manage that is an owner of the property. We may also collect names and contact information of any other owners of the property, owners of an entity that owns the property, or guests or family members who use or stay at the property.
                    </li>
                    <li>
                        <Bold>Bank account, tax, and identity information.</Bold> In order to make payments to you, we collect information such as your bank account information, tax identification number, or other information. We may also collect proof of identity, such as a government-issued ID, passport, or other identity information in order to verify your identity or comply with applicable law. We may also collect other types of information from you as required to collect or remit lodging or sales taxes on your behalf, apply for or renew a government license or permit relating to rental management services provided to you, or pay utility or other bills on your behalf.
                    </li>
                    <li>
                        <Bold>Other information as required by law.</Bold> We may collect additional personal information about you as necessary to comply with the requirements of applicable law.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>When you choose to provide us with additional information.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        <Bold>Information provided during communications with SolArt Group Inc.</Bold> When you communicate with SolArt Group Inc in person, or by phone, email, text message, application, or other electronic means of communication, we may collect any information that you choose to provide during the communication.
                    </li>
                    <li>
                        <Bold>Other information you choose to provide.</Bold> We may collect other information you choose to provide to SolArt Group Inc, for example when you submit a review, comment or request, interact with a SolArt Group Inc social media account, attend a SolArt Group Inc event, refer a potential guest or owner, conduct a search, enter a contest, sweepstakes, or promotion, designate a preferred language or currency, or use other aspects of our Services.
                    </li>
                    <li>
                        <Bold>We may collect certain information about you from third parties.</Bold> Each third party that collects or processes your information does so subject to its own privacy policies. We encourage you to review the privacy policies of websites that you visit. SolArt Group Inc does not control or oversee the manner in which third parties collect, use, or disclose your information.
                    </li>
                    <li>
                        <Bold>Information provided in connection with a booking or stay.</Bold> If you use a third party to book a stay in a property for which SolArt Group Inc provides rental management or marketing services, the third party may send us personal information relating to your stay that you provided to the third party, such as your name, contact information, payment method, the names of other renters or guests, reservation dates, and identity information. We may collect information about you from your friends or family members, for example if a friend or family member includes you as part of their reservation. Third parties who provide services to you in connection with your stay, such as a utility, contractor, service provider, tour operator, concierge, resort or community association, or government official, may send us information about the services you used if permitted or required to do so by applicable law. The type of information we receive varies depending on the type of service that was provided. To the extent allowed by applicable law, we may obtain police or background check information or potential fraud alerts from third-party providers of identity verification, criminal background check, or money laundering prevention services.
                    </li>
                    <li>
                        <Bold>Information obtained in connection with rental management services.</Bold> When we agree to provide rental management or marketing services for you, a third party such as a utility provider, government entity, former or current property manager or resort or community association may provide us information such as your name, contact information, property address, property rental history, utility account numbers and history, sales or lodging tax license number and remittance history, or local rental permit numbers.
                    </li>
                    <li>
                        <Bold>Other information we obtain from third parties.</Bold> We may obtain information from business partners about your use of the Services or services provided by third parties. Such information may include your travel preferences and history, products or services you like, or reviews you submit. We may obtain your name and mailing address or other contact information from third parties such as government agencies, direct mailing service providers, or vendors who compile information from public records. Other than described in this Privacy Policy, we do not intentionally collect sensitive personal information, such as information about your medical history, political views, union membership, religious or philosophical beliefs, racial or ethnic origin, or genetic data.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>We automatically collect certain information about you when you visit a SolArt Group Inc website, use a SolArt Group Inc application, or communicate with SolArt Group Inc.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        <Bold>Information about your site visits.</Bold> When you visit a SolArt Group Inc website, we collect information about your activity on the site. We may track the date and time you accessed the site, the pages you visit and how long you viewed them, your searches for properties, your booking activity (including started, attempted, and abandoned bookings), whether you have followed links to other websites, and your other interactions with the site. Your internet browser may automatically transmit information to us, such as what website you came from.
                    </li>
                    <li>
                        <Bold>Device and browser information.</Bold> We may automatically collect information about the hardware, operating system, browser, and extensions or add-ons you are using, IP address, proxy server, ISP or mobile carrier, device identifier, and any crash data associated with your visit.
                    </li>
                    <li>
                        <Bold>Location information.</Bold> If you use our mobile websites or applications, we may automatically collect location data obtained from your mobile device's GPS. Most mobile devices allow you to control whether an application can use the device’s location services. If you use our websites, we may collect information about your location derived from your IP address.
                    </li>
                    <li>
                        <Bold>Cookies, web beacons, and similar technologies.</Bold> As further described in our <a href={"#section12"}>Cookie Policy</a>, we may use tracking tools like browser cookies, flash cookies, and web beacons. We may also engage certain business partners to use these tracking tools on SolArt Group Inc websites or to track your online behavior on other sites on our behalf.
                    </li>
                    <li>
                        Communication. When you communicate with SolArt Group Inc using electronic means, we automatically collect the contents of the communication. In some cases, where permitted by law, we may automatically record phone calls for quality assurance and training purposes.
                    </li>
                </ul>
            </Paragraph>
            <BackToTop/>

            <Title id={"section3"}>
                3. HOW WE USE YOUR INFORMATION
            </Title>
            <Paragraph>
                <Bold>We use your information in different ways, depending on which Services you use and how you use those Services.</Bold>
            </Paragraph>
            <Paragraph>
                We use information about you to provide Services that you request or to fulfill the terms of a contract between you and us. We use information about you to respond to your inquiries or requests; to communicate with you about reservations and stays; to fulfill contractual or legal requirements relating to your request or the Services you are using (for example, to permit you to stay in a property you have reserved); to provide customer service; to provide rental management or similar services; to detect and prevent fraud or illegal activity; and to make or receive payments to or from you.
            </Paragraph>
            <Paragraph>
                <Bold>To optimize, customize and improve our services and your user experience.</Bold>
            </Paragraph>
            <Paragraph>
                We use information about you and your use of the Services to optimize, customize, and improve our Services, our systems, and your user experience. We may use your information to respond to comments, suggestions, and reviews; to personalize your user experience, including changing displayed properties based on your preferences and search and booking history; and to improve and optimize the performance of our websites or applications. We may use your information to send notifications and offers relating to a stay you have booked. We may use location data to find locations nearest to you and provide driving directions to a property you have booked.
            </Paragraph>
            <Paragraph>
                <Bold>To conduct, measure, and improve our advertising and marketing.</Bold>
            </Paragraph>
            <Paragraph>
                We use information about you to communicate with you about SolArt Group Inc and SolArt Group Inc Services as permitted by law, including sending advertisements and marketing communications. We may use information about you and your search, browsing, and booking history to send promotional messages and offers that may be of interest to you; to analyze and improve the effectiveness of our advertising and marketing efforts; or to conduct marketing campaigns on third-party websites or social media platforms.
            </Paragraph>
            <Paragraph>
                We also use your data to produce aggregated information that does not identify you, such as information about ad impressions served or clicked on.
            </Paragraph>
            <Paragraph>
                <Bold>To comply with our legal and contractual obligations and for security and investigation purposes.</Bold>
            </Paragraph>
            <Paragraph>
                We may use information about you to comply with our legal obligations, such as requirements in some jurisdictions to collect and store certain identity and contact information in connection with a booking or information about your tax identification information in connection with making payments to you. We may also use your personal information to comply with our contractual obligations to you or to certain third parties. We may also use your personal information to enforce the terms of an agreement between us and you or a third party; to enforce the terms of our Terms of Service or other policies; or to resolve disputes with you or another SolArt Group Inc customer. We may use your information to conduct security investigations or to conduct or cooperate with investigations related to potential criminal activity. We may use your information to detect and prevent fraud, security incidents, and criminal or harmful activity.
            </Paragraph>
            <BackToTop/>

            <Title id={"section4"}>
                4. HOW WE SHARE OR DISCLOSE YOUR INFORMATION
            </Title>
            <Paragraph>
                <Bold>With third parties in order to fulfill our contractual obligations and/or provide Services.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        <Bold>We share information about you to provide Services that you request or to fulfill the terms of a contract between you and us.</Bold> We need to share this information to adequately perform the terms of the contract between us and you.
                    </li>
                    <li>
                        <Bold>We may share information with our local service providers, such as maintenance technicians, contractors, and housekeepers, to allow us to provide services you request and that we are contractually obligated to provide.</Bold> We might also authorize our local service providers to collect information on our behalf. If you are staying in a property reserved through SolArt Group Inc, this information may include your full name, the names of other guests, and your telephone number or email address. If we provide you with rental management services or rental marketing services, this information may include your full name and the names of any other owners or contacts you have designated, your telephone number, your email address, the address of the property we are managing or marketing, and your mailing address.
                    </li>
                    <li>
                        <Bold>We might also share your contact information with property owners and service providers to allow them to respond directly to your reviews and questions. We may share your information with third parties who provide services to us in connection with a booking.</Bold> These third parties may provide services related to payment processing; access to property; fraud prevention, identity verification, and risk assessment; and anti-money laundering compliance. These providers’ access to your information is limited to the information they reasonably require to perform specific tasks on our behalf, and they are contractually bound to use it only for purposes for which it is disclosed.
                    </li>
                    <li>
                        <Bold>Service providers may be located inside or outside of Europe.</Bold> In particular, we may use service providers located in Europe, North America, and South America.
                    </li>
                    <li>
                        <Bold>Some resorts, community associations, government entities, or other third parties may require us to provide guest information in connection with a specific reservation for a property under their control or jurisdiction.</Bold> We share information with these third parties to the extent it is legally required or that those parties require us to do so as a condition of the rental. This information may include your full name and age, the names and ages of other guests, your telephone number, your address, your government-issued identification (such as a driver’s license or passport number), and vehicle information such as make, model, and license plate number. In some jurisdictions we are required to disclose guests’ full names, contact information, and transaction information related to a rental stay to the owner of the property.
                    </li>
                    <li>
                        <Bold>We provide your information to third parties at your direction or request.</Bold> For example, we will provide your information to a third party if you ask us to do so to facilitate the resolution of a dispute.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>With third parties in order to offer additional services.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        We may share information about you with local third-party business partners who may offer additional services in connection with a property you have booked. For example, we may provide your name and email address to a company that offers concierge services for the property you have booked.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>With a subsidiary or affiliated company.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        As allowed by applicable law, we may share your information with a SolArt Group Inc subsidiary or other affiliated company under common ownership or control. Unless the subsidiary or affiliated party adheres to its own privacy policy (which will be at least as protective as the terms of this Privacy Policy), the terms of this Privacy Policy will apply to such information sharing.
                    </li>
                    <li>
                        If you book a stay in a state or country other than your state or country of residence, or if you contract with us for us to provide rental management services in a state or country other than your state or country of residence, your information will be shared with the SolArt Group Inc entity that manages the property in that state or country. Regardless of your state or country of residence, your information may be shared with SolArt Group Inc, which provides technical, business, administrative, and customer support services to its subsidiaries and affiliates. This data sharing is necessary to perform the terms of the agreement between us and you and is further based on our legitimate interests in providing Services globally.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>In connection with a sale, merger, or change of control.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        We may share information in connection with the sale or all or part of our business, a merger, a change of control, or a reorganization, or in contemplation of any of these events (for example, as part of the due diligence process). Any entity that buys us or any part of our business, or any successor entity following a merger, change of control, or reorganization, will have the right to use your personal information, but only as permitted in this Privacy Policy unless you agree otherwise.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>To comply with the law, respond to legal requests, resolve disputes, prevent harm to persons or property, and protect our rights.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        We may disclose your information, including your personal information, to a court, law enforcement agency, government agency, or other authorized third party in response to a court order, subpoena, warrant, or other legal process or legal requirement, or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent, or take action regarding alleged, suspected, or known illegal activities or any activity that may expose us, you, or any third party on whose behalf we act to liability; (2) comply with our legal obligations; (3) comply with legal process and to investigate, respond to, or defend ourselves against any claims or allegations asserted against us; (4) comply with or respond to an audit conducted by a government agency; (5) enforce our Terms of Service or the terms of any agreement between us and you; (6) prevent or mitigate harm to persons or property; or (7) exercise or protect our rights, the rights of our employees, the rights of third parties on whose behalf we act (such as the owners of properties we manage or market), or the rights of the public.
                    </li>
                    <li>
                        We may attempt to notify you about a legal request or demand for your personal data, where we reasonably believe that notification is appropriate, except where (a) disclosure is prohibited by applicable law or by the terms of the legal process or court order we receive, (b) where the request is an emergency, or (c) we believe providing notice would be futile, would create or increase a risk of harm to persons or property, or would create or increase a risk, or prevent the detection of fraud. We may dispute legal demands in instances where we believe a request is overbroad or lacks proper authority. However, we do not undertake to provide notice of every request or challenge any legal demand.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>With vendors that perform services on our behalf.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        We may share your information with vendors or other third parties that provide us with services or software. For example, we may provide your contact information to operators of communication or marketing platforms so that we may use their platform software to communicate with you. We might share information with a business partner who is running a joint promotion with us or who provides a product or service in partnership with us. We may disclose information to our outside auditors in connection with their auditing services.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>With social media platforms and advertising publishers.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        Where permitted by applicable law, we may use certain personal information about you, such as your email address, to hash and share it with advertising publishers such as Facebook or Google to generate leads or otherwise promote our Services. We carry out these activities based on our legitimate interest in conducting marketing activities to offer you services that may be of interest to you. You may ask us to cease using your email address for these marketing activities by submitting a request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>To obtain government permits and to collect and remit taxes.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        We may share identifying information regarding your property with a government entity or agency in connection with applying for, obtaining, maintaining, or renewing a required license or permit if required in connection with our provision of rental management services. Such information may include your full name and the full name of all owners, your contact information, the address of the property, your tax identification number, and information relating to rental activity.
                    </li>
                    <li>
                        In jurisdictions that impose lodging, hotel, occupancy, sales, or other types of taxes on transient or rental occupancy of property, and where an agreement permits us to collect and remit such taxes on behalf of an owner, we may disclose information to government agencies in connection with the collection and remission of taxes. The information we disclose may include your name and contact information, the property address, the dates of stay and transaction amounts, amount of tax collected or due, the permit or license number for the property, and the owner’s tax identification number(s).
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>We may aggregate your information.</Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        We may aggregate data about individuals who use the Services, meaning we combine and anonymize this information. We may share this aggregated information with third parties to help describe our business, analyze our marketing efforts, comply with regulations, obtain insights about our business practices, and refine the services we offer, and for other business purposes. When we share aggregated information, we do not share personally identifiable data about individuals.
                    </li>
                </ul>
            </Paragraph>
            <BackToTop/>

            <Title id={"section5"}>
                5. YOUR RIGHTS AND PREFERENCES
            </Title>
            <Paragraph>
                Communications from SolArt Group Inc or Third Parties.
            </Paragraph>
            <Paragraph>
                <Bold>Transaction-related communications from SolArt Group Inc.</Bold>
                <Box>
                    You may receive business-related communications from SolArt Group Inc relating to a booking you have made or an inquiry about a property. Because these communications are necessary for us to provide you with requested services, you cannot opt out of such communications.
                </Box>
            </Paragraph>
            <Paragraph>
                <Bold>Marketing communications from SolArt Group Inc.</Bold>
                <Box>
                    You may receive marketing communications from SolArt Group Inc as permitted by law. You can register or change your preferences to receive or not receive marketing communications from us by clicking the “unsubscribe” link in the communication you have received or by submitting a request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>. Please allow sufficient time for your preferences to be processed. Even if you opt out of receiving marketing messages, we may still contact you for transactional purposes like confirming or following up on a reservation or home inquiry or asking you to review your stay. If you later opt back into receiving marketing communications from us, we will remove your information from our opt-out databases.
                </Box>
            </Paragraph>
            <Paragraph>
                <Bold>Transaction-related communications from third parties.</Bold>
                <Box>
                    You may receive business-related communications from a third party, such as a resort, relating to a booking you have made or an inquiry about a property. To the extent these communications are necessary for us to provide you with requested services, you cannot opt out of such communications.
                </Box>
            </Paragraph>
            <Paragraph>
                <Bold>Marketing communications from third parties.</Bold>
                <Box>
                    You may receive marketing communications from third parties with which SolArt Group Inc has contracted or with which SolArt Group Inc is running a joint promotion. You may opt out of disclosure of your personal information to such third parties by submitting a request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
                </Box>
                <Box>
                    We do not sell your personal information to non-agent third parties. Therefore, there is no ability or need to opt in to or opt out of such sales.
                </Box>
            </Paragraph>
            <Paragraph>
                <Bold>Your rights to access and control your personal information.</Bold>
                <Box>
                    In addition to the choices described elsewhere in this document, you may have additional rights concerning the collection, use, and sharing of your data. To the extent that we are legally or otherwise obligated to fulfill one or more of the following requests on the basis of your legal right(s) to cause us to do so, you may exercise any of the rights described in this section by submitting a request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
                </Box>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        <Bold>Update your personal information.</Bold> You may ask us to correct inaccurate or incomplete personal information about you.
                    </li>
                    <li>
                        <Bold>Right to access your personal information. In some jurisdictions, you may have the right to obtain a copy of your personal information in our possession.</Bold> You may also have the rights to request a copy of personal information that you provided to us in machine-readable form, and to request that we transfer this information to another service provider if technically feasible to do so.
                    </li>
                    <li>
                        <Bold>Right to request deletion of your data. In some jurisdictions, you may have the right to ask us to erase or delete some or all of your personal information.</Bold> Requests to delete your data are subject to the “data retention and deletion” provisions of this Privacy Policy.
                    </li>
                    <li>
                        <Bold>Right to object to, withdraw consent to, or limit use of your information.</Bold> In some jurisdictions, you may have the right to ask us to stop using some or all of your personal information for certain purposes if our processing of your information is based on legitimate interests. In such cases, we will no longer use your personal information for such purposes unless: (i) our use is required to establish, exercise, or defend legal claims or (ii) we demonstrate compelling legitimate grounds for our use of the information. If our use of your personal information is based on your consent, you may withdraw your consent at any time by sending a request stating what consent you are withdrawing. Any withdrawal of consent is prospective only (meaning it does not apply to our use of your data before we receive the withdrawal).
                    </li>
                    <li>
                        In some jurisdictions, you may also have the right to limit the ways we use your personal information, especially if the information is inaccurate or our use of the data is unlawful.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>Data retention and deletion.</Bold>
                <Box>
                    We retain your personal information for as long as necessary to perform the contract between us and you and to comply with legal requirements. The length of time we retain your information depends on type of information we have collected, the purpose for which we have collected it and the laws of the applicable jurisdiction(s) (usually the jurisdiction of your residence, the jurisdiction of the property where you have booked a stay or that we are managing, or both).
                </Box>
                <Box>
                    In general, we retain your personal data as necessary to comply with our legal obligations, such as obligations to retain and report information for tax, auditing, and regulatory compliance purposes. We may also retain some of your personal information as necessary to meet our legitimate business interests, such as to enhance our ability to detect fraud or to assert or defend against an actual or potential legal claim by you or a third party.
                </Box>
                <Box>
                    If you share information with others in connection with your use of the Services, such as by writing a review or engaging directly with a third party, your information may continue to be visible to the public or to the third party even after we delete it.
                </Box>
                <Box>
                    Even after we delete your information, portions of your data may remain in aggregated, anonymized, or tokenized form, but are not associated with personal identifiers. In addition, because we regularly back up our systems, portions of your personal information may continue to reside in backup copies for a period of time after it is deleted from our main systems. If you use our mobile application for SolArt Group Inc guests or SolArt Group Inc homeowners and you delete your user account within the application, we will retain certain account-related reservation and transaction information as needed to comply with any applicable legal requirements, or to meet our legitimate business interests (including to assist or defend against an actual or potential legal claim by you or a third party).
                </Box>
                <Box>
                    <Bold>Lawful bases to collect, use, and share your personal information.</Bold>
                </Box>
                <Box>
                    We will only collect, use, and share your personal information where we have a lawful basis to do so. Lawful bases include (i) contract (if processing is necessary for the performance of a contract with you), (ii) consent (where you have given consent), (iii) legitimate interests or (iv) as otherwise may be allowed by applicable law and consistent with this Privacy Policy.
                </Box>
            </Paragraph>
            <BackToTop/>

            <Title id={"section6"}>
                6. YOUR PRIVACY AND OUR GLOBAL OPERATIONS
            </Title>
            <Paragraph>
                <Bold>Data controller.</Bold>
                <Box>
                    The SolArt Group Inc company that is responsible for your information under this Privacy Policy is the “data controller.”
                </Box>
                <Box>
                    If you reside outside of Europe, the data controller for purposes of your data is SolArt Group Inc.
                </Box>
            </Paragraph>
            <Paragraph>
                <Bold>Cross-border data transfers.</Bold>
                <Box>
                    SolArt Group Inc operates in multiple jurisdictions. We process data both inside and outside the United States and Europe, and we may share your data among affiliated companies or with service providers based in countries other than your country of residence or the country in which Services are provided to you. Countries in which we process data may have laws that differ from the laws in your country of residence.
                </Box>
                <Box>
                    When you submit personal data directly to SolArt Group Inc or its non-European subsidiaries or affiliates, that data may be transferred to, processed, and stored in the United States. If you are located in Europe, personal data you transmit to a European subsidiary or affiliate of SolArt Group Inc (or to a third party located in Europe) may be transferred to SolArt Group Inc subject to applicable rules concerning adequate levels of data protection.
                </Box>
            </Paragraph>
            <Paragraph>
                <Bold>Safeguards in place to ensure an adequate level of data protection.</Bold>
                <Box>
                    Information collected within Europe may be transferred, processed, or stored outside Europe as described in this Privacy Policy. If we transfer, process, or store personal information from Europe to or in a country outside Europe, we ensure that safeguards are in place to ensure an adequate level of data protection. These safeguards may be based on a determination by the European Commission or Swiss Federal Data Protection and Information Commissioner that a country outside Europe has an adequate level of data protection, or on the use of model contract clauses approved by European Union or Swiss data protection authorities.
                </Box>
            </Paragraph>
            <Paragraph>
                <Bold>Additional privacy rights</Bold>
                <Box>
                    Depending on the laws of the country or state in which you reside, you may have additional rights concerning our collection, use, and disclosure of your personal information, as further described at the end of this Privacy Policy.
                </Box>
            </Paragraph>
            <BackToTop/>

            <Title id={"section7"}>
                7. HOW WE SECURE YOUR INFORMATION
            </Title>
            <Paragraph>
                When you place an order or make a booking on our websites, all of your order information, including your credit card number and delivery address, is transmitted using Secure Sockets Layer (SSL) technology. SSL technology causes your browser to encrypt your information before transmitting it to our secure server. SSL technology, an industry standard, is designed to prevent someone other than operators of our websites from capturing and viewing your personal information.
            </Paragraph>
            <Paragraph>
                We use industry-standard technical measures to protect our websites and your information, and we monitor for and attempt to prevent security breaches. We update and control access to our systems to protect your information, but we cannot guarantee that your information may not be accessed, disclosed, altered, lost or destroyed by breach of any of our security measures. In the event of a data breach, we will comply with applicable laws relating to notification.
            </Paragraph>
            <Paragraph>
                <Link to={"/security"}>Find details about information security at SolArt Group Inc.</Link>
            </Paragraph>
            <BackToTop/>

            <Title id={"section8"}>
                8. LINKS TO THIRD-PARTY SITES
            </Title>
            <Paragraph>
                Our websites may contain links to third-party websites. If you click on one of those links, you will be taken to websites we do not control and for which we are not responsible. This Privacy Policy does not apply to the information practices of those websites. You should read the privacy policies of other websites carefully.
            </Paragraph>
            <Paragraph>
                Some portions of the Services implement Google Maps/Earth mapping services, including Google Maps API(s). Your use of Google Maps/Earth is subject to <a href={"https://www.google.com/intl/en_us/help/terms_maps.html"} target={"_blank"}>Google’s terms of use</a>, which incorporates <a href={"https://www.google.com/policies/privacy"} target={"_blank"}>Google’s Privacy Policy</a>.
            </Paragraph>
            <BackToTop/>

            <Title id={"section9"}>
                9. OUR SITES AND CHILDREN
            </Title>
            <Paragraph>
                Our websites and mobile applications are not created for children. To our knowledge we do not collect information online from children under 18. If you are a parent or guardian and think we have information about your child, please contact your data controller at the address listed in Section 11. To learn more about how to protect your child online, visit the <a href={"https://www.consumer.ftc.gov/topics/protecting-kids-online"} target={"_blank"}>US Federal Trade Commission’s materials on protecting children online</a>.
            </Paragraph>
            <BackToTop/>

            <Title id={"section10"}>
                10. CHANGES TO THIS PRIVACY POLICY
            </Title>
            <Paragraph>
                <Bold>This Version:</Bold> This version became effective on January 1, 2024.
            </Paragraph>
            <Paragraph>
                <Bold>Policy updates</Bold>
                <Box>
                    From time to time we may update our policies. We will notify you of any material changes to our Privacy Policy by posting the updated version on our website and updating the “Last Updated” date and the beginning of this Privacy Policy and the “This Version” date in this section. Please check our website periodically for updates.
                </Box>
            </Paragraph>
            <BackToTop/>

            <Title id={"section11"}>
                11. CONTACTING US
            </Title>
            <Paragraph>
                If you have any questions regarding this Privacy Policy, you may contact us using the information below.
            </Paragraph>
            <Paragraph>
                SolArt Group Inc
                By email: support@solartgroupinc.com
                By Phone: +1 (916) 520-8246
            </Paragraph>
            <BackToTop/>

            <Title id={"section11"}>
                12. COOKIE NOTICE
            </Title>
            <Paragraph>
                Last Updated: January 1, 2024
            </Paragraph>
            <Paragraph>
                Like almost all commercial websites, SolArt Group Inc’s website uses cookies to improve the functionality of our website and streamline your user experience. You may delete and block all cookies from this site, but parts of the site will not work correctly (or at all). Even if you are only browsing the site, certain information about your visit will be automatically collected. This information includes information about the platform and operating system you are using, your browser type and version, computer and connection information, and what time you accessed the site. Unless you opt out, this information will be collected every time you access SolArt Group Inc’s website.
            </Paragraph>
            <Paragraph>
                <Bold>
                    What are cookies?
                </Bold>
            </Paragraph>
            <Paragraph>
                A cookie is a small text file that is placed in your browser to allow a website to recognize your browser or device. Cookies are used for many purposes, such as enabling certain site functionality, storing user preferences, permitting analytics, and enabling ad delivery. Cookies can help us understand how visitors use our site, help you navigate between pages efficiently, help remember your preferences for later visits, and generally improve your browsing experience. Cookies can also help ensure that marketing you see online is relevant to you and your interests.
            </Paragraph>
            <Paragraph>
                A cookie is not spyware or a virus, and it does not give us access to your computer or any information about you personally, other than the data you choose to share with us.
            </Paragraph>
            <Paragraph>
                The length of time a cookie will stay on your computer or mobile device depends on whether it is a “session” or “persistent” cookie. A session cookie will only remain on your device until you close your browser. A persistent cookie will remain until it expires or is deleted. Each persistent cookie has an expiration date; some cookies may expire after a few minutes while others may not expire for several years.
            </Paragraph>
            <Paragraph>
                <Bold>
                    What types of cookies does SolArt Group Inc use?
                </Bold>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>
                        <Bold>Essential Cookies.</Bold> These first-party cookies are also known as “strictly necessary” cookies, and as the name suggests, they are necessary to allow you to move around the SolArt Group Inc site and use its features, such as “Save and View Favorites.”
                    </li>
                    <li>
                        <Bold>Analytics Cookies.</Bold> Also known as “performance cookies,” analytics cookies collect information about visits to our sites and how the services we offer are being used. We use this data to make improvements and report our performance. For example, these cookies collect information about how visitors use our sites, which site the visitor came from, the number of each user’s visits and how long a user stays on the site. We might also use analytics cookies to test new ads, pages, or features to see how users react to them. Analytics cookies are primarily third-party cookies.
                    </li>
                    <li>
                        <Bold>Functionality Cookies.</Bold> Sometimes called “preference cookies,” these first-party cookies allow us to remember information you have entered or choices you have made (such as your username, language, region, and marketing preferences) on our sites, so the next time you visit the site you will not have to set them again. These cookies also allow us to provide a better user experience on our website.
                    </li>
                    <li>
                        <Bold>Targeting Cookies.</Bold> SolArt Group Inc and our advertising partners or other third-party partners may use these types of cookies, also known as “advertising cookies,” to deliver advertising and track ad performance, and enable advertising networks to deliver ads that may be relevant to you based upon your activities (this is sometimes called “behavioral” or “targeted” advertising) on our sites.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Bold>
                    How can you manage and delete cookies?
                </Bold>
            </Paragraph>
            <Paragraph>
                Most browsers accept cookies automatically. However, you can change the settings in your browser to delete cookies that have already been set and to not accept new cookies. Note that if you set your browser to disable cookies, you may not be able to access certain parts of the SolArt Group Inc sites and some services we offer may not work properly or at all.
            </Paragraph>
            <Paragraph>
                <Box>
                    <a href={"https://support.google.com/chrome/answer/95647?hl=en"} target={"_blank"}>Learn about managing cookie settings in Google Chrome.</a>
                    <a href={"https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"} target={"_blank"}>Learn about managing cookie settings in Mozilla Firefox.</a>
                    <a href={"https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"} target={"_blank"}>Learn about managing cookie settings in Mozilla Firefox.</a>
                    <a href={"http://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11"} target={"_blank"}>Learn about managing cookie settings in Internet Explorer.</a>
                    <a href={"https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"} target={"_blank"}>Learn about managing cookie settings in Safari.</a>
                    <a href={"https://www.opera.com/help/tutorials/security/privacy/"} target={"_blank"}>Learn about managing cookie settings in Opera.</a>
                </Box>
            </Paragraph>
            <Paragraph>
                Browsers on mobile devices may have different ways to manage cookie settings.
            </Paragraph>
            <Paragraph>
                <Bold>
                    SolArt Group Inc may use other technology that is standard to the Internet, such as pixel tags, web beacons, and other similar technologies, to track visitors to our sites.
                </Bold>
            </Paragraph>
            <Paragraph>
                Do Not Track disclosure.
            </Paragraph>
            <Paragraph>
                <Bold>
                    Do Not Track is a privacy preference that users can set in certain web browsers. When a user turns on the Do Not Track signal, the browser sends a message to websites requesting them not to track the user. For information about Do Not Track, visit <a href={"www.allaboutdnt.org"} target={"_blank"}>www.allaboutdnt.org</a>. At this time, SolArt Group Inc does not respond to Do Not Track browser settings or signals. Internet-standard tracking tools may be used by us and by third parties to collect information about you and your Internet activity, as disclosed in our Privacy Policy, even if you have turned on the Do Not Track signal.
                </Bold>
            </Paragraph>
            <BackToTop/>

            <Title id={"section13"}>
                13. CALIFORNIA PRIVACY NOTICE
            </Title>
            <Paragraph>
                Pursuant to the California Consumer Privacy Act of 2018, as amended by the California Privacy Rights Act, (collectively referred to herein as the “CCPA/CPRA”), this notice provides information regarding the personal information we collect about California residents and the rights afforded to such individuals under the CCPA/CPRA.
            </Paragraph>
            <Paragraph>
                As is described in this Privacy Policy, we collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household—otherwise known as “personal information.”
            </Paragraph>
            <Paragraph>
                Under the CCPA/CPRA, personal information does not include certain types of publicly available information from government records, de-identified or aggregated consumer information that is appropriately anonymized, or certain information excluded from the CCPA/CPRA’s scope, such as health or medical information covered by HIPAA and personal information covered by certain sector-specific privacy laws, including the FCRA and GLBA.
            </Paragraph>
            <Paragraph>
                We collect personal information, some of which may be “sensitive personal information” under the CCPA/CPRA, from residents of California, such as guests who stay in SolArt Group Inc homes, the owners of such homes, and individuals interested in our property management and real estate services. Over the past 12 months, we collected the categories of personal information that are described in the <a href={"#section2"}>Information Collected or Received</a> section above, including:
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>Payment information</li>
                    <li>Identity information</li>
                    <li>Contact information</li>
                    <li>Bank account and tax information</li>
                    <li>Information provided during communications with SolArt Group Inc</li>
                    <li>Information provided in connection with a booking or stay</li>
                    <li>Information obtained in connection with rental management services</li>
                    <li>Information about your site visits</li>
                    <li>Device and browser information</li>
                    <li>Location information</li>
                    <li>Other information as required by applicable law</li>
                </ul>
            </Paragraph>
            <Paragraph>
                We collect and use such information for the purposes described in the <a href={"#section3"}>How We Use Your Information</a> section above, including:
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>To fulfill the terms of a contract between you and us</li>
                    <li>To respond to your inquiries or requests</li>
                    <li>To communicate with you about reservations and stays</li>
                    <li>To fulfill contractual or legal requirements relating to your request or the Services you are using</li>
                    <li>To provide customer service</li>
                    <li>To provide rental management or similar services</li>
                    <li>To detect and prevent fraud or illegal activity</li>
                    <li>To make or receive payments from you</li>
                    <li>To optimize, customize and improve our services and your user experience</li>
                    <li>To conduct, measure, and improve our advertising and marketing</li>
                    <li>To comply with our legal and contractual obligations and for security and investigation purposes</li>
                </ul>
            </Paragraph>
            <Paragraph>
                For purposes of the CCPA/CPRA, the following activities may constitute a “sale” in some instances:
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>Sharing information about you with local third-party business partners who may offer additional services in connection with a property you have booked. For example, we may provide your name and email address to a company that offers concierge services for the property you have booked.</li>
                    <li>Using certain personal information about you, such as your email address, to hash and share it with advertising publishers such as Facebook or Google to generate leads or otherwise promote our Services. We carry out these activities based on our legitimate interest in conducting marketing activities to offer you services that may be of interest to you.</li>
                </ul>
            </Paragraph>
            <Paragraph>
                We do not otherwise “sell” personal information.
            </Paragraph>
            <Paragraph>
                <Bold>Exercising Your California Privacy Rights</Bold>
            </Paragraph>
            <Paragraph>
                The CCPA/CPRA and other California law provide residents of California with the rights listed below. If you are a California resident, you may exercise your rights by submitting a verifiable consumer request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
                <ul>
                    <li>
                        <Bold>Right to Access:</Bold> You have the right to know certain information about the data we have collected about you, including:
                        <ul>
                            <li>The categories of personal information we’ve collected from you;</li>
                            <li>The categories of sources from which the personal information was collected;</li>
                            <li>The business or commercial purpose(s) for collecting personal information about you;</li>
                            <li>The categories of personal information about you which we shared and/or sold;</li>
                            <li>The categories of third parties we have shared or sold personal information about you to; and</li>
                            <li>The specific pieces of personal information about you that we have collected.</li>
                        </ul>
                    </li>
                    <li>
                        <Bold>Right to Delete:</Bold> You have the right to request that we delete certain personal information about you that we have collected from you (and request that we direct applicable service providers to do the same). Where an exception applies, we may not fulfill your request for deletion.
                    </li>
                    <li>
                        <Bold>Right to Opt Out:</Bold> You have the right to opt out of any future “sale” or “sharing” of personal information about you. You may do so by submitting a request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
                    </li>
                    <li>
                        <Bold>Right to Limit Use and Disclosure of Sensitive Personal Information:</Bold> You have the right to limit the processing of “sensitive personal information” to purposes permitted under the CCPA/CPRA, such as performing services you have requested, security, quality control, as authorized by law, and disclosures to vendors in support of the permitted purposes. This right only applies to “sensitive personal information” collected or processed by us for the purpose inferring characteristics about you. You may do so by submitting a request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
                    </li>
                    <li>
                        <Bold>Other Rights:</Bold> If you live in California and have an established business relationship with us, you are entitled to ask us for a notice describing what categories of personal customer information SolArt Group Inc shares with third parties or corporate affiliates for those third parties’ or corporate affiliates' direct marketing purposes. That notice will identify the categories of information shared and will include a list of the third parties and affiliates with which it was shared, along with their names and addresses. If you are a California resident and would like a copy of this notice, please submit your request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may only make a verifiable consumer request for access or data portability twice within a 12-month period.
            </Paragraph>
            <Paragraph>
                We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.
            </Paragraph>
            <Paragraph>
                Your verifiable consumer request must:
                <ul>
                    <li>
                        Provide sufficient information that allows us to reasonably verify that you are (a) the person about whom we collected personal information or (b) an authorized representative of such person.
                    </li>
                    <li>
                        Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>
                You can submit a verifiable consumer request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>. Upon receiving your request, we will respond promptly. To the extent that we successfully verify your identity and determine that we will fulfill part or all of your request, we will complete the request within 45 days of receiving it, unless we require an extension, in which case we will inform you why we need more time and how much time we need.
            </Paragraph>
            <Paragraph>
                We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
            </Paragraph>
            <Paragraph>
                We will not discriminate against you for exercising any of your CCPA/CPRA rights.
            </Paragraph>
            <Paragraph>
                <Bold>Changes to this Notice</Bold>
            </Paragraph>
            <Paragraph>
                We reserve the right to amend this notice at our discretion and at any time. When we make changes to this notice, we will post the updated notice on this page and update the notice’s effective date.
            </Paragraph>
            <Paragraph>
                <Bold>Contact Information</Bold>
            </Paragraph>
            <Paragraph>
                If you have any questions or comments about this notice, the ways in which we collect and use your information, or your choices and rights regarding such use, you can contact us at <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
            </Paragraph>
            <BackToTop/>

            <Title id={"section14"}>
                14. NEVADA PRIVACY NOTICE
            </Title>
            <Paragraph>
                Certain Nevada residents have the right to submit a request (subject to verification of the authenticity of the request and the identity of the requestor) to opt out of the “sale” of personally identifiable information, as applicable under Nevada law. For purposes of this section, personally identifiable information includes a natural person’s first and last name, home or other physical address which includes the name of a street and the name of a city or town, email address, telephone number, social security number, and an identifier that allows a specific person to be contacted either physically or online, as collected through our website. If you are a Nevada resident, you may request to exercise your rights, as applicable, by submitting a request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
                We will not discriminate against you for exercising any of your rights under Nevada’s data privacy law.
            </Paragraph>
            <Paragraph>
                <Bold>Contact information</Bold>
            </Paragraph>
            <Paragraph>
                If you have any questions or comments about this notice, the ways in which we collect and use your information, or your choices and rights regarding such use, you can contact us at <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
            </Paragraph>
            <BackToTop/>

            <Title id={"section15"}>
                15. PRIVACY NOTICE OF OTHER U.S. STATES
            </Title>
            <Paragraph>
                Residents of certain other states, such as Virginia, Colorado, and Connecticut, may have certain rights regarding their personal information or personal data, as defined in these states’ laws (collectively as used this in section, “personal information”). The privacy laws of these other U.S. states are referred to in this section as “State Privacy Laws.”
            </Paragraph>
            <Paragraph>
                Under the State Privacy Laws, personal information may not include certain types of publicly available information, de-identified or aggregated consumer information, or certain information excluded from scope of the laws, such as protected health information covered by HIPAA and personal information covered by certain sector-specific privacy laws, including the FCRA and GLBA.
            </Paragraph>
            <Paragraph>
                We collect personal information, some of which may be “sensitive personal information” under the CCPA/CPRA, from residents of California, such as guests who stay in SolArt Group Inc homes, the owners of such homes, and individuals interested in our property management and real estate services. Over the past 12 months, we collected the categories of personal information that are described in the <a href={"#section2"}>Information Collected or Received</a> section above, including:
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>Payment information</li>
                    <li>Identity information</li>
                    <li>Contact information</li>
                    <li>Bank account and tax information</li>
                    <li>Information provided during communications with SolArt Group Inc</li>
                    <li>Information provided in connection with a booking or stay</li>
                    <li>Information obtained in connection with rental management services</li>
                    <li>Information about your site visits</li>
                    <li>Device and browser information</li>
                    <li>Location information</li>
                    <li>Other information as required by applicable law</li>
                </ul>
            </Paragraph>
            <Paragraph>
                We collect and use such information for the purposes described in the <a href={"#section3"}>How We Use Your Information</a> section above, including:
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>To fulfill the terms of a contract between you and us</li>
                    <li>To respond to your inquiries or requests</li>
                    <li>To communicate with you about reservations and stays</li>
                    <li>To fulfill contractual or legal requirements relating to your request or the Services you are using</li>
                    <li>To provide customer service</li>
                    <li>To provide rental management or similar services</li>
                    <li>To detect and prevent fraud or illegal activity</li>
                    <li>To make or receive payments from you</li>
                    <li>To optimize, customize and improve our services and your user experience</li>
                    <li>To conduct, measure, and improve our advertising and marketing</li>
                    <li>To comply with our legal and contractual obligations and for security and investigation purposes</li>
                </ul>
            </Paragraph>
            <Paragraph>
                We disclose information to the categories of entities set forth above in [How We Share or Disclose Your Information].
            </Paragraph>
            <Paragraph>
                For purposes of certain State Privacy Laws, the following activities may constitute a “sale” in some instances, such as disclosing information about you with local third-party business partners who may offer additional services in connection with a property you have booked. For example, we may provide your name and email address to a company that offers concierge services for the property you have booked. We do not otherwise “sell” personal information as that term is defined by State Privacy Laws.
            </Paragraph>
            <Paragraph>
                For purposes of the State Privacy Laws, we process personal information in a manner that constitutes “targeted advertising.” We may use certain personal information about you, such as your email address, to hash and share it with advertising publishers such as Facebook or Google to generate leads or otherwise promote our Services.
            </Paragraph>
            <Paragraph>
                <Bold>Exercising Your Privacy Rights of State Privacy Laws</Bold>
            </Paragraph>
            <Paragraph>
                Depending on your state of residence, you may request to exercise your rights, as applicable, by submitting a verifiable consumer request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
                <ul>
                    <li><Bold>Right to Access:</Bold> To know whether or not we are processing your personal information and to access (obtain a copy of) such personal information that you previously provided to us.</li>
                    <li><Bold>Right to Correct:</Bold> To correct inaccuracies in your personal information, as applicable, taking into account the nature of the personal information and the purposes of the processing</li>
                    <li><Bold>Right to Delete:</Bold> To request that we delete certain personal information about you. Where an exception applies, we may not fulfill your request for deletion.</li>
                    <li><Bold>Right to Opt Out:</Bold> To opt out of targeted advertising or the “sale” of personal information about you. You may do so by submitting a request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.</li>
                </ul>
            </Paragraph>
            <Paragraph>
                We do not process personal information for the purpose of profiling in furtherance of decisions that produce legal or similarly significant effects concerning the consumer, as defined by State Privacy Laws.
            </Paragraph>
            <Paragraph>
                Certain information may be exempt from such requests under applicable law. We need certain types of information so that we can provide the Services to you. If you ask us to delete it, you may no longer be able to access or use the Services.
            </Paragraph>
            <Paragraph>
                Only you or an authorized agent as authorized by State Privacy Laws may make a verifiable consumer request related to your personal information.
            </Paragraph>
            <Paragraph>
                We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.
            </Paragraph>
            <Paragraph>
                Your verifiable consumer request must:
            </Paragraph>
            <Paragraph>
                <ul>
                    <li>Provide sufficient information that allows us to reasonably verify that you are (a) the person about whom we collected personal information or (b) an authorized representative of such person.</li>
                    <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
                </ul>
            </Paragraph>
            <Paragraph>
                You can submit a verifiable consumer request via <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>. Upon receiving your request, we will respond promptly. To the extent that we successfully verify your identity and determine that we will fulfill part or all of your request, we will complete the request within 45 days of receiving it, unless we require an extension, in which case we will inform you why we need more time and how much time we need.
            </Paragraph>
            <Paragraph>
                We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
            </Paragraph>
            <Paragraph>
                We will not discriminate against you for requesting to exercise your State Privacy Law rights. In the event that we deny your verifiable consumer request, you may appeal the decision by contacting <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a> with the subject line “State Privacy Rights Appeal.” Your appeal must describe in detail why you believe the denial was in error. Your description must include your full name and the email address used for your account with us, along with a copy of the denial notice you received from us.
            </Paragraph>
            <Paragraph>
                <Bold>Changes to this Notice</Bold>
            </Paragraph>
            <Paragraph>
                We reserve the right to amend this notice at our discretion and at any time. When we make changes to this notice, we will post the updated notice on this page and update the notice’s effective date.
            </Paragraph>
            <Paragraph>
                <Bold>Contact information</Bold>
            </Paragraph>
            <Paragraph>
                If you have any questions or comments about this notice, the ways in which we collect and use your information, or your choices and rights regarding such use, you can contact us at <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>.
            </Paragraph>
            <BackToTop/>

            <Title id={"section16"}>
                16. DATA REQUEST FORM
            </Title>
            <Paragraph>
                <a href="mailto:support@solartgroupinc.com" target="_blank" rel="noopener noreferrer">support@solartgroupinc.com</a>
            </Paragraph>
            <BackToTop/>

            <BreakElement/>
            <BreakElement/>
        </Grid>
        <Grid item xs={0} sm={1} md={2} />
    </Grid>
}
