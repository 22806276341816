import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {email, required, requiredNumber} from "app/modules/common/form/validators";
import {action, makeObservable, observable, runInAction} from "mobx";
import {PropertyContactEditModel} from "app/modules/main/api/admin-clients.api";

export class DetailsStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    contactId: number = null;
    name: string = null;

    formState = new FormState({
        name: new FieldState('').validators(required),
        email: new FieldState('').validators(email),
        phone: new FieldState<number>(null).validators(requiredNumber),
        notes: new FieldState(''),
    });

    constructor() {
        super();

        makeObservable(this, {
            contactId: observable,
            name: observable,

            save: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if (this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            let model = new PropertyContactEditModel({
                name: this.formState.$.name.value,
                email: this.formState.$.email.value,
                phone: this.formState.$.phone.value,
                note: this.formState.$.notes.value,
                id: this.contactId
            });

            if(this.contactId > 0) {
                await this.apiClients.propertyContactClient.upsertPropertyContact(model);
            } else {
                this.contactId = await this.apiClients.propertyContactClient.upsertPropertyContact(model);

                runInAction(() => {
                    this.isSavedSuccessfully = true;
                });
            }
        });
    }

    init = async (id?: number) => {
        this.contactId = id;

        await this.wrapInitLoadingApiCall(async () => {
            if(this.contactId > 0) {
                let result = await this.apiClients.propertyContactClient.getDetails(this.contactId);

                runInAction(() => {
                    this.name = result.name;

                    this.formState.$.name.value = result.name;
                    this.formState.$.email.value = result.email;
                    this.formState.$.phone.value = result.phone;
                    this.formState.$.notes.value = result.note;
                });
            }
        });
    }
}
