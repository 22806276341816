import {Component} from "react";
import React from "react";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {Box, Grid, Link} from "@mui/material";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Loading} from "app/modules/common/layout/components/loading";
import {inject, provider, useInstance} from "react-ioc";
import {ListStore} from "app/modules/main/components/admin/requests/list/ListStore";
import {observer} from "mobx-react";
import ModelDetailsIndex from "app/modules/main/components/admin/books/details/modal";
import {
    GetReservationCompletionInfoModelStatus,
    PropertyReservationStatus,
    ReservationListModel
} from "app/modules/main/api/admin-clients.api";
import {DateHelper} from "app/modules/main/components/common/DateHelper";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";

export const reservationStatusMap: Map<PropertyReservationStatus, string> = new Map<PropertyReservationStatus, string>([
    [PropertyReservationStatus.Requested, "Requested"],
    [PropertyReservationStatus.Confirmed, "Confirmed"],
    [PropertyReservationStatus.Cancelled, "Cancelled"],
]);

export const reservationColorMap: Map<PropertyReservationStatus, string> = new Map<PropertyReservationStatus, string>([
    [PropertyReservationStatus.Requested, "#cbaa25"],
    [PropertyReservationStatus.Confirmed, "#4caf50"],
    [PropertyReservationStatus.Cancelled, "#ff1744"],
]);

export const reservationCompletionInfoModelStatusMap: Map<GetReservationCompletionInfoModelStatus, string> = new Map<GetReservationCompletionInfoModelStatus, string>([
    [GetReservationCompletionInfoModelStatus.Pending, "Pending"],
    [GetReservationCompletionInfoModelStatus.Failed, "Failed"],
    [GetReservationCompletionInfoModelStatus.Success, "Success"],
]);

export const reservationCompletionInfoModelColorMap: Map<GetReservationCompletionInfoModelStatus, string> = new Map<GetReservationCompletionInfoModelStatus, string>([
    [GetReservationCompletionInfoModelStatus.Pending, "#cbaa25"],
    [GetReservationCompletionInfoModelStatus.Success, "#4caf50"],
    [GetReservationCompletionInfoModelStatus.Failed, "#ff1744"],
]);

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 50,
        flex: 1,
        renderCell: (params: GridRenderCellParams<object>) => {
            let store = useInstance(ListStore);

            return (
                <Link onClick={() => store.selectBook((params.row as ReservationListModel).id)} sx={{ cursor: "pointer" }}>
                    {(params.row as ReservationListModel).id}
                </Link>
            );
        },
    },
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 3,
        renderCell: (params: GridRenderCellParams<object>) => {
            let store = useInstance(ListStore);

            return (
                <Link onClick={() => store.selectBook((params.row as ReservationListModel).id)} sx={{ cursor: "pointer" }}>
                    {(params.row as ReservationListModel).name}
                </Link>
            );
        },
    },
    {
        field: 'guest',
        headerName: 'Guest Name',
        minWidth: 150,
        flex: 3,
        renderCell: (params: GridRenderCellParams<object>) => (
            (params.row as ReservationListModel).guestName
        )
    },
    {
        field: 'nights',
        headerName: 'Nights',
        maxWidth: 70,
        flex: 1,
        renderCell: (params: GridRenderCellParams<object>) => (
            (params.row as ReservationListModel).nights
        )
    },
    {
        field: 'paymentStatus',
        headerName: 'Payment Status',
        maxWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<object>) => (
            <Box sx={{ color: reservationCompletionInfoModelColorMap.get((params.row as ReservationListModel).paymentStatus) }}>{reservationCompletionInfoModelStatusMap.get((params.row as ReservationListModel).paymentStatus)}</Box>
        )
    },
    {
        field: 'period',
        headerName: 'Period',
        minWidth: 200,
        flex: 3,
        renderCell: (params: GridRenderCellParams<object>) => (
            DateHelper.formatMomentDate((params.row as ReservationListModel).from) + " - " + DateHelper.formatMomentDate((params.row as ReservationListModel).to)
        )
    },
    {
        field: 'total',
        headerName: 'Total',
        maxWidth: 120,
        flex: 1,
        renderCell: (params: GridRenderCellParams<object>) => (
            NumberHelper.formatUSDollar((params.row as ReservationListModel).total)
        )
    },
    {
        field: 'code',
        headerName: 'Code',
        maxWidth: 140,
        flex: 1,
        renderCell: (params: GridRenderCellParams<object>) => (params.row as ReservationListModel).reservationCode
    },
    {
        field: 'status',
        headerName: 'Status',
        maxWidth: 120,
        flex: 1,
        renderCell: (params: GridRenderCellParams<object>) => <Box sx={{ color: reservationColorMap.get((params.row as ReservationListModel).status) }}>{reservationStatusMap.get((params.row as ReservationListModel).status)}</Box>
    }
];

@provider(ListStore)
@observer
export class Index extends Component<any, any> {
    @inject listStore: ListStore;

    render() {
        if(this.listStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={12}>
                <PageTitleChip Title={"Requests"} TotalRows={this.listStore.totalRows}/>
            </Grid>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.listStore.items}
                    columns={columns}
                    paginationModel={{ page: this.listStore.skipRows/this.listStore.pageSize, pageSize: this.listStore.pageSize }}
                    pageSizeOptions={this.listStore.pageSizeOptions}
                    rowCount={this.listStore.totalRows}
                    loading={this.listStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    rowSelection={false}
                    checkboxSelection={false}
                    onPaginationModelChange={(model) => this.listStore.setPage(model.page, model.pageSize)}/>
            </DataGridBody>
            { this.listStore.selectedBook > 0 && <ModelDetailsIndex close={() => this.listStore.selectBook(null)} bookId={this.listStore.selectedBook}/>}
        </DataGridContainer>;
    }

    async componentDidMount() {
        this.listStore.init();
    }
}
