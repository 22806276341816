import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {action, makeObservable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {
    DataListGetParamsOfPropertyAmenityListFilters,
    PropertyAmenityListFilters, PropertyAmenityListModel
} from "app/modules/main/api/admin-clients.api";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";

export class ListStore extends BaseListStore<PropertyAmenityListModel>{
    @inject apiClients: ApiClients;
    @inject adminStore: AdminStore;

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new PropertyAmenityListFilters({ });
            const [result] = await Promise.all([
                this.apiClients.propertyAmenityClient.getList(new DataListGetParamsOfPropertyAmenityListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    async init() {
        this.adminStore.selectMenu(AdminMenu.PropertyAmenity);

        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
