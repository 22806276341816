import {observer} from "mobx-react";
import {Button, Grid, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import Details from "app/modules/main/components/main/book/details";
import {useInstance} from "react-ioc";
import {BookStore} from "app/modules/main/components/main/book/BookStore";

import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from "app/modules/main/components/main/book/CheckoutForm";
import {Loading} from "app/modules/common/layout/components/loading";

export default observer(() => {
    const [isLoading, setIsLoading] = useState(true);
    const bookStore = useInstance(BookStore);

    let fetch = async () => {
        setIsLoading(true);
        await bookStore.initPayment();
        setIsLoading(false);
    }

    useEffect( () => {
        fetch();
    }, [])

    let backButton = <Button variant={"text"} sx={{ fontSize: "12px" }} onClick={() => bookStore.backToTermsStep()}>{"<"} Back to Terms Info Step</Button>;

    if(isLoading) {
        return <Loading />;
    }

    let paymentForm = <Elements stripe={bookStore.stripePromise} options={{ clientSecret: bookStore.paymentIntentClientSecret }}>
        <CheckoutForm />
    </Elements>;

    return (<Grid container>
        <Grid item xs={12} sx={{ display: { xs: "block", md: "none" }, p: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>{backButton}</Grid>
                <Grid item xs={12}><Details bookInfo={bookStore.bookInfo}/></Grid>
                <Grid item xs={12}>{paymentForm}</Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "block" }, pl: 2, pr: 2 }}>
            <Grid container spacing={1}>
                <Grid item xs={0} sm={1} md={1} lg={2} xl={3}></Grid>
                <Grid item xs={12} sm={10} md={10} lg={8} xl={6} sx={{ pr: 2 }}>
                    <div>{backButton}</div>
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={2} xl={3}></Grid>

                <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
                <Grid item xs={6} sm={6} md={5} lg={4} xl={3} sx={{ pr: 2 }}>
                    <Stack spacing={2}>
                        {paymentForm}
                    </Stack>
                </Grid>
                <Grid item xs={6} sm={6} md={5} lg={4} xl={3}><Details bookInfo={bookStore.bookInfo}/></Grid>
                <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
            </Grid>
        </Grid>
    </Grid>);
});
