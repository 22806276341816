import {provider, useInstance} from "react-ioc";
import {DetailsStore} from "app/modules/main/components/admin/contacts/details/DetailsStore";
import {observer} from "mobx-react";
import {Navigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {LoadingButton} from "@mui/lab";
import {Grid, Stack} from "@mui/material";
import {Section, SectionName} from "app/modules/common/layout/components/details";
import {SectionTextField} from "app/modules/common/details/text-field";
import {Loading} from "app/modules/common/layout/components/loading";

export default provider(DetailsStore)(observer(() => {
    let store = useInstance(DetailsStore);
    let adminStore = useInstance(AdminStore);

    const urlParams = useParams();
    const { id } = urlParams;

    useEffect(
        () => {
            store.init(!!id && parseInt(id) || null);
            adminStore.selectMenu(AdminMenu.Contacts);
        },
        [id],
    );

    if(store.isSavedSuccessfully) {
        return <Navigate to={"/admin/contacts/" + store.contactId} replace={true} />;
    }

    if(store.isInitLoading) {
        return <Loading />;
    }

    return <Container>
        <PageTitleDetails Title={store.name || "New Contact"} Size={8}/>
        <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
            <LoadingButton loading={store.isLoading} disabled={store.formState.hasError} variant="contained" onClick={store.save}>
                Save
            </LoadingButton>
        </Grid>
        <ContainerDetails>
            <SectionName title="INFO" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Name"}
                            field={store.formState.$.name}
                            width={"33%"}
                        />
                        <SectionTextField
                            label={"Email"}
                            field={store.formState.$.email}
                            width={"33%"}
                        />
                        <SectionTextField
                            label={"Phone"}
                            field={store.formState.$.phone}
                            width={"34%"}
                        />
                    </Stack>

                    <SectionTextField
                        label={"Notes"}
                        field={store.formState.$.notes}
                        width={"100%"}
                    />
                </Stack>
            </Section>
        </ContainerDetails>
    </Container>;
}));
