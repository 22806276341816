import {Component} from "react";
import {inject} from "react-ioc";
import {MainStore} from "app/modules/main/stores/MainStore";
import React from "react";
import Search from "app/modules/main/components/main/search/search-header";
import Results from "app/modules/main/components/main/search/results";
import {observer} from "mobx-react";
import {SearchStore} from "app/modules/main/components/main/search/SearchStore";
import {Loading} from "app/modules/common/layout/components/loading";

@observer
export class Index extends Component<any, any> {
    @inject mainStore: MainStore;
    @inject searchStore: SearchStore;

    render() {
        return <React.Fragment>
            <Search/>
            {this.searchStore.isLoading ? <Loading/> : <Results/>}
        </React.Fragment>;
    }

    async componentDidMount() {
        this.searchStore.loadData();
    }
}
