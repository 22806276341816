import {BaseLoadingStore} from "app/modules/main/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {
    GetReservationCompletionInfoModelStatus, ReservationAccountModel
} from "app/modules/main/api/main-clients.api";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";
import moment from "moment";
import {AnalyticsService} from "app/modules/main/stores/AnalyticsService";

export class PaymentCompleteStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;
    @inject analyticsService: AnalyticsService;

    reservationInfo: ReservationAccountModel = null;

    constructor() {
        super();

        makeObservable(this, {
            reservationInfo: observable,

            init: action
        });
    }

    init = async (code: string) => {
        try{
            this.isInitLoading = true;

            this.reservationInfo = await this.apiClients.propertyMainClient.getReservationAccountDetails(code);

            let duration = moment.duration(moment().diff(this.reservationInfo.paymentCreatedAt));
            let seconds = duration.asSeconds();

            if (this.reservationInfo.paymentStatus == GetReservationCompletionInfoModelStatus.Pending && seconds < 180) {
                setTimeout(() => this.init(code), 5 * 1000)
                return;
            } else {
                this.analyticsService.paymentMadeProperty(this.reservationInfo.publicDetails.id, this.reservationInfo.reservationCode);
            }

            runInAction(() => {
                this.isInitLoading = false;
            });
        }
        catch (ex) {
            ApiErrorHandler(ex);
        }
    }
}

