import React, {useEffect} from "react";
import {observer} from "mobx-react";

import Details from "app/modules/main/components/common/details/index";
import {Divider, Grid, Link, Stack, Typography} from "@mui/material";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {DetailsStore} from "app/modules/main/components/account/book/details/DetailsStore";
import {provider, useInstance} from "react-ioc";
import {useParams} from "react-router-dom";
import {Loading} from "app/modules/common/layout/components/loading";
import StayDetails from "app/modules/main/components/common/details/stay-details";
import ReservationCode from "app/modules/main/components/common/details/reservation-code";
import PaymentDetails from "app/modules/main/components/common/details/payment-details";
import {
    PropertyReservationStatus
} from "app/modules/main/api/main-clients.api";
import {blue, deepOrange, red} from "@mui/material/colors";

export default provider(DetailsStore)(observer(() => {
    const store = useInstance(DetailsStore);
    const urlParams = useParams();
    const { id } = urlParams;

    useEffect(
        () => {
            store.init(id);
        }, []);

    if (store.isInitLoading) {
        return <Loading />;
    }

    let stayDetails = <Grid container spacing={2}>
        <Grid item xs={12}><Divider sx={{ mb: 1, p: 1 }}/></Grid>
        <StayDetails details={store.details}/>

        {store.details.propertyContactDetails && <React.Fragment>
            <Grid item xs={12}><Divider sx={{ mb: 1, p: 1 }}/></Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Stack sx={{ textAlign: { xs: "left", sm: "left", md: "center" } }}>
                    <Typography variant={"body2"}><b>Contact:</b> {store.details.propertyContactDetails.phoneNumber} {store.details.propertyContactDetails.fullName?.length > 0 && <b>({store.details.propertyContactDetails.fullName})</b>}</Typography>
                    {store.details.propertyContactDetails.address?.length > 0 && <Typography variant={"body2"}><b>Address:</b> <Link target={"_blank"} href={"https://maps.google.com/?q=" + store.details.propertyContactDetails.address}>{store.details.propertyContactDetails.address}</Link> </Typography>}
                </Stack>
            </Grid>
        </React.Fragment>}

        <Grid item xs={12}>
            <BoxCenter>
                <Divider sx={{ mb: 2, p: 1, width: 150 }}/>
            </BoxCenter>
            <ReservationCode reservationCode={store.details.reservationCode}/>

            <BoxCenter>
                {store.details.status == PropertyReservationStatus.Confirmed && <Typography variant={"body2"} color={blue[700]} sx={{ fontSize: "16px", fontStyle: "italic" }}><b>Confirmed</b></Typography>}
                {store.details.status == PropertyReservationStatus.Requested && <Typography variant={"body2"} color={deepOrange[400]} sx={{ fontSize: "16px", fontStyle: "italic" }}><b>Requested</b></Typography>}
                {store.details.status == PropertyReservationStatus.Cancelled && <Typography variant={"body2"} color={red[700]} sx={{ fontSize: "16px", fontStyle: "italic" }}><b>Canceled</b></Typography>}
            </BoxCenter>

            <PaymentDetails details={store.details}/>
        </Grid>
    </Grid>

    return (<Details info={store.details.publicDetails} additionalBlock={stayDetails} backButton={<br/>} />);
}));

