import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable} from "mobx";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {ReservationAccountShortInfo} from "app/modules/main/api/main-clients.api";

export class ListStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    years: number[] = null;
    reservations: ReservationAccountShortInfo[] = null;

    selectedYear: number = 0;

    constructor() {
        super();

        makeObservable(this, {
            years: observable,
            reservations: observable,
            selectedYear: observable,

            init: action,
            selectYear: action
        });
    }

    selectYear = async (year) => {
        this.selectedYear = year

        await this.wrapLoadingApiCall(async () => {
            if(this.selectedYear > 0) {
                this.reservations = await this.apiClients.propertyMainClient.getReservationByYear(this.selectedYear);
            } else {
                this.reservations = await this.apiClients.propertyMainClient.getCurrentReservations();
            }
        });
    }

    init = async () => {
        await this.wrapInitLoadingApiCall(async () => {
           [this.years, this.reservations] = [
               await this.apiClients.propertyMainClient.getReservationYears(),
               await this.apiClients.propertyMainClient.getCurrentReservations(),
           ];
        });
    }
}
