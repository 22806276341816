import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {
    DataListGetParamsOfReservationListFilters,
    ReservationListFilters,
    ReservationListModel
} from "app/modules/main/api/admin-clients.api";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";

export class ListStore extends BaseListStore<ReservationListModel>{
    @inject apiClients: ApiClients;
    @inject adminStore: AdminStore;

    selectedBook = null;

    constructor() {
        super();

        makeObservable(this, {
            selectedBook: observable,

            init: action,
            selectBook: action
        });
    }

    selectBook = (bookId) => {
        this.selectedBook = bookId;
    }

    applySpecificFilters() {
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new ReservationListFilters({ });
            const [result] = await Promise.all([
                this.apiClients.calendarClient.getList(new DataListGetParamsOfReservationListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    async init() {
        this.adminStore.selectMenu(AdminMenu.Requests);

        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
