import {observer} from "mobx-react";
import {Card, Divider, Grid, Stack, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import React from "react";
import {DateHelper} from "app/modules/main/components/common/DateHelper";
import moment from "moment";
import Rates from "app/modules/main/components/common/details/rates";
import {BookingInfo} from "app/modules/main/api/main-clients.api";

export default observer((props: { bookInfo: BookingInfo }) => {
    return (<Card sx={{ m: 1, p: 2 }}>
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <img src={`/mainController/Search/GetFile?path=${props.bookInfo.details.thumbnailImageUrl}`} style={{ width: "100%" }}/>
            </Grid>
            <Grid item xs={8}>
                <Stack>
                    <Typography variant={"body1"} sx={{ pl: 1, color: blue[800], cursor: "pointer", ":hover": { textDecoration: "underline" } }}><b>Listing #{props.bookInfo.details.id}</b></Typography>
                    <Typography variant={"body2"} sx={{ pl: 1 }}>{props.bookInfo.details.city}, {props.bookInfo.details.stateDisplay}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={6}>
                <Stack>
                    <Typography variant={"body2"}><b>CHECK IN</b></Typography>
                    <Typography variant={"body2"}>{moment(DateHelper.convertDateObj(props.bookInfo.startBookingModel.from)).format("dddd")}</Typography>
                    <Typography variant={"body2"}>{moment(DateHelper.convertDateObj(props.bookInfo.startBookingModel.from)).format("MMM Do YYYY")}</Typography>
                    <Typography variant={"body2"}>4:00 PM</Typography>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack sx={{ textAlign: "right", pr: 1 }}>
                    <Typography variant={"body2"}><b>CHECK OUT</b></Typography>
                    <Typography variant={"body2"}>{moment(DateHelper.convertDateObj(props.bookInfo.startBookingModel.to)).format("dddd")}</Typography>
                    <Typography variant={"body2"}>{moment(DateHelper.convertDateObj(props.bookInfo.startBookingModel.to)).format("MMM Do YYYY")}</Typography>
                    <Typography variant={"body2"}>10:00 AM</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                <Stack>
                    <Typography variant={"body2"}><b>GUESTS</b></Typography>
                    <Typography variant={"body2"}>{props.bookInfo.startBookingModel.adults} Adult{props.bookInfo.startBookingModel.adults > 1 ? "s" : ""}{props.bookInfo.startBookingModel.children > 0 && <span>, {props.bookInfo.startBookingModel.children} Children</span>}{props.bookInfo.startBookingModel.pets > 1 && <span>, {props.bookInfo.startBookingModel.pets} Pet{props.bookInfo.startBookingModel.pets > 1 ? "s" : ""}</span>}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Rates rates={props.bookInfo.rates}/>
        </Grid>
    </Card>);
})
