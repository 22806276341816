import {observer} from "mobx-react";
import React from "react";
import {Typography} from "@mui/material";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {blue} from "@mui/material/colors";

export default observer((props : { reservationCode: string }) => {
    return <React.Fragment>
        <BoxCenter>
            <Typography variant={"body2"} sx={{ fontSize: "12px", fontStyle: "italic" }}>confirmation number:</Typography>
        </BoxCenter>
        <BoxCenter>
            <Typography variant={"body2"} color={blue[700]} sx={{ fontSize: "20px", pb: 2 }}><b>{props.reservationCode}</b></Typography>
        </BoxCenter>
    </React.Fragment>
})
