import {observer} from "mobx-react";
import {Component} from "react";
import {inject, provider} from "react-ioc";
import {Alert, Box, Grid, Typography} from "@mui/material";
import React from "react";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {centerBoxStyle} from "app/modules/auth";
import {Loading} from "app/modules/common/layout/components/loading";
import {ConfirmEmailStore} from "app/modules/auth/components/confirm-email/ConfirmEmailStore";
import Icon from "app/modules/auth/components/common/icon";

@provider(ConfirmEmailStore)
@observer
export default class Index extends Component<any, any> {
    @inject confirmEmailStore: ConfirmEmailStore;

    render() {
        if(this.confirmEmailStore.isLoading) {
            return <Loading/>;
        }

        if(this.confirmEmailStore.isWrongParams || this.confirmEmailStore.errorMessage?.length > 0) {
            return <Box sx={centerBoxStyle}>
                <Icon/>
                <Typography component="h1" variant="h5">
                   Confirm Email
                </Typography>
                <Alert sx={{ mt: 2, mb: 2 }} severity="error">{this.confirmEmailStore.errorMessage || "The link is broken or expired, please try to reset your password."}</Alert>
                <Box component="form">
                    <Grid container>
                        <Grid item xs>
                            <BtnLink Link="/login" Text="Login" variant="text"/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>;
        }

        if(this.confirmEmailStore.isSuccess) {
            return <Box sx={centerBoxStyle}>
                <Icon/>
                <Typography component="h1" variant="h5">
                    Confirm Email
                </Typography>
                <Alert sx={{ mt: 2, mb: 2 }} severity="success">Email is confirmed, please login.</Alert>
                <Box component="form">
                    <Grid container>
                        <Grid item xs>
                            <BtnLink Link="/login" Text="Login" variant="text"/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>;
        }
    }

    componentDidMount() {
        this.confirmEmailStore.init();
    }
}
