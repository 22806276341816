import {observer} from "mobx-react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, Grid, Stack,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {IntegrationReservationLog, IntegrationReservationTimeLine} from "app/modules/main/api/admin-clients.api";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {DateHelper} from "app/modules/main/components/common/DateHelper";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {Loading} from "app/modules/common/layout/components/loading";

export default observer((props: { close: () => void, book: IntegrationReservationTimeLine }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [logs, setLogs] = useState([]);

    let apiClient = useInstance(ApiClients);

    useEffect(() => {
        const fetchData = async () => {
            setLogs(await apiClient.integrationClient.getIntegrationLogs(props.book.id));
            setIsLoading(false);
        };

        fetchData();
    },[]);

    const reprocess = () => apiClient.integrationClient.retryProcessHosttoolsItem(props.book.id);

    return <Dialog
        open={true}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <DialogTitle id="alert-dialog-title">
            External Book Details
        </DialogTitle>
        <DialogContent>
            {isLoading ? <Loading/> :
            <Stack spacing={1} direction={"column"}>
                <Divider/>
                <Box>Guest: <b>{props.book.guestName}</b></Box>
                <Box>From: <b>{DateHelper.formatMomentDateFull(props.book.from)}</b></Box>
                <Box>To: <b>{DateHelper.formatMomentDateFull(props.book.to)}</b></Box>
                <Box>Code: <b>{props.book.confirmationCode}</b></Box>
                <Box>Source: <b>{props.book.source}</b></Box>
                <Box>Listing: <b>{props.book.listing}</b></Box>
                <Box>Status: <b>{props.book.status}</b></Box>
                <Box>Error: <b>{props.book.lastError || "N/A"}</b></Box>
                <Box>ReservationId: <b>{props.book.reservationId || "N/A"}</b></Box>
                <Divider/>
                {logs.map((x: IntegrationReservationLog) => <Grid container key={x.id}>
                    <Grid item xs={4}>{DateHelper.formatMomentDateFull(x.createdAt)}</Grid>
                    <Grid item xs={4}>{x.operation}</Grid>
                    <Grid item xs={4}><CopyToClipboard text={x.data}><Button variant={"text"}>Data</Button></CopyToClipboard></Grid>
                </Grid>)}
            </Stack>}
        </DialogContent>
        {!isLoading && <DialogActions>
            <Button onClick={props.close}>Close</Button>
            <Button onClick={reprocess} color={"primary"}>
                Reprocess
            </Button>
        </DialogActions>}
    </Dialog>;
});
