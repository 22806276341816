import {provider, useInstance} from "react-ioc";
import {DetailsStore} from "app/modules/main/components/admin/promo-codes/details/DetailsStore";
import {observer} from "mobx-react";
import {Navigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {LoadingButton} from "@mui/lab";
import {Grid, MenuItem, Stack, Typography} from "@mui/material";
import {Section, SectionName} from "app/modules/common/layout/components/details";
import {
    SectionDatePickerField,
    SectionNumberTextField, SectionSelectField,
    SectionTextField
} from "app/modules/common/details/text-field";
import {Loading} from "app/modules/common/layout/components/loading";
import {red} from "@mui/material/colors";

export default provider(DetailsStore)(observer(() => {
    let store = useInstance(DetailsStore);
    let adminStore = useInstance(AdminStore);

    const urlParams = useParams();
    const { id } = urlParams;

    useEffect(
        () => {
            store.init(!!id && parseInt(id) || null);
            adminStore.selectMenu(AdminMenu.PromoCodes);
        },
        [id],
    );

    if(store.isSavedSuccessfully) {
        return <Navigate to={"/admin/promo-codes/" + store.promoCodeId} replace={true} />;
    }

    if(store.isInitLoading) {
        return <Loading />;
    }

    return <Container>
        <PageTitleDetails Title={store.promoCode || "New Promo Code"} Size={store.promoCodeId > 0 ? 12 : 8}/>
        <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
            {!store.promoCodeId && <LoadingButton loading={store.isLoading} disabled={store.formState.hasError} variant="contained" onClick={store.save}>
                Save
            </LoadingButton>}
        </Grid>
        <ContainerDetails>
            <SectionName title="INFO" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Promo Code (Optional)"}
                            field={store.formState.$.promoCode}
                            width={"50%"}
                            isReadOnlyMode={store.promoCodeId > 0}
                        />
                        <SectionNumberTextField
                            label={"Amount"}
                            field={store.formState.$.amount}
                            width={"50%"}
                            prefix="$"
                            isReadOnlyMode={store.promoCodeId > 0}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionNumberTextField
                            label={"Min Nights"}
                            field={store.formState.$.minNights}
                            width={"50%"}
                            isReadOnlyMode={store.promoCodeId > 0}
                        />
                        <SectionDatePickerField
                            label={"ExpiredAt"}
                            field={store.formState.$.expiredAt}
                            width={"50%"}
                            isReadOnlyMode={store.promoCodeId > 0}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionSelectField
                            field={store.formState.$.propertyId}
                            width={"100%"}
                            label={"Property"}
                            isReadOnlyMode={store.promoCodeId > 0}
                            items={() => store.properties?.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        {store.promoCodeId > 0 && store.active && !store.propertyReservationId && <LoadingButton loading={store.isLoading} color={"error"} variant={"contained"} onClick={() => store.deactivate()}>
                            Deactivate
                        </LoadingButton>}
                        {!store.active && <Typography sx={{ color: red[800] }}>Deactivated</Typography>}
                    </Stack>
                </Stack>
            </Section>
        </ContainerDetails>
    </Container>;
}));
