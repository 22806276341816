import {Component} from "react";
import React from "react";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {Box, Grid} from "@mui/material";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Loading} from "app/modules/common/layout/components/loading";
import {inject, provider} from "react-ioc";
import {ListStore} from "app/modules/main/components/admin/properties/list/ListStore";
import {observer} from "mobx-react";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import {PropertyListModel, PropertyStatus} from "app/modules/main/api/admin-clients.api";
import {StringHelper} from "app/modules/common/helpers/StringHelper";

export const propertyStatusColorMap: Map<PropertyStatus, string> = new Map<PropertyStatus, string>([
    [PropertyStatus.Unlisted, "#606060"],
    [PropertyStatus.Deactivated, "#ff1744"],
    [PropertyStatus.Listed, "#4caf50"],
]);

const columns = [
    {
        field: 'statusColor',
        headerName: '',
        maxWidth: 30,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyListModel>) => (
            <Box sx={{ width: "5px", height: "100%", backgroundColor: propertyStatusColorMap.get((params.row as PropertyListModel).status) }} />
        ),
    },
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 50,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyListModel>) => (
            <Link to={"/admin/properties/" + (params.row as PropertyListModel).id}>
                {(params.row as PropertyListModel).id}
            </Link>
        ),
    },
    {
        field: 'image',
        headerName: 'Image',
        maxWidth: 120,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyListModel>) => (
            <Link to={"/admin/properties/" + (params.row as PropertyListModel).id}>
                <img src={`/adminController/Property/GetFile?path=${(params.row as PropertyListModel).thumbnailUrl}`} height={50}/>
            </Link>
        ),
    },
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 2,
        renderCell: (params: GridRenderCellParams<PropertyListModel>) => (
            <Link to={"/admin/properties/" + (params.row as PropertyListModel).id}>
                {(params.row as PropertyListModel).name}
            </Link>
        ),
    },
    {
        field: 'address',
        headerName: 'Address',
        minWidth: 150,
        flex: 2,
        renderCell: (params: GridRenderCellParams<PropertyListModel>) => (
            (params.row as PropertyListModel).address
        )
    },
    {
        field: 'rate',
        headerName: 'Rate',
        maxWidth: 110,
        type: 'number',
        flex: 1,
        valueGetter: (params: GridRenderCellParams<PropertyListModel>) =>
            `${NumberHelper.formatUSDollar((params.row as PropertyListModel).rate)}`,
    },
    {
        field: 'owner',
        headerName: 'Owner',
        minWidth: 200,
        flex: 2,
        renderCell: (params: GridRenderCellParams<PropertyListModel>) => (
            StringHelper.formatNull((params.row as PropertyListModel).owner)
        )
    }
];

@provider(ListStore)
@observer
export class Index extends Component<any, any> {
    @inject listStore: ListStore;

    render() {
        if(this.listStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={"Properties"} TotalRows={this.listStore.totalRows}/>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={"/admin/properties/new"} Text="New Property"/>
            </Grid>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.listStore.items}
                    columns={columns}
                    paginationModel={{ page: this.listStore.skipRows/this.listStore.pageSize, pageSize: this.listStore.pageSize }}
                    pageSizeOptions={this.listStore.pageSizeOptions}
                    rowCount={this.listStore.totalRows}
                    loading={this.listStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    rowSelection={false}
                    checkboxSelection={false}
                    onPaginationModelChange={(model) => this.listStore.setPage(model.page, model.pageSize)}/>
            </DataGridBody>
        </DataGridContainer>;
    }

    async componentDidMount() {
        this.listStore.init();
    }
}
