import {Component} from "react";
import React from "react";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {Grid} from "@mui/material";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Loading} from "app/modules/common/layout/components/loading";
import {inject, provider} from "react-ioc";
import {ListStore} from "app/modules/main/components/admin/contacts/ListStore";
import {observer} from "mobx-react";
import {PropertyContactListModel} from "app/modules/main/api/admin-clients.api";
import {Link} from "react-router-dom";
import {BtnLink} from "app/modules/common/layout/components/btn-link";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 50,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyContactListModel>) => <Link to={"/admin/contacts/" + (params.row as PropertyContactListModel).id}>
            {(params.row as PropertyContactListModel).id}
        </Link>,
    },
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyContactListModel>) => <Link to={"/admin/contacts/" + (params.row as PropertyContactListModel).id}>
            {(params.row as PropertyContactListModel).name}
        </Link>,
    },
    {
        field: 'email',
        headerName: 'Email',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyContactListModel>) => (
            (params.row as PropertyContactListModel).email
        )
    },
    {
        field: 'phone',
        headerName: 'Phone',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyContactListModel>) => (
            (params.row as PropertyContactListModel).phone
        )
    },
    {
        field: 'note',
        headerName: 'Note',
        maxWidth: 300,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PropertyContactListModel>) => (
            (params.row as PropertyContactListModel).note
        )
    }
];

@provider(ListStore)
@observer
export class Index extends Component<any, any> {
    @inject listStore: ListStore;

    render() {
        if(this.listStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={"Contacts"} TotalRows={this.listStore.totalRows}/>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={"/admin/contacts/new"} Text="New Contact"/>
            </Grid>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.listStore.items}
                    columns={columns}
                    paginationModel={{ page: this.listStore.skipRows/this.listStore.pageSize, pageSize: this.listStore.pageSize }}
                    pageSizeOptions={this.listStore.pageSizeOptions}
                    rowCount={this.listStore.totalRows}
                    loading={this.listStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    rowSelection={false}
                    checkboxSelection={false}
                    onPaginationModelChange={(model) => this.listStore.setPage(model.page, model.pageSize)}/>
            </DataGridBody>
        </DataGridContainer>;
    }

    async componentDidMount() {
        this.listStore.init();
    }
}
