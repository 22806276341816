import {Autocomplete, Box, Button, Divider, Grid, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import React from "react";
import {observer} from "mobx-react";
import {blueGrey, grey, indigo} from "@mui/material/colors";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {MobileDateRangePicker} from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {useInstance} from "react-ioc";
import {SearchStore} from "app/modules/main/components/main/search/SearchStore";
import {SearchCitiesResult} from "app/modules/main/api/main-clients.api";
import {DebounceInput} from "app/modules/main/components/common/debounce-input";
import {LocationRenderOption} from "app/modules/main/components/main/search/location-render";
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import BackgroundImage from "app/modules/common/assets/background.jpg";
import useEmblaCarousel from "embla-carousel-react";
import { EmblaOptionsType } from 'embla-carousel'
import {ResultItemCard} from "app/modules/main/components/main/search/result-item";

const options : EmblaOptionsType = {
    align: "center",
    startIndex: 1
};

export default observer(() => {
    const [searchRedirect, setSearchRedirect] = React.useState(false);
    const store = useInstance(SearchStore);

    const [emblaRef] = useEmblaCarousel(options);

    if(searchRedirect) {
        return <NavigateHook path={"/search"}/>;
    }

    const filterOptions = (options: SearchCitiesResult[]): SearchCitiesResult[] => options;

    let location = <Autocomplete
        sx={{backgroundColor: blueGrey[50], borderRadius: "5px", minWidth: "250px"}}
        options={store.searchCitiesResult}
        value={store.searchValue}
        onChange={(event: unknown, value: SearchCitiesResult | null) => store.setSearchValue(value, true)}
        getOptionLabel={(item: SearchCitiesResult) => `${item.city}, ${item.state}`}
        renderInput={(params) => <DebounceInput
            placeholder="Type in here…"
            debounceTimeout={400}
            handleDebounce={(value: string) => {
                store.setSearchString(value);
                store.searchCities();
            }}
            {...params}
        />}
        renderOption={LocationRenderOption}
        loading={store.isLoading}
        filterOptions={filterOptions}
        blurOnSelect={true}
    />;

    let dateRange = <Box sx={{ ml: 2 }}>
        <MobileDateRangePicker
            disablePast
            value={store.dates as any}
            onChange={(newValue) => store.setDates(newValue)}
            slots={{ field: SingleInputDateRangeField }}
            slotProps={{ textField: { sx: { backgroundColor: blueGrey[50], borderRadius: "5px", width: "100%", minWidth: "250px !important" } } }}
        />
    </Box>;
    let guests = <TextField
        id="outlined-basic" sx={{ ml: 2, mr: 2, backgroundColor: blueGrey[50], borderRadius: "5px" }}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <PeopleAltIcon />
                </InputAdornment>
            ),
        }}
        value={store.searchParams.guestsAdults}
        onChange={(e) => {
            let value = parseFloat(e.target.value);
            if(isNaN(value) || value < 1) {
                value = 1;
            }

            store.setGuestAdults(value);
        }}
        type={"number"}
        placeholder={'1'}
        variant="outlined"
    />;

    return (<Grid container>
        <Grid item xs={12} sx={{ display: { xs: "block", md: "none" }, backgroundColor: indigo[50], pb: 2, pl: 2, pr: 2 }}>
            <Stack direction={"column"} spacing={2}>
                <Typography align={"center"} variant={"h5"} sx={{ pt: 2, pb: 2 }}>Find a vacation place to relax</Typography>
                {location}
                {dateRange}
                {guests}
                <Button variant="contained" sx={{ width: "100%", height: "56px", backgroundColor: blueGrey[800] }} onClick={() => setSearchRedirect(true)}>Search</Button>
            </Stack>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "block" }, pt: 10, pb: 10, backgroundImage: 'url("' + BackgroundImage + '")', backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
            <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <BoxCenter>
                        <Stack direction={"column"} sx={{ backgroundColor: blueGrey[200] + 'cc', p: 2, pt:4, pb: 4, borderRadius: "5px" }}>
                            <Typography align={"center"} variant={"h3"} sx={{ pt: 2, pb: 2, color: blueGrey[900] }}>Find a vacation place to relax</Typography>
                            <Stack direction={"row"}>
                                {location}
                                {dateRange}
                                {guests}
                                <Button variant="contained" sx={{ width: "auto", backgroundColor: blueGrey[800] }} onClick={() => setSearchRedirect(true)}>Search</Button>
                            </Stack>
                        </Stack>
                    </BoxCenter>
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "block" }, pt: 2, pb: 2 }}>
            <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <BoxCenter>
                        <Stack direction={"column"} spacing={2}>
                            <Typography align={"center"} variant={"h5"} sx={{ pt: 2, color: blueGrey[900] }}>Find spaces that suit you</Typography>
                            <Divider/>
                            <Box ref={emblaRef} sx={{ overflow: "hidden", margin: "auto", maxWidth: "80vw", backgroundColor: grey[50], pt: 3, pb: 3, borderRadius: 2 }}>
                                <Box sx={{ display: "flex" }}>
                                    {store.samples?.map(x => <Box sx={{ flex: "0 0 385px", minWidth: 0, pr: 1, pl: 1 }} key={x.id} ><ResultItemCard item={x} /></Box>)}
                                </Box>
                            </Box>
                        </Stack>
                    </BoxCenter>
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        </Grid>
    </Grid>);
})
