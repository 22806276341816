import {
    Autocomplete,
    Box,
    Button, Checkbox, Divider,
    Fade, FormControlLabel,
    Grid, IconButton,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {observer} from "mobx-react";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PetsIcon from '@mui/icons-material/Pets';
import {MobileDateRangePicker} from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {grey} from "@mui/material/colors";
import {SearchCitiesResult} from "app/modules/main/api/main-clients.api";
import {DebounceInput} from "app/modules/main/components/common/debounce-input";
import {LocationRenderOption} from "app/modules/main/components/main/search/location-render";
import {useInstance} from "react-ioc";
import {SearchStore} from "app/modules/main/components/main/search/SearchStore";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {runInAction} from "mobx";
import {NumericFormatCustom} from "app/modules/common/details/text-field";
import {MainStore} from "app/modules/main/stores/MainStore";
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

function GuestField(props: { label: string, value: number, disableMinus: boolean, increase: () => void, decrease: () => void }) {
    return <Stack direction={"row"} sx={{ p: 1 }}>
        <BoxCenter>
            <Typography sx={{ width: "80px", fontSize: "14px" }}>{props.label}</Typography>
        </BoxCenter>
        <IconButton disabled={props.disableMinus} onClick={props.decrease}>
            <RemoveIcon />
        </IconButton>
        <BoxCenter>
            <Typography sx={{ fontWeight: props.value > 0 ? "bold" : "normal" }}>{props.value}</Typography>
        </BoxCenter>
        <IconButton onClick={props.increase}>
            <AddIcon />
        </IconButton>
    </Stack>;
}

export default observer(() => {
    const [anchorElGuestPopup, setAnchorElGuestPopup] = React.useState(null);
    const [openGuestPopup, setOpenGuestPopup] = React.useState(false);

    const [anchorElFilterPopup, setAnchorElFilterPopup] = React.useState(null);
    const [openFilterPopup, setOpenFilterPopup] = React.useState(false);

    const store = useInstance(SearchStore);
    const mainStore = useInstance(MainStore);

    const filterOptions = (options: SearchCitiesResult[]): SearchCitiesResult[] => options;

    let location = <Autocomplete
        className="SearchHeaderInputs"
        options={store.searchCitiesResult}
        value={store.searchValue}
        sx={{ minWidth: "250px" }}
        onChange={(event: unknown, value: SearchCitiesResult | null) => store.setSearchValue(value)}
        getOptionLabel={(item: SearchCitiesResult) => `${item.city}, ${item.state}`}
        renderInput={(params) => <DebounceInput
            placeholder="Type in here…"
            debounceTimeout={400}
            handleDebounce={(value: string) => {
                store.setSearchString(value);
                store.searchCities();
            }}
            {...params}
        />}
        renderOption={LocationRenderOption}
        loading={store.isLoading}
        filterOptions={filterOptions}
        blurOnSelect={true}
    />;

    let dateRange = <Box sx={{ width: { sm: "100%", md: "auto" } }}>
        <MobileDateRangePicker
            className="SearchHeaderInputs"
            disablePast
            value={store.dates as any}
            onAccept={(newValue) => {
                store.setDates(newValue);
            }}
            slots={{ field: SingleInputDateRangeField }}
            slotProps={{
                textField: { sx: { borderRadius: "0px", width: "100%", minWidth: "250px !important" } }
            }}
        />
    </Box>;

    let guests = <BoxCenter sx={{ borderColor: grey[400], borderWidth: "1px", borderStyle: "solid", cursor: "pointer", minHeight: "45px", width: { xs: "70%", sm: "70%", md: "auto" } }}
                            onClick={(e) => {
                                store.copyGuestEdit();
                                setAnchorElGuestPopup(e.currentTarget);
                                setOpenFilterPopup(false);
                                setOpenGuestPopup((prev) => !prev);
                            }}>
            <Stack direction={"row"} sx={{ color: grey[700], pl: "10px", pr: "10px" }} spacing={2}>
                <Stack direction={"row"} spacing={1}>
                    <PeopleAltIcon />
                    <BoxCenter>{store.searchParams.guestsAdults + store.searchParams.guestsChildren} guest{(store.searchParams.guestsAdults + store.searchParams.guestsChildren) > 1 ? "s" : ""}</BoxCenter>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    <PetsIcon />
                    <BoxCenter>{store.searchParams.guestsPets} pet{(store.searchParams.guestsPets) == 1 ? "" : "s"}</BoxCenter>
                </Stack>
            </Stack>
        </BoxCenter>;

    let onFilterClick = (e) => {
        store.copyFilterEdit();
        setAnchorElFilterPopup(e.currentTarget);
        setOpenGuestPopup(false);
        setOpenFilterPopup((prev) => !prev);
    };

    let filter = <Button variant="outlined"
                         startIcon={<FilterAltIcon />}
                         sx={{ width: "30%", borderRadius: 0, color: grey[600], borderColor: grey[500] }}
                         onClick={onFilterClick}>
        Filters
    </Button>;

    let filterFullView = <Button variant="outlined" startIcon={<FilterAltIcon />} sx={{ borderRadius: 0, color: grey[600], borderColor: grey[500], width: "200px" }} onClick={onFilterClick}>
        Filters
    </Button>;

    let PopupBodyGuests = observer(({ ...props }) => <ClickAwayListener onClickAway={(e) => { setOpenGuestPopup(false); e.preventDefault(); }}>
        <Fade {...props} timeout={350}>
            <Paper>
                <Stack direction={"column"}>
                    <GuestField
                        label={"Adults"}
                        value={store.guestsEdit.adults}
                        disableMinus={store.guestsEdit.adults <= 1}
                        increase={() => runInAction(() => ++store.guestsEdit.adults)}
                        decrease={() => runInAction(() => --store.guestsEdit.adults)}/>
                    <GuestField
                        label={"Children"}
                        value={store.guestsEdit.children}
                        disableMinus={store.guestsEdit.children <= 0}
                        increase={() => runInAction(() => ++store.guestsEdit.children)}
                        decrease={() => runInAction(() => --store.guestsEdit.children)}/>
                    <GuestField label={"Pets"}
                                value={store.guestsEdit.pets}
                                disableMinus={store.guestsEdit.pets <= 0}
                                increase={() => runInAction(() => ++store.guestsEdit.pets)}
                                decrease={() => runInAction(() => --store.guestsEdit.pets)}/>
                </Stack>
                <Divider/>
                <Stack direction={"row"}>
                    <Button variant="text" sx={{ width: "50%", color: grey[500] }} onClick={() => store.resetGuestEdit()}>Clear</Button>
                    <Button variant="text" sx={{ width: "50%" }} onClick={() => {
                        setOpenGuestPopup(false);
                        store.guestsUpdate();
                    }}>Update</Button>
                </Stack>
            </Paper>
        </Fade>
    </ClickAwayListener>);

    let PopupBodyFilter = observer(({ ...props }) => <ClickAwayListener onClickAway={(e) => { setOpenFilterPopup(false); e.preventDefault(); }}>
        <Fade {...props} timeout={350}>
            <Paper sx={{ p: 1, width: "320px", maxHeight: { xs: "calc(100vh - 300px)", sm: "calc(100vh - 300px)", md: "calc(100vh - 200px)" }, overflowY: "auto" }}>
                <Stack direction={"column"}>
                    <Typography sx={{ fontWeight: "bold", pl: 1 }}>Rooms</Typography>
                    <Stack direction={"row"} sx={{ p: 1 }}>
                        <BoxCenter>
                            <Typography sx={{ width: "130px", fontSize: "14px" }}>Bedrooms</Typography>
                        </BoxCenter>
                        <TextField
                            value={(store.filterEdit.minBedrooms || '')}
                            InputProps={{ inputComponent: NumericFormatCustom as any, sx: { width: "70px", height: "35px", padding: "2px" } }}
                            inputProps={{ prefix: "", style: { textAlign: 'center' } }}
                            onChange={(e) => store.updateMinBedrooms(Math.abs(parseFloat(e.target.value)))}
                            label={""}
                            placeholder={"min"}
                            autoComplete={"off"}
                            variant="outlined"
                        />
                        <BoxCenter>
                            <Typography sx={{ fontWeight: props.value > 0 ? "bold" : "normal", pl: 1, pr: 1 }}>-</Typography>
                        </BoxCenter>
                        <TextField
                            value={(store.filterEdit.maxBedrooms || '')}
                            InputProps={{ inputComponent: NumericFormatCustom as any, sx: { width: "70px", height: "35px", padding: "2px" } }}
                            inputProps={{ prefix: "", style: { textAlign: 'center' } }}
                            onChange={(e) => store.updateMaxBedrooms(Math.abs(parseFloat(e.target.value)))}
                            label={""}
                            placeholder={"max"}
                            autoComplete={"off"}
                            variant="outlined"
                        />
                    </Stack>
                    <Stack direction={"row"} sx={{ p: 1 }}>
                        <BoxCenter>
                            <Typography sx={{ width: "130px", fontSize: "14px" }}>Bathrooms</Typography>
                        </BoxCenter>
                        <TextField
                            value={(store.filterEdit.minBathrooms || '')}
                            InputProps={{ inputComponent: NumericFormatCustom as any, sx: { width: "70px", height: "35px", padding: "2px" } }}
                            inputProps={{ prefix: "", style: { textAlign: 'center' } }}
                            onChange={(e) => store.updateMinBathrooms(Math.abs(parseFloat(e.target.value)))}
                            label={""}
                            placeholder={"min"}
                            autoComplete={"off"}
                            variant="outlined"
                        />
                        <BoxCenter>
                            <Typography sx={{ fontWeight: props.value > 0 ? "bold" : "normal", pl: 1, pr: 1 }}>-</Typography>
                        </BoxCenter>
                        <TextField
                            value={(store.filterEdit.maxBathrooms || '')}
                            InputProps={{ inputComponent: NumericFormatCustom as any, sx: { width: "70px", height: "35px", padding: "2px" } }}
                            inputProps={{ prefix: "", style: { textAlign: 'center' } }}
                            onChange={(e) => store.updateMaxBathrooms(Math.abs(parseFloat(e.target.value)))}
                            label={""}
                            placeholder={"max"}
                            autoComplete={"off"}
                            variant="outlined"
                        />
                    </Stack>
                    <Typography sx={{ fontWeight: "bold", pl: 1, pt: 2 }}>Nightly rate</Typography>
                    <Stack direction={"row"} sx={{ p: 1 }}>
                        <TextField
                            value={(store.filterEdit.minRate || '')}
                            InputProps={{ inputComponent: NumericFormatCustom as any, sx: { width: "120px", height: "35px", padding: "2px" } }}
                            inputProps={{ prefix: "$", style: { textAlign: 'center' } }}
                            onChange={(e) => store.updateMinRate(Math.abs(parseFloat(e.target.value)))}
                            label={""}
                            placeholder={"min"}
                            autoComplete={"off"}
                            variant="outlined"
                        />
                        <BoxCenter>
                            <Typography sx={{ fontWeight: props.value > 0 ? "bold" : "normal", pl: 1, pr: 1 }}>-</Typography>
                        </BoxCenter>
                        <TextField
                            value={(store.filterEdit.maxRate || '')}
                            InputProps={{ inputComponent: NumericFormatCustom as any, sx: { width: "120px", height: "35px", padding: "2px" } }}
                            inputProps={{ prefix: "$", style: { textAlign: 'center' } }}
                            onChange={(e) => store.updateMaxRate(Math.abs(parseFloat(e.target.value)))}
                            label={""}
                            placeholder={"max"}
                            autoComplete={"off"}
                            variant="outlined"
                        />
                    </Stack>
                    <Typography sx={{ fontWeight: "bold", pl: 1, pt: 2 }}>Type of property</Typography>
                    <Grid container sx={{ p: 1 }}>
                        {mainStore.clientData.propertyTypes.map(x => <Grid item key={x.id} xs={6}>
                            <FormControlLabel
                                style={{ fontSize: "14px"}}
                                control={<Checkbox sx={{ padding: "4px" }} size="small" checked={store.filterEdit.propertyTypes.indexOf(x.id) >= 0} onClick={() => store.checkPropertyType(x.id)} />}
                                label={<Typography sx={{ fontSize: "14px" }}>{x.name}</Typography>}
                            />
                        </Grid>)}
                    </Grid>
                    <Typography sx={{ fontWeight: "bold", pl: 1, pt: 2 }}>Amenities</Typography>
                    <Grid container sx={{ p: 1 }}>
                        {mainStore.clientData.amenities.map(x => <Grid item key={x.id} xs={6}>
                            <FormControlLabel
                                style={{ fontSize: "14px" }}
                                control={<Checkbox sx={{ padding: "4px" }} size="small" checked={store.filterEdit.propertyAmenities.indexOf(x.id) >= 0} onClick={() => store.checkAmenity(x.id)} />}
                                label={<Typography sx={{ fontSize: "14px" }}>{x.name}</Typography>} />
                        </Grid>)}
                    </Grid>
                </Stack>
                <Divider/>
                <Stack direction={"row"}>
                    <Button variant="text" sx={{ width: "50%", color: grey[500] }} onClick={() => runInAction(() => store.resetFilterEdit())}>Clear</Button>
                    <Button variant="text" sx={{ width: "50%" }} onClick={() => {
                        setOpenFilterPopup(false);
                        store.filterUpdate();
                    }}>Update</Button>
                </Stack>
            </Paper>
        </Fade>
    </ClickAwayListener>);

    return (<Grid container>
        <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
            <Stack direction={"column"}>
                {location}
                {dateRange}
                <Stack direction={"row"}>
                    {guests}
                    {filter}
                </Stack>
            </Stack>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "block" } }}>
            <Stack direction={"row"} sx={{ mb: 1, borderColor: grey[500], borderWidth: "1px", borderStyle: "solid", pb: 0}}>
                {location}
                {dateRange}
                {guests}
                {filterFullView}
            </Stack>
            {openGuestPopup && <Popper open={openGuestPopup} anchorEl={anchorElGuestPopup} placement={"bottom"} transition>
                {({ TransitionProps }) => <PopupBodyGuests {...TransitionProps}/>}
            </Popper>}
            {openFilterPopup && <Popper open={openFilterPopup} anchorEl={anchorElFilterPopup} placement={"bottom"} transition>
                {({ TransitionProps }) => <PopupBodyFilter {...TransitionProps}/>}
            </Popper>}
        </Grid>
    </Grid>);
})
