import {TextFieldProps} from "@mui/material/TextField/TextField";
import React from "react";
import {InputAdornment, TextField} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

type DebounceProps = {
    handleDebounce: (value: string) => void;
    debounceTimeout: number;
};

export function DebounceInput(props: TextFieldProps & DebounceProps) {
    const {handleDebounce, debounceTimeout, ...rest} = props;

    const timerRef = React.useRef<number>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = window.setTimeout(() => {
            handleDebounce(event.target.value);
        }, debounceTimeout);
    };

    return <TextField
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <LocationOnIcon/>
                </InputAdornment>
            )
        }}
        placeholder={'Try "Los Angeles"'}
        variant="outlined"
        onChange={handleChange}
        {...rest}
    />;
}
