import {observer} from "mobx-react";
import {Box, Button, Card, Checkbox, Divider, FormControlLabel, Grid, Link, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import Details from "app/modules/main/components/main/book/details";
import {useInstance} from "react-ioc";
import {BookStore} from "app/modules/main/components/main/book/BookStore";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import HomeIcon from '@mui/icons-material/Home';
import SmokeFreeIcon from '@mui/icons-material/SmokeFree';
import PetsIcon from '@mui/icons-material/Pets';
import CancelIcon from '@mui/icons-material/Cancel';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import WarningIcon from '@mui/icons-material/Warning';
import {StringHelper} from "app/modules/common/helpers/StringHelper";

export default observer(() => {
    const bookStore = useInstance(BookStore);
    const [confirmation, setConfirmation] = useState(false);

    let backButton = <Button variant={"text"} sx={{ fontSize: "12px" }} onClick={() => bookStore.backToContactStep()}>{"<"} Back to Contact Info Step</Button>;

    let bookButton = <Button variant={"contained"}
                               color={"warning"}
                               sx={{ fontSize: "12px", width: { xs: "100%", sm: "100%", md: "30%" }, marginLeft: { xs: "0", sm: "0", md: "70%" }, borderRadius: 0, height: "54px" }}
                               disabled={!confirmation}
                               onClick={() => bookStore.instantBooking ? bookStore.acceptTermsAndGoToPayment() : bookStore.acceptTermsAndComplete()}>{bookStore.instantBooking ? "GO TO PAYMENT" : "SUBMIT"}</Button>;

    let cancellationPolicy = <Card sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>
            <Stack direction={"row"} sx={{ pb: 2 }}>
                <BoxCenter><EventBusyIcon/></BoxCenter>
                <BoxCenter><Typography variant={"h6"} sx={{ pl: 1 }}>Review Policy</Typography></BoxCenter>
                <Box/>
            </Stack>
            <Divider sx={{ mb: 2 }}/>
            <Stack direction={"column"} sx={{ pb: 2 }} spacing={2}>
                {bookStore.bookInfo.details.policies?.map((x, index) => <Typography key={index} sx={{ fontSize: "14px" }}>{x}</Typography>)}
            </Stack>
            <Divider sx={{ mb: 2 }}/>
            {bookStore.instantBooking ? <Typography variant={"subtitle2"}>*** Our agent will contact you to confirm this reservation.</Typography> :
                <Typography variant={"subtitle2"}>*** Our agent will contact you to confirm this reservation, and provide the next steps. After a confirmation from agent this policy will apply.</Typography>}
            <Typography variant={"subtitle2"}>*** All refunds include 5% fee, that will be paid to a payment provider.</Typography>
        </Box>
    </Card>;

    let propertyRulesItem = (icon: React.ReactNode, text: React.ReactNode) => <Stack direction={"row"} spacing={1}>
        <Box>{icon}</Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>{text}</Box>
    </Stack>;

    let propertyRules = <Card sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>
            <Stack direction={"row"} sx={{ pb: 2 }}>
                <BoxCenter><HomeIcon/></BoxCenter>
                <BoxCenter><Typography variant={"h6"} sx={{ pl: 1 }}>Review Property Rules</Typography></BoxCenter>
                <Box/>
            </Stack>
            <Divider sx={{ mb: 2 }}/>
            <Stack direction={"column"} sx={{ pb: 2 }} spacing={1}>
                {!bookStore.bookInfo.details.smokingAllowed && propertyRulesItem(<SmokeFreeIcon/>, "Smoking not permitted anywhere on the property")}
                {bookStore.bookInfo.details.petMax > 0 && propertyRulesItem(<PetsIcon/>, "Pets: Only " + bookStore.bookInfo.details.petMax + " allowed")}
                {!bookStore.bookInfo.details.partiesAllowed && propertyRulesItem(<CancelIcon/>, "No events or parties allowed")}
                {bookStore.bookInfo.details.quietHoursEnabled && propertyRulesItem(<VolumeMuteIcon/>, <span>Quiet hours are from {StringHelper.format24Hours(bookStore.bookInfo.details.quietHoursStartTime)} to {StringHelper.format24Hours(bookStore.bookInfo.details.quietHoursEndTime)}</span>)}
                {propertyRulesItem(<WarningIcon/>, <span><b>Damage Policy:</b> Renter is liable for any damage to the property that occurs during occupancy</span>)}
            </Stack>
        </Box>
    </Card>;

    let confirmationCheckBox = <Card sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>
            <FormControlLabel
                control={
                    <Checkbox checked={confirmation} onChange={(e, checked) => { setConfirmation(checked) }} name="gilad" />
                }
                label={<Box>I agree to SolArt Group Inc's <Link onClick={(e) => { e.preventDefault(); window.open('/terms', '_blank'); }}>Rental Agreement</Link>, <Link onClick={(e) => { e.preventDefault(); window.open('/policies', '_blank'); }}>Privacy Policy</Link><Box sx={{display: "none"}}> and The <Link onClick={(e) => { e.preventDefault() }}>Unit Specific Terms</Link></Box>.</Box>}
            />
        </Box>
    </Card>;

    return (<Grid container>
        <Grid item xs={12} sx={{ display: { xs: "block", md: "none" }, p: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>{backButton}</Grid>
                <Grid item xs={12}>{cancellationPolicy}</Grid>
                <Grid item xs={12}>{propertyRules}</Grid>
                <Grid item xs={12}><Details bookInfo={bookStore.bookInfo}/></Grid>
                <Grid item xs={12}>{confirmationCheckBox}</Grid>
                <Grid item xs={12}>{bookButton}</Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "block" }, pl: 2, pr: 2 }}>
            <Grid container spacing={1}>
                <Grid item xs={0} sm={1} md={1} lg={2} xl={3}></Grid>
                <Grid item xs={12} sm={10} md={10} lg={8} xl={6} sx={{ pr: 2 }}>
                    <div>{backButton}</div>
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={2} xl={3}></Grid>

                <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
                <Grid item xs={6} sm={6} md={5} lg={4} xl={3} sx={{ pr: 2 }}>
                    <Stack spacing={2}>
                        {cancellationPolicy}
                        {propertyRules}
                        {confirmationCheckBox}
                    </Stack>
                    <Box sx={{ pt: 1 }}>
                        {bookButton}
                    </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={5} lg={4} xl={3}><Details bookInfo={bookStore.bookInfo}/></Grid>
                <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
            </Grid>
        </Grid>
    </Grid>);
});
