import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {action, makeObservable, observable} from "mobx";
import {PropertyTimeLineGroup} from "app/modules/main/api/admin-clients.api";
import moment from "moment";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";

export class TimelineStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    items: PropertyTimeLineGroup[] = null;

    groups: { id: number, title: string, rightTitle: string }[] = [];
    groupItem: { id: number, group: number, title: string, start_time: moment.Moment, end_time: moment.Moment, canMove: boolean, canResize: boolean, canChangeGroup: boolean, itemProps: { onDoubleClick: () => void } }[] = [];

    selectedBookId: number = null;

    constructor() {
        super();

        makeObservable(this, {
            items: observable,
            selectedBookId: observable,

            selectBook: action
        });
    }

    selectBook = (bookId) => this.selectedBookId = bookId;

    init = async () => {
        await this.wrapInitLoadingApiCall(async () => {
            const result = await this.apiClients.calendarClient.getTimeLineItems();

            this.groups = result.map(x => ({
                id: x.id,
                title: x.name,
                rightTitle: NumberHelper.formatUSDollar(x.total)
            }));

            this.groupItem.length = 0;

            for(let i = 0; i < result.length; i++) {
                let group = result[i];
                for(let j = 0; j < group.reservations.length; j++) {
                    let item = group.reservations[j];

                    this.groupItem.push({
                        id: item.id,
                        group: group.id,
                        title: `${item.name}`, //${NumberHelper.formatUSDollar(item.total)} -
                        canChangeGroup: false,
                        canMove: false,
                        canResize: false,
                        start_time: item.from,
                        end_time: item.to,
                        itemProps: {
                            onDoubleClick: () => this.selectBook(item.id)
                        }
                    });
                }
            }
        });
    }
}
