import {Box, Grid, Link, Stack} from "@mui/material";
import React from "react";
import {observer} from "mobx-react";
import {grey} from "@mui/material/colors";
import {BoxCenter} from "app/modules/common/layout/components/containers";

export default observer(() => {
    return (<Grid container sx={{ pt: 2, pb: 1, backgroundColor: grey[100], height: 100}}>
        <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
            <BoxCenter>
                <Stack direction={"row"} spacing={2}>
                    <Link onClick={(e) => { e.preventDefault(); window.open('/policies', '_blank'); }}>Privacy Policy</Link>
                    <Link onClick={(e) => { e.preventDefault(); window.open('/security', '_blank'); }}>Security</Link>
                    <Link onClick={(e) => { e.preventDefault(); window.open('/terms', '_blank'); }}>Terms</Link>
                    <Box>
                        {'Copyright © '}
                        <Link color="inherit" href="https://solartgroupinc.com">
                            SolArt Group Inc
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Box>
                </Stack>
            </BoxCenter>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
    </Grid>);
})
