import Events from "app/modules/common/static/EventNames";
import PubSub from 'pubsub-js';

export let ApiErrorHandler = (ex) => {
    if(ex && ex.isCodedExceptionApi == true) {
        let response = ex.response;

        if(response?.status == 401) { return; }

        if(typeof response === 'object' &&
            !Array.isArray(response) &&
            response !== null) {
            //PubSub.publish(Events.APIError, `Object: ${response.Obj} ${response.ErrorCode} ${response.Message != response.ErrorCode && response.Message}`);
            PubSub.publish(Events.APIError, `${response.Message != response.ErrorCode && response.Message}`);
        }
    } else {
        throw ex;
    }
}

export let ApiErrorTryGetMessage = (ex) => {
    if(ex && ex.isCodedExceptionApi == true) {
        let response = ex.response;

        if(typeof response === 'object' &&
            !Array.isArray(response) &&
            response !== null &&
            response.Message?.length > 0) {
            return response.Message;
        }
    }

    return null;
}
