import {
    Box,
    Button,
    Divider, FormControl,
    Grid,
    Link, MenuItem,
    Select, SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {useNavigate} from "react-router-dom";
import {provider, useInstance} from "react-ioc";
import {ListStore} from "app/modules/main/components/account/book/ListStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import {DateHelper} from "app/modules/main/components/common/DateHelper";
import {PropertyReservationStatus} from "app/modules/main/api/main-clients.api";
import {blue, deepOrange, red} from "@mui/material/colors";

export default provider(ListStore)(observer(() => {
    const navigate = useNavigate();
    const store = useInstance(ListStore);

    useEffect(
        () => {
            store.init();
        }, []);

    let Details = ({ image, city, state, date, price, status, reservationCode }) => <Stack direction={"row"} spacing={1}>
        <Box><img src={image} style={{ width: "100%", maxWidth: "150px", borderRadius: "5px" }}/></Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Link href={"/account/book/" + reservationCode} target={"_blank"}><Typography><b>{city}, {state}</b></Typography></Link>
            <Typography><i>{date}</i></Typography>
            <Typography variant={"body2"}><b>{price}</b></Typography>
            {status == PropertyReservationStatus.Confirmed && <Typography variant={"body2"} color={blue[700]} sx={{ fontSize: "12px", fontStyle: "italic" }}><b>Confirmed</b></Typography>}
            {status == PropertyReservationStatus.Requested && <Typography variant={"body2"} color={deepOrange[400]} sx={{ fontSize: "12px", fontStyle: "italic" }}><b>Requested</b></Typography>}
            {status == PropertyReservationStatus.Cancelled && <Typography variant={"body2"} color={red[700]} sx={{ fontSize: "12px", fontStyle: "italic" }}><b>Canceled</b></Typography>}
        </Box>
    </Stack>;

    let findTrip = <Button variant={"outlined"}
                           color={"warning"}
                           sx={{ fontSize: "12px", width: { xs: "100%", sm: "100%", md: 300 }, borderRadius: 0, height: "54px" }}
                           onClick={() => navigate("/")}>Plan A Trip</Button>;

    return (<React.Fragment>
        <Grid container spacing={1}>
            <Grid item xs={0} sm={1} md={1} lg={2} xl={3}></Grid>
            <Grid item xs={12} sm={10} md={10} lg={8} xl={6}>
                <BoxCenter sx={{ p: 2 }}>
                    <Stack spacing={1} sx={{ width: "100%" }}>
                        <Typography variant={"h4"} textAlign={"center"}>Your Trips</Typography>
                        <Divider/>
                        <BoxCenter sx={{ pb: 2 }}>{findTrip}</BoxCenter>
                        {store.years?.length > 0 && <BoxCenter>
                            <FormControl sx={{ minWidth: 200 }} size="small">
                                <Select
                                    value={store.selectedYear?.toString()}
                                    onChange={(event: SelectChangeEvent) => { store.selectYear(parseInt(event.target.value)); }}
                                >
                                    <MenuItem value={0}>Upcoming</MenuItem>
                                    {store.years.map(x => <MenuItem key={x} value={x}>{x} Year</MenuItem>)}
                                </Select>
                            </FormControl>
                        </BoxCenter>}
                    </Stack>
                </BoxCenter>
                {store.isLoading && <Loading/>}
                {!store.isLoading && <BoxCenter sx={{ p: 2, width: "100%" }}>
                    <Stack spacing={2}>
                        {store.reservations?.map(x => (
                            <Details
                                key={x.reservationCode}
                                image={`/mainController/Search/GetFile?path=${x.thumbnailImageUrl}`}
                                city={x.city}
                                state={x.state}
                                date={`${DateHelper.formatMomentDate(x.from)} - ${DateHelper.formatMomentDate(x.to)}`}
                                price={NumberHelper.formatUSDollar(x.total)}
                                status={x.status}
                                reservationCode={x.reservationCode}
                            />
                        ))}
                    </Stack>
                </BoxCenter>}
            </Grid>
            <Grid item xs={0} sm={1} md={1} lg={2} xl={3}></Grid>
        </Grid>
    </React.Fragment>);
}));
