import {provider, useInstance} from "react-ioc";
import {DetailsStore} from "app/modules/main/components/admin/policies/details/DetailsStore";
import {observer} from "mobx-react";
import {Navigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {LoadingButton} from "@mui/lab";
import {Grid, MenuItem, Stack, Typography} from "@mui/material";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {SectionNumberTextField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {
    AcceptedGuestType,
    CancelPolicyChargeType,
    PolicyPetsType,
    PolicyType
} from "app/modules/main/api/admin-clients.api";
import {Loading} from "app/modules/common/layout/components/loading";

export default provider(DetailsStore)(observer(() => {
    let store = useInstance(DetailsStore);
    let adminStore = useInstance(AdminStore);

    const urlParams = useParams();
    const { id } = urlParams;

    useEffect(
        () => {
            store.init(!!id && parseInt(id) || null);
            adminStore.selectMenu(AdminMenu.Policies);
        },
        [id],
    );

    if(store.isSavedSuccessfully) {
        return <Navigate to={"/admin/policies/" + store.policyId} replace={true} />;
    }

    if(store.isInitLoading) {
        return <Loading />;
    }

    return <Container>
        <PageTitleDetails Title={store.name || "New Policy"} Size={8}/>
        <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
            <LoadingButton loading={store.isLoading} disabled={store.formState.hasError} variant="contained" onClick={store.save}>
                Save
            </LoadingButton>
        </Grid>
        <ContainerDetails>
            <SectionName title="INFO" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Name"}
                            field={store.formState.$.name}
                            width={"50%"}
                        />
                        <SectionSelectField
                            field={store.formState.$.type}
                            width={"50%"}
                            label={"Type"}
                            items={() => [
                                <MenuItem value={PolicyType.Stay} key={PolicyType.Stay}>Stay</MenuItem>,
                                <MenuItem value={PolicyType.Cancel} key={PolicyType.Cancel}>Cancel</MenuItem>,
                                <MenuItem value={PolicyType.NoShow} key={PolicyType.NoShow}>No Show</MenuItem>,
                                <MenuItem value={PolicyType.Pets} key={PolicyType.Pets}>Pets</MenuItem>,
                                <MenuItem value={PolicyType.Other} key={PolicyType.Other}>Other</MenuItem>
                            ]}
                        />
                    </Stack>
                </Stack>
            </Section>
            <SectionSeparator/>
            <SectionName title="POLICY" description="" />
            <Section>
                {store.formState.$.type.value == PolicyType.Stay &&
                    <Stack spacing={2} direction={"column"}>
                        <Stack spacing={2} direction={"row"}>
                            <SectionNumberTextField
                                label={"Check-In Time From"}
                                field={store.formPolicyStayState.$.checkInTimeFrom}
                                width={"100%"}
                            />
                            <SectionNumberTextField
                                label={"Check-In Time To (Optional)"}
                                field={store.formPolicyStayState.$.checkInTimeTo}
                                width={"100%"}
                            />
                        </Stack>
                        <Stack spacing={2} direction={"row"}>
                            <SectionNumberTextField
                                label={"Check-Out Time From"}
                                field={store.formPolicyStayState.$.checkOutTimeFrom}
                                width={"100%"}
                            />
                            <SectionNumberTextField
                                label={"Check-Out Time To (Optional)"}
                                field={store.formPolicyStayState.$.checkOutTimeTo}
                                width={"100%"}
                            />
                        </Stack>
                        <Stack spacing={2} direction={"row"}>
                            <SectionSelectField
                                field={store.formPolicyStayState.$.guestType}
                                width={"100%"}
                                label={"Guest Type"}
                                items={() => [
                                    <MenuItem value={AcceptedGuestType.AdultOnly} key={AcceptedGuestType.AdultOnly}>Adult Only</MenuItem>,
                                    <MenuItem value={AcceptedGuestType.ChildrenAllowed} key={AcceptedGuestType.ChildrenAllowed}>Children Allowed</MenuItem>,
                                ]}
                            />
                        </Stack>
                    </Stack>}
                {store.formState.$.type.value == PolicyType.Cancel &&
                    <Stack spacing={2} direction={"column"}>
                        <Stack spacing={2} direction={"row"}>
                            <SectionSelectField
                                field={store.formPolicyCancelState.$.penaltyAfterReservationType}
                                width={"100%"}
                                label={"Penalty After Reservation Type"}
                                items={() => [
                                    <MenuItem value={CancelPolicyChargeType.Percentage} key={CancelPolicyChargeType.Percentage}>Percentage</MenuItem>,
                                    <MenuItem value={CancelPolicyChargeType.Night} key={CancelPolicyChargeType.Night}>Night</MenuItem>,
                                ]}
                            />
                            <SectionNumberTextField
                                label={"Penalty After Reservation"}
                                field={store.formPolicyCancelState.$.penaltyAfterReservation}
                                width={"100%"}
                                prefix={store.formPolicyCancelState.$.penaltyAfterReservationType.value == CancelPolicyChargeType.Percentage ? "%" : ""}
                            />
                        </Stack>
                        <Stack spacing={2} direction={"row"}>
                            <SectionSelectField
                                field={store.formPolicyCancelState.$.penaltyAfterDeadlineType}
                                width={"100%"}
                                label={"Penalty After Deadline Type"}
                                items={() => [
                                    <MenuItem value={CancelPolicyChargeType.Percentage} key={CancelPolicyChargeType.Percentage}>Percentage</MenuItem>,
                                    <MenuItem value={CancelPolicyChargeType.Night} key={CancelPolicyChargeType.Night}>Night</MenuItem>,
                                ]}
                            />
                            <SectionNumberTextField
                                label={"Deadline Days"}
                                field={store.formPolicyCancelState.$.deadlineDays}
                                width={"100%"}
                            />
                            <SectionNumberTextField
                                label={"Penalty After Deadline"}
                                field={store.formPolicyCancelState.$.penaltyAfterDeadline}
                                width={"100%"}
                                prefix="%"
                            />
                        </Stack>
                    </Stack>}
                {store.formState.$.type.value == PolicyType.NoShow &&
                    <Stack spacing={2} direction={"column"}>
                        <Stack spacing={2} direction={"row"}>
                            <SectionNumberTextField
                                label={"Penalty Percentage"}
                                field={store.formPolicyNoShowState.$.penaltyPercentage}
                                width={"100%"}
                                prefix="%"
                            />
                        </Stack>
                    </Stack>}
                {store.formState.$.type.value == PolicyType.Pets &&
                    <Stack spacing={2} direction={"column"}>
                        <Stack spacing={2} direction={"row"}>
                            <SectionSelectField
                                field={store.formPolicyPetsState.$.type}
                                width={"100%"}
                                label={"Type"}
                                items={() => [
                                    <MenuItem value={PolicyPetsType.NotAllowed} key={PolicyPetsType.NotAllowed}>Not Allowed</MenuItem>,
                                    <MenuItem value={PolicyPetsType.ChargesMayApply} key={PolicyPetsType.ChargesMayApply}>Charges May Apply</MenuItem>,
                                    <MenuItem value={PolicyPetsType.Free} key={PolicyPetsType.Free}>Free</MenuItem>
                                ]}
                            />
                        </Stack>
                    </Stack>}
                {store.formState.$.type.value == PolicyType.Other &&
                    <Stack spacing={2} direction={"column"}>
                        <Stack spacing={2} direction={"row"}>
                            <SectionTextField
                                label={"Text"}
                                field={store.formPolicyOtherState.$.text}
                                width={"100%"}
                            />
                        </Stack>
                    </Stack>}
            </Section>

            <SectionSeparator/>
            <SectionName title="POLICY PREVIEW" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <Stack spacing={2} direction={"row"}>
                        <LoadingButton loading={store.isLoading} variant="contained" onClick={store.getRuleString}>Get Policy Text</LoadingButton>
                        <Typography>{store.policyString}</Typography>
                    </Stack>
                </Stack>
            </Section>
        </ContainerDetails>
    </Container>;
}));
