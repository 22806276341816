import {observer} from "mobx-react";
import React from "react";
import {Component} from "react";
import {
    Alert,
    Box,
    Container, Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {inject, provider} from "react-ioc";
import {LoadingButton} from "@mui/lab";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {centerBoxStyle} from "app/modules/auth";
import {LocalStorageService} from "app/modules/auth/components/stores/LocalStorageService";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import Icon from "app/modules/auth/components/common/icon";
import {RegisterStore} from "app/modules/auth/components/register/RegisterStore";

@provider(RegisterStore, LocalStorageService)
@observer
export default class Index extends Component<any, any> {
    @inject registerStore: RegisterStore;

    render() {
        if(!this.registerStore.isLoading && this.registerStore.isSuccessRegister) {
            return <Box sx={centerBoxStyle}>
                <Icon/>
                <Typography component="h1" variant="h5">
                    Register
                </Typography>
                <Alert sx={{ mt: 2, mb: 2 }} severity="success">Please check your email inbox, you'll receive an email with the confirmation link.</Alert>
                <Box component="form">
                    <Grid container>
                        <Grid item xs>
                            <BtnLink Link="/login" Text="Login" variant="text"/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>;
        }

        return <Container component="main" maxWidth="xs">
            <Box sx={centerBoxStyle}>
                <Icon/>
                <Typography component="h1" variant="h5">
                    Register
                </Typography>

                {this.registerStore.errorMessage && <Alert sx={{ width: "100%", mt: 2, mb: 2 }} severity="error">{this.registerStore.errorMessage?.split("/n").map((x, i) => { return <div key={i}>{x}</div>})}</Alert>}

                <Box component="form" onSubmit={() => {}} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Full Name"
                        name="name"
                        autoComplete="off"
                        autoFocus
                        value={this.registerStore.formState.$.name.value}
                        error={this.registerStore.formState.$.name.hasError}
                        helperText={this.registerStore.formState.$.name.error}
                        onChange={(e) => this.registerStore.formState.$.name.onChange(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="off"
                        value={this.registerStore.formState.$.email.value}
                        error={this.registerStore.formState.$.email.hasError}
                        helperText={this.registerStore.formState.$.email.error}
                        onChange={(e) => this.registerStore.formState.$.email.onChange(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="off"
                        value={this.registerStore.formState.$.password.value}
                        error={this.registerStore.formState.$.password.hasError}
                        helperText={this.registerStore.formState.$.password.error}
                        onChange={(e) => this.registerStore.formState.$.password.onChange(e.target.value)}
                    />
                    <LoadingButton
                        fullWidth
                        loading={this.registerStore.isLoading}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={this.registerStore.register}
                    >
                        Register
                    </LoadingButton>
                    <BoxCenter>
                        <Stack alignItems={"center"}>
                            <Typography fontSize={"12px"} variant={"subtitle2"}>OR</Typography>
                            <BtnLink Link="/login" Text={<Box sx={{ fontSize: "14px" }}>Login</Box>} variant="text"/>
                        </Stack>
                    </BoxCenter>
                </Box>
            </Box>
        </Container>;
    }
}
