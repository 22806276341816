import ReactGA from "react-ga";

export class GAnalyticsService {
    changeLocation = (path: string) => {
        ReactGA.pageview(window.location.pathname);
    }

    login = () => {
        ReactGA.event({
            category: "user",
            action: "login"
        });
    }

    register = () => {
        ReactGA.event({
            category: "user",
            action: "register"
        });
    }

    forgotPassword = () => {
        ReactGA.event({
            category: "user",
            action: "forgot-password"
        });
    }

    resetPasswordPassword = () => {
        ReactGA.event({
            category: "user",
            action: "reset-password"
        });
    }

    marketingLink = (code) => {
        ReactGA.event({
            category: "marketing",
            action: "link",
            dimension5: code
        });
    }

    searchProperties = (city: string, state: string) => {
        ReactGA.event({
            category: "properties",
            action: "search",
            dimension1: city,
            dimension2: state
        });
    }

    lookAtProperty = (city: string, state: string, propertyId: number) => {
        ReactGA.event({
            category: "properties",
            action: "look",
            dimension1: city,
            dimension2: state,
            dimension3: propertyId.toString()
        });
    }

    startBookingProperty = (propertyId: number) => {
        ReactGA.event({
            category: "properties",
            action: "start-booking",
            dimension3: propertyId.toString()
        });
    }

    paymentIntentBookingProperty = (id: number, reservationCode: string) => {
        ReactGA.event({
            category: "properties",
            action: "payment-intent",
            dimension4: reservationCode
        });
    }

    paymentMadeProperty = (id: number, reservationCode: string) => {
        ReactGA.event({
            category: "properties",
            action: "payment-made",
            dimension4: reservationCode
        });
    }
}
