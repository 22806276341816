import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress, List, ListItem, ListItemText,
    Stack, Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";

import {PolicyShortListFilters, PolicyShortListModel, PolicyType} from "app/modules/main/api/admin-clients.api";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";

export interface DialogPolicySelectorProps {
    closeModal: () => void;
    setPolicy: (policy: PolicySelectorResult) => void;
    type: PolicyType
}

export type PolicySelectorResult = {
    policyId: number;
    name: string;
    text: string;
    type: PolicyType;
}

export const DialogPolicySelector = observer((props: DialogPolicySelectorProps) => {
    const [items, setItems] = useState<PolicyShortListModel[]>();
    const [isLoading, setLoading] = useState(false);
    const [policy, setPolicy] = useState(null);

    const store = useInstance(ApiClients);

    const fetchData = async () => {
        if(isLoading) {
            return;
        }

        try {
            setLoading(true);
            const data = await store.policyClient.getShortList(new PolicyShortListFilters({ type: props.type }));
            setItems(data);
        } finally {
            setLoading(false);
        }
    };

    const select = async () => {
        if(policy.policyId > 0) {
            props.setPolicy(policy);
            props.closeModal();
        }
    }

    useEffect(
        () => {
            fetchData();
        },
        [props.type],
    );

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Select Policy
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                <Stack spacing={2} direction={"column"}>
                    {isLoading ? <LinearProgress/> : <List dense={true} sx={{
                        overflow: 'auto',
                        maxHeight: 300
                    }}>
                        {items?.map(x => <ListItem sx={{cursor: "pointer"}} key={x.id} selected={x.id == policy?.policyId} onClick={() => {
                            let policy : PolicySelectorResult = {
                                name: x.name,
                                policyId: x.id,
                                text: x.text,
                                type: x.type
                            };
                            setPolicy(policy);
                        }}>
                            <ListItemText
                                primary={x.name}
                                secondary={x.text}
                            />
                        </ListItem>)}
                        {!items?.length && <Typography sx={{ width: "100%" }} align={"center"} variant="subtitle2" display="block">No result.</Typography>}
                    </List>}
                </Stack>
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} disabled={!(policy?.policyId > 0)} variant="outlined" onClick={select} color="primary" autoFocus>
                Select
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
