import {provider} from "react-ioc";
import {DetailsStore} from "app/modules/main/components/admin/contacts/details/DetailsStore";
import {observer} from "mobx-react";
import React, {useState} from "react";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import {PolicyShortListModel, PolicyType} from "app/modules/main/api/admin-clients.api";
import {PolicyTypeNameMap} from "app/modules/main/components/admin/policies";
import AddIcon from "@mui/icons-material/Add";
import {DialogPolicySelector} from "app/modules/main/components/admin/properties/details/dialog.policy.selector";

export default provider(DetailsStore)(observer((props: {items: PolicyShortListModel[], type: PolicyType, remove: (number) => void, add: (PolicySelectorResult) => void}) => {
    const [dialog, setDialog] = useState(false);

    return <Stack spacing={2} direction={"column"}>
                <Typography variant={"h5"}>{PolicyTypeNameMap.get(props.type)}</Typography>
                {props.items?.filter(x => x.type == props.type).map(x => <Stack spacing={2} direction={"row"} key={x.id}>
                <Typography sx={{ minWidth: 200 }}>{x.name}</Typography>
                <Typography variant={"body2"}>{x.text}</Typography>
                <IconButton color="error" onClick={props.remove}>
                    <RemoveIcon fontSize={"large"}/>
                </IconButton>
            </Stack>)}
            <Box>
                <IconButton color="primary" onClick={() => setDialog(true)}>
                    <AddIcon fontSize={"large"}/>
                </IconButton>
            </Box>
            {dialog && <DialogPolicySelector
                type={props.type}
                setPolicy={props.add}
                closeModal={() => {
                    setDialog(false);
                }}
            />}
        </Stack>;
}));
