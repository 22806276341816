import {action, makeObservable, observable, runInAction} from "mobx";
import {
    DataListGetParamsOfPropertySearchParams,
    IPropertySearchParams,
    PropertySearchListModel, PropertySearchParams,
    SearchCitiesResult
} from "app/modules/main/api/main-clients.api";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {MainStore} from "app/modules/main/stores/MainStore";
import queryString from 'query-string';
import {DateHelper} from "app/modules/main/components/common/DateHelper";
import {AnalyticsService} from "app/modules/main/stores/AnalyticsService";

export interface IGuests {
    adults: number;
    pets: number;
    children: number;
}

interface IFilter {
    minBedrooms: number | null;
    maxBedrooms: number | null;
    minBathrooms: number | null;
    maxBathrooms: number | null;
    minRate: number | null;
    maxRate: number | null;
    propertyTypes: number[];
    propertyAmenities: number[];
}

export class SearchStore extends BaseListStore<PropertySearchListModel> {
    @inject apiClient: ApiClients;
    @inject mainStore: MainStore;
    @inject analyticsService: AnalyticsService;

    selectedListingId: number = null;

    searchString: string = null;
    searchCitiesResult: SearchCitiesResult[] = [new SearchCitiesResult({ city: 'Los Angeles', state: 'CA' })];
    searchValue: SearchCitiesResult = this.searchCitiesResult?.length > 0 ? this.searchCitiesResult[0] : null;
    samples: PropertySearchListModel[] = null;

    searchParams: IPropertySearchParams = {
        minBedrooms: null,
        maxBedrooms: null,
        minBathrooms: null,
        maxBathrooms: null,
        minRate: null,
        maxRate: null,
        propertyTypes: [],
        propertyAmenities: [],
        guestsAdults: 1,
        guestsChildren: 0,
        guestsPets: 0,
        city: null,
        state: null,
        from: null,
        to: null
    }

    guestsEdit: IGuests = {
        adults: 1,
        children: 0,
        pets: 0
    }

    filterEdit: IFilter = {
        maxBathrooms: null,
        maxBedrooms: null,
        maxRate: null,
        minBathrooms: null,
        minBedrooms: null,
        minRate: null,
        propertyAmenities: [],
        propertyTypes: []

    }

    dates = [null, null];

    constructor() {
        super();

        makeObservable(this, {
            selectedListingId: observable,
            searchCitiesResult: observable,
            searchString: observable,
            searchValue: observable,
            dates: observable,
            samples: observable,

            searchParams: observable.deep,

            filterEdit: observable.deep,
            guestsEdit: observable.deep,

            selectListing: action,
            goBack: action,
            searchCities: action,
            setSearchValue: action,
            setDates: action,

            copyGuestEdit: action,
            guestsUpdate: action,
            resetGuestEdit: action,

            copyFilterEdit: action,
            resetFilterEdit: action,
            filterUpdate: action,

            checkPropertyType: action,
            checkAmenity: action,
            updateMinRate: action,
            updateMaxRate: action,
            updateMinBedrooms: action,
            updateMaxBedrooms: action,
            updateMinBathrooms: action,
            updateMaxBathrooms: action,
            clickOnCountry: action,
            clickOnState: action,
            changePage: action,
            init: action
        });
    }

    clickOnCountry = () => {
        this.searchValue = null;
        this.searchParams.state = null;
        this.searchParams.city = null;
    }

    clickOnState = () => {
        this.searchValue = null;
        this.searchParams.city = null;
    }

    searchCities = async () => {
        let trimmedValue = this.searchString?.trim();
        if(trimmedValue?.length >= 2) {
            await this.wrapLoadingApiCall(async () => {
                this.searchCitiesResult.length = 0;

                let cities = await this.apiClient.searchClient.searchCities(trimmedValue);

                runInAction(() => {
                    this.searchCitiesResult = cities;
                });
            });
        }
    }
    getSearchValue = () => (!!this.searchValue ? `${this.searchValue?.city}, ${this.searchValue?.state}` : null);
    guestsUpdate = () => {
        this.searchParams.guestsAdults = this.guestsEdit.adults;
        this.searchParams.guestsChildren = this.guestsEdit.children;
        this.searchParams.guestsPets = this.guestsEdit.pets;

        this.changeFiltersAndLoadData();
    }

    copyGuestEdit = () => {
        this.guestsEdit.adults = this.searchParams.guestsAdults;
        this.guestsEdit.children = this.searchParams.guestsChildren;
        this.guestsEdit.pets = this.searchParams.guestsPets;
    }
    resetGuestEdit = () => {
        this.guestsEdit.adults = 1;
        this.guestsEdit.children = 0;
        this.guestsEdit.pets = 0;
    }
    copyFilterEdit = () => {
        this.filterEdit.maxBathrooms = this.searchParams.maxBathrooms;
        this.filterEdit.maxBedrooms = this.searchParams.maxBedrooms;
        this.filterEdit.maxRate = this.searchParams.maxRate;
        this.filterEdit.minBathrooms = this.searchParams.minBathrooms;
        this.filterEdit.minBedrooms = this.searchParams.minBedrooms;
        this.filterEdit.minRate = this.searchParams.minRate;
        this.filterEdit.propertyAmenities = this.searchParams.propertyAmenities;
        this.filterEdit.propertyTypes = this.searchParams.propertyTypes;
    }

    resetFilterEdit = () => {
        this.filterEdit.maxBathrooms = null;
        this.filterEdit.maxBedrooms = null;
        this.filterEdit.maxRate = null;
        this.filterEdit.minBathrooms = null;
        this.filterEdit.minBedrooms = null;
        this.filterEdit.minRate = null;
        this.filterEdit.propertyAmenities = [];
        this.filterEdit.propertyTypes = [];
    }
    filterUpdate = () => {
        this.searchParams.maxBathrooms = this.filterEdit.maxBathrooms;
        this.searchParams.maxBedrooms = this.filterEdit.maxBedrooms;
        this.searchParams.maxRate = this.filterEdit.maxRate;
        this.searchParams.minBathrooms = this.filterEdit.minBathrooms;
        this.searchParams.minBedrooms = this.filterEdit.minBedrooms;
        this.searchParams.minRate = this.filterEdit.minRate;
        this.searchParams.propertyAmenities = this.filterEdit.propertyAmenities;
        this.searchParams.propertyTypes = this.filterEdit.propertyTypes;

        this.changeFiltersAndLoadData();
    }
    checkPropertyType = (id) => {
        let index = this.filterEdit.propertyTypes.indexOf(id);

        if(index >= 0) {
            this.filterEdit.propertyTypes.splice(index, 1);
        } else {
            this.filterEdit.propertyTypes.push(id);
        }
    }
    checkAmenity = (id) => {
        let index = this.filterEdit.propertyAmenities.indexOf(id);

        if(index >= 0) {
            this.filterEdit.propertyAmenities.splice(index, 1);
        } else {
            this.filterEdit.propertyAmenities.push(id);
        }
    }

    updateMinRate = (value: number) => {
        this.filterEdit.minRate = value;

        if (this.filterEdit.maxRate > 0 && this.filterEdit.minRate >= this.filterEdit.maxRate) {
            this.filterEdit.maxRate = null;
        }
    }
    updateMaxRate = (value: number) => {
        this.filterEdit.maxRate = value;

        if (this.filterEdit.minRate > 0 && this.filterEdit.minRate >= this.filterEdit.maxRate) {
            this.filterEdit.minRate = null;
        }
    }
    updateMinBedrooms = (value: number) => {
        this.filterEdit.minBedrooms = value;

        if (this.filterEdit.maxBedrooms > 0 && this.filterEdit.minBedrooms >= this.filterEdit.maxBedrooms) {
            this.filterEdit.maxBedrooms = null;
        }
    }
    updateMaxBedrooms = (value: number) => {
        this.filterEdit.maxBedrooms = value;

        if (this.filterEdit.minBedrooms > 0 && this.filterEdit.minBedrooms >= this.filterEdit.maxBedrooms) {
            this.filterEdit.minBedrooms = null;
        }
    }
    updateMinBathrooms = (value: number) => {
        this.filterEdit.minBathrooms = value;

        if (this.filterEdit.maxBathrooms > 0 && this.filterEdit.minBathrooms >= this.filterEdit.maxBathrooms) {
            this.filterEdit.maxBathrooms = null;
        }
    }
    updateMaxBathrooms = (value: number) => {
        this.filterEdit.maxBathrooms = value;

        if (this.filterEdit.minBathrooms > 0 && this.filterEdit.minBathrooms >= this.filterEdit.maxBathrooms) {
            this.filterEdit.minBathrooms = null;
        }
    }

    setGuestAdults = (value: number) => this.searchParams.guestsAdults = value;
    setDates = (value) => {
        this.dates = value;
        let from = value[0];
        let to = value[1];

        this.searchParams.from = !!from ? DateHelper.convertDate(from) : null
        this.searchParams.to = !!to ? DateHelper.convertDate(to) : null;

        this.changeFiltersAndLoadData();
    }
    setSearchValue = (value: SearchCitiesResult, muteSearch = false) => {
        this.searchValue = value;
        this.searchParams.city = value?.city ?? null;
        this.searchParams.state = value?.state ?? null;

        if(!muteSearch) {
            this.changeFiltersAndLoadData();
        }
    };
    setSearchString = (value: string) => this.searchString = value;
    selectListing = (id) => this.selectedListingId = id;
    goBack = () => this.selectedListingId = null;

    getTitle = () => {
        if (this.searchParams.city?.length > 0) {
            return this.searchParams.city;
        } else if (this.searchParams.state?.length > 0) {
            return this.mainStore.clientData.states.find(x => x.code == this.searchParams.state).name;
        } else {
            return "US";
        }
    }

    changeFiltersAndLoadData() {
        this.skipRows = 0;
        this.loadData();
    }

    loadData() {
        this.wrapLoadingApiCall(async () => {
            this.analyticsService.searchProperty(this.searchParams.city, this.searchParams.state);

            let filters = new PropertySearchParams({
                city: this.searchParams.city,
                state: this.searchParams.state,
                guestsAdults: this.searchParams.guestsAdults,
                guestsChildren: this.searchParams.guestsChildren,
                guestsPets: this.searchParams.guestsPets,
                propertyAmenities: this.searchParams.propertyAmenities,
                propertyTypes: this.searchParams.propertyTypes,
                minRate: this.searchParams.minRate,
                maxRate: this.searchParams.maxRate,
                minBathrooms: this.searchParams.minBathrooms,
                maxBathrooms: this.searchParams.maxBathrooms,
                minBedrooms: this.searchParams.minBedrooms,
                maxBedrooms: this.searchParams.maxBedrooms,
                from: this.searchParams.from,
                to: this.searchParams.to,
            });
            const [result] = await Promise.all([
                this.apiClient.searchClient.getList(new DataListGetParamsOfPropertySearchParams({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    rangeStart = () => this.skipRows * this.pageSize + 1;
    rangeEnd = () => this.skipRows * this.pageSize + this.items.length;
    totalPages = () => Math.floor(this.totalRows/this.pageSize) + 1;
    currentPage = () => Math.floor(this.skipRows/this.pageSize) + 1;
    changePage = (page: number) => {
        let skipRows = (page - 1)*this.pageSize;

        if(skipRows != this.skipRows) {
            this.skipRows = skipRows;
            this.loadData();
        }
    }
    getBookParams = () => {
        let obj = { from: this.searchParams.from, to: this.searchParams.to, adults: this.searchParams.guestsAdults, children: this.searchParams.guestsChildren, pets: this.searchParams.guestsPets };
        return `?${queryString.stringify(obj)}`;
    }

    applySpecificFilters() {}

    async init() {
        await this.wrapInitLoadingApiCall(async () => {
            this.samples = await this.apiClient.searchClient.getSamples();
        }, null, () => {
            runInAction(() => {
                this.isInitLoading = false;
            });
        });
    }
}

