// @ts-nocheck
import {ApiClientConfig} from "app/modules/main/api/ApiClientConfig";

export class AuthorizedApiBase {
    private readonly config: ApiClientConfig;

    protected constructor(config: ApiClientConfig) {
        this.config = config;
    }

    protected transformOptions = (options: RequestInit): Promise<RequestInit> => {
        options.headers = {
            ...options.headers,
            //'Authorization': 'Bearer ' + this.config.getBearerToken(),
        };
        return Promise.resolve(options);
    };
}
