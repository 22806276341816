import {observer} from "mobx-react";
import React from "react";
import {Component} from "react";
import {
    Alert,
    Box,
    Container,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {LoginStore} from "app/modules/auth/components/login/LoginStore";
import {inject, provider} from "react-ioc";
import {LoadingButton} from "@mui/lab";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {centerBoxStyle} from "app/modules/auth";
import {LocalStorageService} from "app/modules/auth/components/stores/LocalStorageService";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import Icon from "app/modules/auth/components/common/icon";
import queryString from "query-string";

@provider(LoginStore, LocalStorageService)
@observer
export default class Index extends Component<any, any> {
    @inject loginStore: LoginStore;

    render() {
        if(!this.loginStore.isLoading && this.loginStore.isSuccessLogin) {
            const parsed = queryString.parse(location.search);

            if(parsed.book == "1") {
                return <NavigateHook path={"/book"}/>;
            }

            return <NavigateHook path={"/"}/>;
        }

        return <Container component="main" maxWidth="xs">
            <Box sx={centerBoxStyle}>
                <Icon/>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                {this.loginStore.errorMessage && <Alert sx={{ width: "100%", mt: 2, mb: 2 }} severity="error">{this.loginStore.errorMessage}</Alert>}

                <Box component="form" onSubmit={() => {}} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={this.loginStore.formState.$.email.value}
                        error={this.loginStore.formState.$.email.hasError}
                        helperText={this.loginStore.formState.$.email.error}
                        onChange={(e) => this.loginStore.formState.$.email.onChange(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={this.loginStore.formState.$.password.value}
                        error={this.loginStore.formState.$.password.hasError}
                        helperText={this.loginStore.formState.$.password.error}
                        onChange={(e) => this.loginStore.formState.$.password.onChange(e.target.value)}
                    />
                    <LoadingButton
                        fullWidth
                        loading={this.loginStore.isLoading}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={this.loginStore.login}
                    >
                        Sign In
                    </LoadingButton>
                    <BoxCenter>
                        <BtnLink Link="/auth/forgot-password" Text={<Box sx={{ fontSize: "12px" }}>Forgot password?</Box>} variant="text"/>
                    </BoxCenter>
                    <BoxCenter>
                        <Stack alignItems={"center"}>
                            <Typography fontSize={"12px"} variant={"subtitle2"}>OR</Typography>
                            <BtnLink Link="/auth/register" Text={<Box sx={{ fontSize: "14px" }}>Register</Box>} variant="text"/>
                        </Stack>
                    </BoxCenter>
                </Box>
            </Box>
        </Container>;
    }
}
