import React, {useEffect} from "react";
import {observer} from "mobx-react";
import Details from "app/modules/main/components/common/details";
import {Box, Button, Divider, Grid, Link, Stack, Typography} from "@mui/material";
import {provider, useInstance} from "react-ioc";
import {DetailsStore} from "app/modules/main/components/admin/books/details/DetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import moment from "moment";
import Rates from "app/modules/main/components/common/details/rates";
import {PropertyReservationStatus} from "app/modules/main/api/admin-clients.api";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {reservationColorMap, reservationStatusMap} from "app/modules/main/components/admin/requests/list";
import PaymentDetails from "app/modules/main/components/common/details/payment-details";

export default provider(DetailsStore)(observer((props: { backButton?: React.ReactNode, bookId: number }) => {
    let store = useInstance(DetailsStore);

    useEffect(
        () => {
            store.init(props.bookId);
        },[props.bookId]);

    if(store.isInitLoading) {
        return <Loading />
    }

    let stayDetails = <Grid container spacing={2}>
        <Grid item xs={12}><Divider sx={{ mb: 1, p: 1 }}/></Grid>
        <Grid item xs={6} sm={6} md={6}>
            <Stack sx={{ textAlign: { xs: "left", sm: "left", md: "center" } }}>
                <Typography variant={"body2"} sx={{ pb: 1 }}><b>GUEST</b></Typography>
                <Typography variant={"body2"}><b>Name:</b> {store.reservation.fullName}</Typography>
                <Typography variant={"body2"}><b>Phone:</b> {store.reservation.phone}</Typography>
                <Typography variant={"body2"}><b>Email:</b> {store.reservation.email}</Typography>
                <Grid item xs={12}><Divider sx={{ mb: 1, p: 1 }}/></Grid>
                <Typography variant={"body2"}><b>From:</b> {moment.utc(store.reservation.from).format("ll - LT")}</Typography>
                <Typography variant={"body2"}><b>To:</b> {moment.utc(store.reservation.to).format("ll - LT")}</Typography>
            </Stack>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{ pb: 1 }}>
            <Stack sx={{ textAlign: { xs: "right", sm: "right", md: "center" } }}>
                <Typography variant={"body2"}><b>Price</b></Typography>
            </Stack>
            <Rates rates={store.reservation.rates}/>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={2}>
                {store.reservation.status == PropertyReservationStatus.Requested && <Grid item xs={6}><Button variant="contained" fullWidth color={"error"} onClick={store.cancel}>Cancel</Button></Grid>}
                {store.reservation.status == PropertyReservationStatus.Requested && <Grid item xs={6}><Button variant="contained" fullWidth color={"warning"} onClick={store.confirm}>Confirm</Button></Grid>}

                {store.reservation.status == PropertyReservationStatus.Cancelled && <Grid item xs={12}><BoxCenter><Box sx={{ color: reservationColorMap.get(store.reservation.status) }}>{reservationStatusMap.get(store.reservation.status)}</Box></BoxCenter></Grid>}

                {store.reservation.status == PropertyReservationStatus.Confirmed && <Grid item xs={store.reservation.canUpdateStatus ? 6 : 12}><BoxCenter><Box sx={{ color: reservationColorMap.get(store.reservation.status) }}>{reservationStatusMap.get(store.reservation.status)}</Box></BoxCenter></Grid>}
                {store.reservation.status == PropertyReservationStatus.Confirmed && store.reservation.canUpdateStatus && <Grid item xs={6}><Button variant="contained" fullWidth color={"error"} onClick={store.cancel}>Reject</Button></Grid>}
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <br/>
            <PaymentDetails details={store.reservation}/>
            <br/>
            {store.reservation.paymentLink?.length > 0 && <BoxCenter>
                <Link href={store.reservation.paymentLink} variant={"subtitle2"} target={"_blank"}>Payment Details (Stripe Dashboard)</Link>
            </BoxCenter>}
        </Grid>
    </Grid>

    return (<Details info={store.reservation.publicDetails} additionalBlock={stayDetails} backButton={props.backButton || <br/> } />);
}));
