import {FieldState, FormState} from "formstate";
import {email, required} from "app/modules/common/form/validators";
import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/auth/services/AxiosBaseClient";
import {BaseStore} from "app/modules/auth/services/BaseStore";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";
import {LocalStorageService} from "app/modules/auth/components/stores/LocalStorageService";
import {LoginRequest} from "app/modules/auth/api/clients.api";
import {AnalyticsService} from "app/modules/main/stores/AnalyticsService";

export class LoginStore extends BaseStore {
    @inject apiClients: ApiClients;
    @inject localStorageService: LocalStorageService;
    @inject analyticsService: AnalyticsService;

    isLoading: boolean = false;
    isSuccessLogin: boolean = false;
    errorMessage: string = null;

    formState = new FormState({
        email: new FieldState('').validators(required, email),
        password: new FieldState('').validators(required),
        rememberMe: new FieldState(false)
    });

    constructor() {
        super();

        makeObservable(this, {
            isLoading: observable,
            isSuccessLogin: observable,
            errorMessage: observable,

            login: action
        });
    }

    login = async () => {
        this.errorMessage = null;

        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        runInAction(() => { this.isLoading = true; })

        this.executeRecaptcha(async (token) => {
            try {
                const result = await this.apiClients.authClient.login(new LoginRequest({
                    email: this.formState.$.email.value,
                    password: this.formState.$.password.value,
                    rememberMe: this.formState.$.rememberMe.value
                }), token);

                if(result.success) {
                    this.analyticsService.login();
                    runInAction(() => { this.isSuccessLogin = true; });
                } else {
                    runInAction(() => { this.errorMessage = "Invalid login attempt."; });
                }
            } catch(ex) {
                if(!ex.isCodedExceptionApi) {
                    runInAction(() => {
                        this.errorMessage = "Unexpected error";
                    });
                }

                ApiErrorHandler(ex);
            }
            finally {
                runInAction(() => { this.isLoading = false; })
            }
        });
    }
}
