import {PropertySearchListModel} from "app/modules/main/api/main-clients.api";
import {Box, Button, Card, Grid, Stack, Typography} from "@mui/material";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {useInstance} from "react-ioc";
import {SearchStore} from "app/modules/main/components/main/search/SearchStore";

export const ResultItem = (props: { item: PropertySearchListModel }) => {
    return <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }} xl={2}>
        <ResultItemCard item={props.item} />
    </Grid>;
}

export const ResultItemCard = (props: { item: PropertySearchListModel }) => {
    const navigate = useNavigate();
    const searchStore = useInstance(SearchStore);

    return <Card sx={{ p: 1, cursor: "pointer", boxShadow: "none" }} onClick={() => navigate("/search/" + props.item.id + searchStore.getBookParams())}>
        <Stack direction={"column"}>
            <Box>
                <Button variant={"text"} sx={{ minWidth: 0, padding: 0, fontSize: "12px" }} fullWidth>
                    <Box component={"div"} sx={{ whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: 'hidden', width: "100%", textAlign: "left" }}><Link to={"/search/" + props.item.id + searchStore.getBookParams()}> {props.item.name}</Link></Box>
                </Button>
            </Box>
            <Typography sx={{ fontSize: "12px", pb: 1 }}><b>{NumberHelper.formatUSDollar(props.item.rate)}</b> avg / night</Typography>
            <img src={`/mainController/Search/GetFile?path=${props.item.thumbnailUrl}`} height={250} style={{ borderRadius: "10px" }}/>
            <Box sx={{ pt: 1}}>
                <Button variant={"text"} sx={{ minWidth: 0, padding: 0, fontSize: "10px" }}>
                    {props.item.city}, {props.item.state}
                </Button>
            </Box>
            <Typography sx={{ fontSize: "12px", pb: 1 }}>{props.item.bedrooms} Bedrooms | {props.item.bathroom} Bath | Sleeps {props.item.sleeps}</Typography>
        </Stack>
    </Card>;
}
