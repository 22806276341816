import {observer} from "mobx-react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, Stack,
} from "@mui/material";
import React from "react";
import {PropertyTimeLineReservation} from "app/modules/main/api/admin-clients.api";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import {DateHelper} from "app/modules/main/components/common/DateHelper";

export default observer((props: { close: () => void, book: PropertyTimeLineReservation, delete: () => void }) => {
    return <Dialog
        open={true}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <DialogTitle id="alert-dialog-title">
            External Book Details
        </DialogTitle>
        <DialogContent>
            <Stack spacing={1} direction={"column"}>
                <Divider/>
                <Box>Guest: <b>{props.book.name}</b></Box>
                <Box>From: <b>{DateHelper.formatMomentDateFull(props.book.from)}</b></Box>
                <Box>To: <b>{DateHelper.formatMomentDateFull(props.book.to)}</b></Box>
                <Box>Total: <b>{NumberHelper.formatUSDollar(props.book.total)}</b></Box>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Close</Button>
            <Button onClick={props.delete} color={"error"}>
                Delete
            </Button>
        </DialogActions>
    </Dialog>;
});
