import {BaseStore} from "app/modules/auth/services/BaseStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/auth/services/AxiosBaseClient";
import {action, makeObservable, observable, runInAction} from "mobx";
import {ApiErrorHandler, ApiErrorTryGetMessage} from "app/modules/common/static/ErrrorHandler";
import {ConfirmEmailRequest} from "app/modules/auth/api/clients.api";
import queryString from 'query-string';

export class ConfirmEmailStore extends BaseStore {
    @inject apiClients: ApiClients;

    isLoading: boolean = true;
    isSuccess: boolean = false;
    isWrongParams: boolean = false;
    errorMessage: string = null;

    email: string = null;
    code: string = null;

    constructor() {
        super();

        makeObservable(this, {
            isLoading: observable,
            isSuccess: observable,
            isWrongParams: observable,
            errorMessage: observable,

            submit: action,
            init: action
        });
    }

    submit = async () => {
        try {
            let result = await this.apiClients.authClient.confirmEmail(new ConfirmEmailRequest({
                code: this.code,
                email: this.email,
            }));

            if(result.success) {
                runInAction(() => { this.isSuccess = true; });
            } else {
                runInAction(() => {
                    this.errorMessage = result.error;
                });
            }
        } catch(ex) {
            if(!ex.isCodedExceptionApi) {
                runInAction(() => {
                    this.errorMessage = "Unexpected error";
                });
            }

            const errorMessage = ApiErrorTryGetMessage(ex);

            if(errorMessage?.length > 0) {
                runInAction(() => {
                    this.errorMessage = errorMessage;
                });
            } else {
                ApiErrorHandler(ex);
            }
        }
        finally {
            runInAction(() => { this.isLoading = false; })
        }
    }

    init = async () => {
        const parsed = queryString.parse(location.search);

        this.email = parsed.email as string;
        this.code = decodeURI(parsed.code as string);

        this.isWrongParams = !(this.email?.length > 0 && this.code.length > 0);

        if(!this.isWrongParams) {
            await this.submit();
        }
    }
}
