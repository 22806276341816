import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {
    Badge,
    Box,
    Button,
    Divider,
    Grid,
    InputBase,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {grey, red} from "@mui/material/colors";
import {provider, useInstance} from "react-ioc";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {CalendarStore} from "app/modules/main/components/admin/calendar/CalendarStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {propertyStatusColorMap} from "app/modules/main/components/admin/properties/list";
import {CalendarMonthGroup, PropertyCalendarItemStatus, PropertyStatus} from "app/modules/main/api/admin-clients.api";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import {SectionNumberTextField} from "app/modules/common/details/text-field";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const calendarDayStatusColorMap: Map<PropertyCalendarItemStatus, string> = new Map<PropertyCalendarItemStatus, string>([
    [PropertyCalendarItemStatus.Available, "#6060601f"],
    [PropertyCalendarItemStatus.Unavailable, "#d32f2f40"],
    [PropertyCalendarItemStatus.Reserved, "#2e7d3280"],
]);

const selectedColor = "#60606054";

export default provider(CalendarStore)(observer(() => {
    let adminStore = useInstance(AdminStore);
    let store = useInstance(CalendarStore);

    useEffect(
        () => {
            adminStore.selectMenu(AdminMenu.Calendar);
            store.init();
        }, []);

    let Month = (props: { group: CalendarMonthGroup }) => (<Box sx={{ p: 1 }}>
        <Grid container>
            <Grid item xs={12} sx={{ pb: 1 }}><Typography textAlign={"center"}>{`${months[props.group.month - 1]} ${props.group.year}`}</Typography></Grid>
            <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
                {Array.apply(null, Array(props.group.startDay)).map((x, index) => <Box key={index} sx={{ minHeight: "75px", width: "14.28%" }}/>)}
                {props.group.days.map((x, index) => {
                    return <Box
                        sx={{
                            minHeight: "75px",
                            width: "14.28%",
                            backgroundImage: `-webkit-linear-gradient(-50deg, ${calendarDayStatusColorMap.get(x.prevStatus ?? PropertyCalendarItemStatus.Available)} 50%, ${calendarDayStatusColorMap.get(x.status)} 50%)`,
                            border: `${grey[500]} 1px solid`,
                            flexDirection: "row",
                            position: "relative"
                        }}
                        key={x.date}
                        onClick={() => x.status != PropertyCalendarItemStatus.Reserved && store.selectDate(x.date)}
                    >
                        {store.selectedDates.indexOf(x.date) >= 0 && <Box sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: selectedColor
                        }} />}
                        <Typography textAlign={"center"} sx={{ pt: 1 }}>{index + 1}</Typography>
                        { x.status != PropertyCalendarItemStatus.Reserved && !x.discountRate && <Typography textAlign={"center"} sx={{
                            pt: 2,
                            fontSize: x.isOverridden ? "14px" : "12px",
                            color:  x.isOverridden ? grey[900] : grey[600],
                        }}><b>{NumberHelper.formatUSDollar(x.rate)}</b></Typography>}

                        { x.status != PropertyCalendarItemStatus.Reserved && x.discountRate > 0 && <Typography textAlign={"center"} sx={{
                            pt: 1,
                            fontSize: "10px",
                            color: grey[600],
                            textDecoration: "line-through"
                        }}><b>{NumberHelper.formatUSDollar(x.rate)}</b></Typography>}

                        { x.status != PropertyCalendarItemStatus.Reserved && x.discountRate > 0 && <Typography textAlign={"center"} sx={{
                            fontSize: "12px",
                            color:  red[600],
                        }}><b>{NumberHelper.formatUSDollar(x.discountRate)}</b></Typography>}

                        { false && index % 3 == 1 && <BoxCenter sx={{ pt: 3 }}><Badge badgeContent={" "} color="error"/></BoxCenter>}
                        { false && index % 3 == 2 && <BoxCenter sx={{ pt: 3 }}><Badge badgeContent={" "} color="success"/></BoxCenter>}
                    </Box>;
                })}
            </Grid>
        </Grid>
    </Box>);

    if(store.isInitLoading) {
        return <Loading/>;
    }

    return <Box>
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <nav aria-label="main mailbox folders">
                    <BoxCenter>
                        <SearchPaper width={400}>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={""}
                                onChange={(e) => {}}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <SearchVerDivider />
                            <Button variant="text" onClick={() => {}}>Search</Button>
                        </SearchPaper>
                    </BoxCenter>
                    <List sx={{ height: "calc(100vh - 230px)", overflowY: "auto", mt: 2 }}>
                        {store.properties?.map((x, index) => {
                            return <ListItem disablePadding key={x.id}>
                                <ListItemButton selected={store.propertyId == x.id} onClick={() => store.selectProperty(x.id)}>
                                    <Box sx={{ width: "5px", height: "25px", backgroundColor: propertyStatusColorMap.get(x.status) }} />
                                    <ListItemText sx={{ paddingLeft: "5px" }} primary={x.name} />
                                </ListItemButton>
                            </ListItem>;
                        })}

                    </List>
                </nav>
            </Grid>
            <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={12} sx={{ height: "90px" }}>
                        <Stack direction={"row"}>
                            <Box display="flex"
                                 justifyContent="center"
                                 alignItems="center">
                                <SectionNumberTextField
                                    label={"Default Rate"}
                                    field={store.formState.$.defaultRate}
                                    prefix="$"
                                />
                                <Button sx={{ mr: 4, height: "34px" }} onClick={() => store.updateDefaultRate()}>Update Default Rate</Button>
                                {store.propertyDetails?.status ==  PropertyStatus.Listed && <Button sx={{ mr: 4, height: "34px" }} variant={"contained"} color={"warning"} onClick={() => store.delistProperty()}>Delist</Button>}
                                {store.propertyDetails?.status ==  PropertyStatus.Unlisted && <Button sx={{ mr: 4, height: "34px" }} variant={"contained"} color={"success"} onClick={() => store.listProperty()}>List</Button>}
                                {store.selectedDates?.length > 0 && <React.Fragment>
                                    <BoxCenter><Typography variant={"h6"} sx={{ pr: 2 }}>Selected Dates ({store.selectedDates.length}):</Typography></BoxCenter>
                                    <Button sx={{ mr: 4, height: "34px" }} variant={"contained"} color={"error"} onClick={() => store.markAsUnavailable()}>Mark As Unavailable</Button>
                                    <Button sx={{ mr: 4, height: "34px" }} variant={"contained"} color={"success"} onClick={() => store.markAsAvailable()}>Mark As Available</Button>
                                    <SectionNumberTextField
                                        label={"Rate"}
                                        field={store.selectedFormState.$.rate}
                                        prefix="$"
                                    />
                                    <SectionNumberTextField
                                        label={"Discount Rate"}
                                        field={store.selectedFormState.$.discountRate}
                                        prefix="$"
                                    />
                                    <Button sx={{ mr: 4, height: "34px" }} onClick={() => store.updateSelectedRate()}>Update rate</Button>
                                </React.Fragment>}
                            </Box>
                        </Stack>
                        <Divider sx={{ pt:1 }}/>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ height: "calc(100vh - 245px)", overflowY: "auto", pt: 3 }}>
                    {store.monthGroups?.map(x => <Grid item xs={4} key={`${x.year}${x.month}`}><Month group={x} /></Grid>)}
                </Grid>
            </Grid>
        </Grid>
    </Box>;
}));
