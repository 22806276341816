import {BaseStore} from "app/modules/auth/services/BaseStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/auth/services/AxiosBaseClient";
import {LocalStorageService} from "app/modules/auth/components/stores/LocalStorageService";
import {FieldState, FormState} from "formstate";
import {email, required} from "app/modules/common/form/validators";
import {action, makeObservable, observable, runInAction} from "mobx";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";
import {RegisterRequest} from "app/modules/auth/api/clients.api";
import {AnalyticsService} from "app/modules/main/stores/AnalyticsService";

export class RegisterStore extends BaseStore {
    @inject apiClients: ApiClients;
    @inject localStorageService: LocalStorageService;
    @inject analyticsService: AnalyticsService;

    isLoading: boolean = false;
    isSuccessRegister: boolean = false;
    errorMessage: string = null;

    formState = new FormState({
        email: new FieldState('').validators(required, email),
        password: new FieldState('').validators(required),
        name: new FieldState('').validators(required),
    });

    register = async () => {
        this.errorMessage = null;

        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        runInAction(() => { this.isLoading = true; })

        this.executeRecaptcha(async (token) => {
            try {
                const result = await this.apiClients.authClient.register(new RegisterRequest({
                    email: this.formState.$.email.value,
                    password: this.formState.$.password.value,
                    name: this.formState.$.name.value
                }), token);

                if(result.success) {
                    this.analyticsService.register();
                    runInAction(() => { this.isSuccessRegister = true; });
                } else {
                    runInAction(() => { this.errorMessage = result.error; });
                }
            } catch(ex) {
                if(!ex.isCodedExceptionApi) {
                    runInAction(() => {
                        this.errorMessage = "Unexpected error";
                    });
                }

                ApiErrorHandler(ex);
            }
            finally {
                runInAction(() => { this.isLoading = false; })
            }
        });
    }

    constructor() {
        super();

        makeObservable(this, {
            isLoading: observable,
            isSuccessRegister: observable,
            errorMessage: observable,

            register: action
        });
    }
}
