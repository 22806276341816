import React from "react";
import {Box, Breadcrumbs, Button, Card, Divider, Grid, Stack, Typography} from "@mui/material";
import {observer} from "mobx-react";

import GroupsIcon from '@mui/icons-material/Groups';
import BedIcon from '@mui/icons-material/Bed';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import ShowerIcon from '@mui/icons-material/Shower';
import PetsIcon from '@mui/icons-material/Pets';
import HomeIcon from '@mui/icons-material/Home';

import ImageGallery from "react-image-gallery";
import {PropertyPublicDetails} from "app/modules/main/api/main-clients.api";
import draftToHtml from 'draftjs-to-html';
import {StringHelper} from "app/modules/common/helpers/StringHelper";

export default observer((props : { additionalBlock?: React.ReactNode, backButton?: React.ReactNode, info: PropertyPublicDetails }) => {
    let navigation = <Box sx={{  }}>
        <Breadcrumbs separator="›">ImageGallery
            <Button variant={"text"} sx={{ minWidth: 0, padding: 0, fontSize: "12px" }}>
                USA
            </Button>
            <Button variant={"text"} sx={{ minWidth: 0, padding: 0, fontSize: "12px" }}>
                {props.info.stateDisplay}
            </Button>
            <Button variant={"text"} sx={{ minWidth: 0, padding: 0, fontSize: "12px" }}>
                {props.info.city}
            </Button>
            <Typography color="text.primary" sx={{ fontSize: "12px" }}>Listing #{props.info.id}</Typography>
        </Breadcrumbs>
    </Box>;

    let title = <Typography variant={"h5"}>{props.info.name}</Typography>
    let titleMobile = <Typography variant={"h6"} sx={{ fontSize: "16px" }}>Listing #{props.info.id} - {props.info.city}, {props.info.stateDisplay} (USA)</Typography>

    let specItem = (icon: React.ReactNode, text: string) => <Grid item xs={12} sm={12} md={6} lg={4}>
        <Stack direction={"row"} spacing={1}>
            <Box>{icon}</Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>{text}</Box>
        </Stack>
    </Grid>;

    let specs = <Grid container>
        {specItem(<HomeIcon/>, props.info.type)}
        {specItem(<GroupsIcon/>, `Max. occupancy: ${props.info.sleeps}`)}
        {specItem(<BedIcon/>, props.info.beds.map(x => `${x.count} ${x.type}`).join(", "))}
        {specItem(<MeetingRoomIcon/>, `${props.info.bedrooms} bedroom${props.info.bedrooms > 1 ? "s" : ""}`)}
        {specItem(<ShowerIcon/>, `${props.info.bathrooms} bathroom${props.info.bathrooms > 1 ? 's' : ""}`)}
        {specItem(<PetsIcon/>, `${props.info.petMax} pet${props.info.petMax > 1 ? "s" : ""} max`)}
    </Grid>;

    let roomSpecsItem = (index: number, room: string, sleeps: number, beds: { occupancy: number, type: string }[]) => <Grid key={index} item xs={12} sm={12} md={6} lg={4} sx={{ p: { xs: "2px", sm: 1, md: 1 } }}>
        <Card sx={{ p: 1, height: { xs: "auto", sm: "auto", md: "100%" } }}>
            <Typography variant={"h6"} sx={{ fontSize: { xs: "14px", sm: "16px" } }}>{room}</Typography>
            <Typography variant={"h6"} sx={{ fontSize: { xs: "12px", sm: "14x" }, pb: 1 }}>sleeps {sleeps}</Typography>
            <Divider sx={{ mb: 1 }}/>
            <Stack>
                { beds.map((x, index) => <Box key={index}>{x.type} ({x.occupancy} { x.occupancy > 1 ? "people" : "person"})</Box>)}
            </Stack>
        </Card>
    </Grid>;

    let roomSpecs = <Grid container spacing={2}>
        {props.info.rooms.map((x, index) => roomSpecsItem(index, x.name, x.sleeps,x.rooms.map(r => { return { occupancy: r.count, type: r.type }; })))}
    </Grid>;

    let titleSleepingArrangements = <Typography variant={"h6"} sx={{ fontSize: { xs: "14px", sm: "16px" } }}>Sleeping Arrangements</Typography>

    let titleDescription = <Typography variant={"h6"} sx={{ fontSize: { xs: "14px", sm: "18px" } }}></Typography>

    const rawContentState = JSON.parse(props.info.description);
    let htmlDetails = draftToHtml(
        rawContentState,
        {
            trigger: '#',
            separator: ' ',
        },
        true
    );
    const theObj = {__html:htmlDetails};

    let description = <Box sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
        <div dangerouslySetInnerHTML={theObj} />
    </Box>

    let amenitiesTitle = <Typography variant={"h6"} sx={{ fontSize: { xs: "14px", sm: "16px" } }}>AMENITIES</Typography>
    let houseRulesTitle = <Typography variant={"h6"} sx={{ fontSize: { xs: "14px", sm: "16px" } }}>HOUSE RULES</Typography>
    let policiesTitle = <Typography variant={"h6"} sx={{ fontSize: { xs: "14px", sm: "16px" } }}>POLICIES</Typography>

    let amenitiesItem = (text: string) => <Grid item key={text} xs={12} sm={12} md={6} lg={4} sx={{ p: "2px" }}>
        <Stack direction={"row"} spacing={1}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>{text}</Box>
        </Stack>
    </Grid>;

    let amenities = <Grid container>
        {props.info.amenities?.map(x => amenitiesItem(x))}
    </Grid>;

    let mapMobile = <iframe width="100%" height="200px" style={{border: 0}} loading="lazy" allowFullScreen
                      src={`https://www.google.com/maps/embed/v1/place?key=${"AIzaSyD2jTzFsa8WordLMSc9J_dGuDRPz5f7GjM"}
    &q=${props.info.latitude},${props.info.longitude}&zoom=13`}></iframe>;

    let map = <iframe width="100%" height="500px" style={{border: 0}} loading="lazy" allowFullScreen
                            src={`https://www.google.com/maps/embed/v1/place?key=${"AIzaSyD2jTzFsa8WordLMSc9J_dGuDRPz5f7GjM"}
    &q=${props.info.latitude},${props.info.longitude}&zoom=13`}></iframe>;

    let houseRules = <Grid container spacing={2}>
        <Grid item xs={12} md={6}><Typography variant={"subtitle1"} sx={{ fontSize: { xs: "12px", sm: "14px" } }}>{props.info.partiesAllowed ? "Parties Allowed" : "Parties - NOT ALLOWED"}</Typography></Grid>
        <Grid item xs={12} md={6}><Typography variant={"subtitle1"} sx={{ fontSize: { xs: "12px", sm: "14px" } }}>{props.info.smokingAllowed ? "Smoking Allowed" : "Smoking - NOT ALLOWED"}</Typography></Grid>
        {props.info.quietHoursEnabled && <Grid item xs={12}><Typography variant={"subtitle1"} sx={{ fontSize: { xs: "12px", sm: "14px" } }}>Quiet hours are from {StringHelper.format24Hours(props.info.quietHoursStartTime)} to {StringHelper.format24Hours(props.info.quietHoursEndTime)}</Typography></Grid>}
        {props.info.houseRules?.trim().length > 0 && <Grid item xs={12}><Box sx={{ fontSize: { xs: "12px", sm: "14px", whiteSpace: 'pre-line' } }}>{props.info.houseRules}</Box></Grid>}
    </Grid>;

    let policies = <Grid container spacing={2}>
        {props.info.policies?.map((x, index) => <Grid item key={index} xs={12}><Typography variant={"subtitle1"} sx={{ fontSize: { xs: "12px", sm: "14px" } }}>{x}</Typography></Grid>)}
    </Grid>;

    let imageGallery = <ImageGallery additionalClass={"image-gallery-custom"} items={props.info.images?.map(x => {
        return {
            original: `/mainController/Search/GetFile?path=${x.url}`,
            thumbnail: `/mainController/Search/GetFile?path=${x.thumbnailUrl}`
        };
    })}/>;

    return (<Grid container>
                <Grid item xs={12} sx={{ display: { xs: "block", md: "none" }, p: 1 }}>
                    <Grid container>
                        {props.backButton && <Grid item xs={12}>{props.backButton}</Grid>}
                        <Grid item xs={12}>{imageGallery}</Grid>
                        {props.additionalBlock && <Grid item xs={12}>{props.additionalBlock}</Grid>}
                        <Grid item xs={12}><Divider sx={{ pt:3, mb:1 }}/></Grid>
                        <Grid item xs={12}>{navigation}</Grid>
                        <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>{titleMobile}</Grid>
                        <Grid item xs={12}>{specs}</Grid>
                        <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>{titleSleepingArrangements}</Grid>
                        <Grid item xs={12}>{roomSpecs}</Grid>
                        <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>{titleDescription}</Grid>
                        <Grid item xs={12}>{description}</Grid>
                        <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>{houseRulesTitle}</Grid>
                        <Grid item xs={12}>{houseRules}</Grid>
                        {props.info.policies?.length > 0 && <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>{policiesTitle}</Grid>}
                        <Grid item xs={12}>{policies}</Grid>
                        <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>{amenitiesTitle}</Grid>
                        <Grid item xs={12}>{amenities}</Grid>
                        <Grid item xs={12} sx={{ pt: 2 }}>{mapMobile}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: "none", md: "block" }, pl: 2, pr: 2 }}>
                    <Grid container>
                        <Grid item xs={0} sm={1} md={2} lg={3} xl={4}></Grid>
                        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                            <Grid container>
                                {props.backButton && <Grid item xs={12}>{props.backButton}</Grid>}
                                <Grid item xs={12}>{imageGallery}</Grid>
                                {props.additionalBlock && <Grid item xs={12}>{props.additionalBlock}</Grid>}
                                <Grid item xs={12}><Divider sx={{ pt:3, mb:1 }}/></Grid>
                                <Grid item xs={12}>{navigation}</Grid>
                                <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>{title}</Grid>
                                <Grid item xs={12}>{specs}</Grid>
                                <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>{titleSleepingArrangements}</Grid>
                                <Grid item xs={12}>{roomSpecs}</Grid>
                                <Grid item xs={12} sx={{ pt: 4, pb: 2 }}>{titleDescription}</Grid>
                                <Grid item xs={12}>{description}</Grid>
                                <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>{houseRulesTitle}</Grid>
                                <Grid item xs={12}>{houseRules}</Grid>
                                {props.info.policies?.length > 0 && <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>{policiesTitle}</Grid>}
                                <Grid item xs={12}>{policies}</Grid>
                                <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>{amenitiesTitle}</Grid>
                                <Grid item xs={12}>{amenities}</Grid>
                                <Grid item xs={12} sx={{ pt: 3 }}>{map}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={0} sm={1} md={2} lg={3} xl={4}></Grid>
                    </Grid>
                </Grid>
        </Grid>);
});

