import {observer} from "mobx-react";
import {Button, Grid, Stack, Typography} from "@mui/material";
import React from "react";
import Details from "app/modules/main/components/main/book/details";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {useNavigate} from "react-router-dom";
import {blue} from "@mui/material/colors";
import {BookingInfo} from "app/modules/main/api/main-clients.api";

export default observer((props: { confirmationCode: string, bookInfo: BookingInfo }) => {
    const navigation = useNavigate();

    let goToMainPageButton = <Button variant={"contained"}
                                     color={"warning"}
                                     sx={{ fontSize: "12px", width: { xs: "100%", sm: "100%", md: "30%" }, borderRadius: 0, height: "54px" }}
                                     onClick={() => navigation("/")}>GO TO MAIN PAGE</Button>;

    return (<Grid container>
        <Grid item xs={12} sx={{ display: { xs: "block", md: "none" }, p: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}><Details bookInfo={props.bookInfo}/></Grid>
                <Grid item xs={12}>{goToMainPageButton}</Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "block" }, pl: 2, pr: 2 }}>
            <Grid container spacing={1}>
                <Grid item xs={0} sm={1} md={1} lg={2} xl={4}></Grid>
                <Grid item xs={12} sm={10} md={10} lg={8} xl={4}>
                    <Stack direction={"column"}>
                        <Details bookInfo={props.bookInfo}/>
                        <BoxCenter>
                            <Typography variant={"body2"} sx={{ fontSize: "12px", fontStyle: "italic" }}>confirmation number:</Typography>
                        </BoxCenter>
                        <BoxCenter>
                            <Typography variant={"body2"} color={blue[700]} sx={{ fontSize: "20px", pb: 2 }}><b>{props.confirmationCode}</b></Typography>
                        </BoxCenter>
                        <BoxCenter>
                            {goToMainPageButton}
                        </BoxCenter>
                    </Stack>
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={2} xl={4}></Grid>
            </Grid>
        </Grid>
    </Grid>);
});
