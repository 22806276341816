import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {action, makeObservable, observable} from "mobx";
import {ReservationAccountModel} from "app/modules/main/api/main-clients.api";

export class DetailsStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    details: ReservationAccountModel = null;

    constructor() {
        super();

        makeObservable(this, {
            details: observable,

            init: action
        });
    }

    init = async (reservationCode) => {
        await this.wrapInitLoadingApiCall(async () => {
            [this.details] = [
                await this.apiClients.propertyMainClient.getReservationAccountDetails(reservationCode)
            ];
        });
    }
}
