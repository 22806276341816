import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {
    DataListGetParamsOfPropertyListFilters,
    PropertyListFilters,
    PropertyListModel
} from "app/modules/main/api/admin-clients.api";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";

export class ListStore extends BaseListStore<PropertyListModel>{
    @inject apiClients: ApiClients;
    @inject adminStore: AdminStore;

    selectedBook = null;

    constructor() {
        super();

        makeObservable(this, {
            selectedBook: observable,

            init: action,
            selectBook: action
        });
    }

    applySpecificFilters() {
    }

    selectBook = (bookId) => {
        this.selectedBook = bookId;
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new PropertyListFilters({ });
            const [result] = await Promise.all([
                this.apiClients.adminPropertyClient.getList(new DataListGetParamsOfPropertyListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    async init() {
        this.adminStore.selectMenu(AdminMenu.Properties);

        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
