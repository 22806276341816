import {
    PaymentElement,
    LinkAuthenticationElement
} from '@stripe/react-stripe-js'
import {useState} from 'react'
import {useStripe, useElements} from '@stripe/react-stripe-js';
import React from "react";
import {useInstance} from "react-ioc";
import {BookStore} from "app/modules/main/components/main/book/BookStore";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/main/stores/MainStore";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {Typography} from "@mui/material";
import {red} from "@mui/material/colors";

export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const bookStore = useInstance(BookStore);
    const mainStore = useInstance(MainStore);

    let bookButton = <LoadingButton variant={"contained"}
                             color={"warning"}
                             loading={isLoading}
                             sx={{ fontSize: "12px", width: { xs: "100%", sm: "100%", md: "30%" }, marginLeft: { xs: "0", sm: "0", md: "70%" }, borderRadius: 0, height: "54px" }}
                             disabled={isLoading || !stripe || !elements}
                             type={"submit"}>PAY</LoadingButton>;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        try {
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/book/${bookStore.confirmationCode}/completion`,
                },
            });

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occured.");
            }
        }
        catch (ex) {
            setMessage("An unexpected error occured.");
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {/* Show any error or success messages */}
            {message && <BoxCenter>
                <Typography id="payment-message" sx={{ fontWeight: 'bold', color: red[800] }} variant="body1">{message}</Typography>
            </BoxCenter>}
            <LinkAuthenticationElement id="link-authentication-element"
                                       options={{ defaultValues: { email: mainStore.userData.email }}}
                // Access the email value like so:
                // onChange={(event) => {
                //  setEmail(event.value.email);
                // }}
                //
                // Prefill the email field like so:
                // options={{defaultValues: {email: 'foo@bar.com'}}}
            />
            <PaymentElement id="payment-element" />
            <br/>
            {bookButton}
            <br/>
        </form>
    )
}
