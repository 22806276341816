import {inject, provider} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import React, {Component} from "react";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/main/stores/MainStore";
import {LocalStorageService} from "app/modules/auth/components/stores/LocalStorageService";
import {observer} from "mobx-react";
import MenuAppBar from "app/modules/main/components/main/header";
import { Index as MarketingIndex } from "app/modules/main/components/marketing/index";
import { Index as AccountIndex } from "app/modules/main/components/account/index";
import AdminIndex from "app/modules/main/components/admin/index";
import Footer from "app/modules/main/components/main/footer";
import {Box} from "@mui/material";
import {ApiClients as ApiAuthClients} from "app/modules/auth/services/AxiosBaseClient";
import {Route, Routes} from "react-router-dom";
import { Index as SearchIndex } from "app/modules/main/components/main/search/index";
import DetailsIndex from "app/modules/main/components/main/search/details/index";
import {SearchStore} from "app/modules/main/components/main/search/SearchStore";
import { Index as BookIndex } from "app/modules/main/components/main/book/index";
import LinkIndex from "app/modules/main/components/main/link/index";
import BookPaymentCompleteIndex from "app/modules/main/components/main/book/payment-complete";
import {CurrentBookStore} from "app/modules/main/stores/CurrentBookStore";
import TermsIndex from "app/modules/common/terms/terms";
import PoliciesIndex from "app/modules/common/terms/policies";
import SecurityIndex from "app/modules/common/terms/security";
import {ApiClientConfig} from "app/modules/main/api/ApiClientConfig";

@provider(ApiClients, ApiAuthClients, MainStore, LocalStorageService, SearchStore, CurrentBookStore, ApiClientConfig)
@observer
export class Index extends Component<any, any> {
    @inject mainStore: MainStore;

    render() {
        if(this.mainStore.isInitLoading) {
            return <Loading/>;
        }

        return <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
            <MenuAppBar/>
            <Box sx={{ height: "calc(100vh - 110px)", overflowX: "hidden", position: "relative" }}>
                <Routes>
                    <Route path="/admin/*" element={<AdminIndex/>} />
                    <Route path="/account/*" element={<AccountIndex/>} />
                    <Route path="/search" element={<SearchIndex/>} />
                    <Route path="/search/:id" element={<DetailsIndex/>} />
                    <Route path="/link/:code" element={<LinkIndex/>} />
                    <Route path="/book" element={<BookIndex/>} />
                    <Route path="/book/:code/completion" element={<BookPaymentCompleteIndex/>} />
                    <Route path="/terms" element={<TermsIndex/>} />
                    <Route path="/policies" element={<PoliciesIndex/>} />
                    <Route path="/security" element={<SecurityIndex/>} />
                    <Route path="/" element={<MarketingIndex/>} />
                </Routes>
            </Box>
            <Footer/>
        </Box>;
    }

    async componentDidMount() {
        await this.mainStore.init();
    }
}
