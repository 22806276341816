import {observer} from "mobx-react";
import {Box, Grid, IconButton, Modal} from "@mui/material";
import DetailsIndex from "app/modules/main/components/admin/books/details/index";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

export default observer((props: { close: () => void, bookId: number }) => {
    return <Modal
        open={true}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={{ backgroundColor: "white", height: "100%", overflowY: "auto" }}>
            <Grid container sx={{ position: "absolute", top: "10px", right: "30px" }}>
                <Grid item xs={12} textAlign={"right"}>
                    <IconButton aria-label="delete" size="large" onClick={props.close}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Grid>
            </Grid>
            <DetailsIndex bookId={props.bookId}/>
        </Box>
    </Modal>;
});
