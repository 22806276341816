export class IntercomeService {
    sendEvent = (eventName: string, metadata?: object) => {
        (window as any).Intercom('trackEvent', eventName, metadata);
    }

    changeLocation = (path: string) => {
        (window as any).Intercom("update", {last_request_at: Math.round((new Date().getTime())/1000)});
    }

    login = () => {
        this.sendEvent("login");
    }

    register = () => {
        this.sendEvent("register");
    }

    forgotPassword = () => {
        this.sendEvent("forgot-password");
    }

    resetPasswordPassword = () => {
        this.sendEvent("reset-password");
    }

    searchProperties = (city: string, state: string) => {
        this.sendEvent("search", {
            city: city,
            state: state
        });
    }

    marketingLink = (code: string) => {
        this.sendEvent("marketing-link", {
            code: code
        });
    }

    lookAtProperty = (city: string, state: string, propertyId: number) => {
        this.sendEvent("property-details", {
            city: city,
            state: state,
            propertyId: propertyId
        });
    }

    startBookingProperty = (propertyId: number) => {
        this.sendEvent("property-start-booking", {
            propertyId: propertyId
        });
    }

    paymentIntentBookingProperty = (id: number, reservationCode: string) => {
        this.sendEvent("property-payment-intent", {
            reservationCode: reservationCode,
            propertyId: id
        });
    }

    paymentMadeProperty = (id: number, reservationCode: string) => {
        this.sendEvent("property-payment-made", {
            reservationCode: reservationCode,
            propertyId: id
        });
    }
}
