import {action, makeObservable, observable, runInAction} from "mobx";
import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {FieldState, FormState} from "formstate";
import {int, int24hours, long, required, requiredNumber} from "app/modules/common/form/validators";
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {
    BookRatesDetails,
    IntegrationModel,
    IntegrationType, ListingListModel,
    PolicyShortListModel,
    PropertyAdditionalImage,
    PropertyAdditionalImageType,
    PropertyContactShortListModel,
    PropertyDetails,
    PropertyEdit,
    PropertyImage,
    PropertyIntegrationModel,
    PropertyRateExtraGuest,
    PropertyRateGeneral,
    PropertyRateGeneralPerNight,
    PropertyRateOnSubTotal,
    PropertyRatePet,
    PropertyRateStayDiscount,
    PropertyRateTaxes,
    PropertyRateType,
    PropertyRoomType,
    PropertySleepingArrangement,
    PropertySleepingArrangementBed,
    PropertySleepingArrangementBedType,
    PropertyStatus,
    RateEditModel,
    RateListModel
} from "app/modules/main/api/admin-clients.api";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {PolicySelectorResult} from "app/modules/main/components/admin/properties/details/dialog.policy.selector";

export enum PropertyTabType {
    Basic = 1,
    Rates = 2,
    Integrations = 3
}

export class DetailsStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;
    @inject dialogStore: DialogStore;

    propertyId?: number = null;
    name?: string = null;
    fileLoading: boolean = false;
    fileError: string = null;
    images: PropertyImage[] = [];
    additionalImages: PropertyAdditionalImage[] = [];
    contacts: PropertyContactShortListModel[] = [];
    integrations: IntegrationModel[] = [];
    hostawayListingList: ListingListModel[] = [];

    previewRateData: BookRatesDetails = null;

    activeTab: PropertyTabType = PropertyTabType.Basic;

    policies: PolicyShortListModel[] = [];

    formRatePreviewState = new FormState({
        nights: new FieldState(5).validators(requiredNumber),
        guests: new FieldState(6).validators(requiredNumber),
        pets: new FieldState(3).validators(requiredNumber),
        promoCode: new FieldState<string>(null)
    });

    formHostToolsWebHookState = new FormState({
        integration: new FieldState<number>(null),
        airbnb: new FieldState<string>(null),
        booking: new FieldState<string>(null)
    });

    formHostToolsSyncState = new FormState({
        integration: new FieldState<number>(null),
        path: new FieldState<string>(null).validators($ => this.formHostToolsSyncState.$.integration.value > 0 && !$?.length && "Required")
    });

    formHostwaySyncState = new FormState({
        integration: new FieldState<number>(null),
        listingId: new FieldState<number>(0).validators($ => this.formHostwaySyncState.$.integration.value > 0 && !($ > 0) && "Required")
    });

    formState = new FormState({
        name: new FieldState('').validators(required),
        internalName: new FieldState('').validators(required),
        state: new FieldState(null).validators(required),
        city: new FieldState('').validators(required),
        address: new FieldState('').validators(required),
        zip: new FieldState('').validators(required),
        editor: new FieldState(EditorState.createEmpty()),
        petMax: new FieldState(0).validators(requiredNumber),
        guestsMax: new FieldState(0).validators(requiredNumber),
        bathrooms: new FieldState(0).validators(requiredNumber),
        amenities: new FieldState<number[]>([]),
        defaultRate: new FieldState(0).validators(requiredNumber),
        type: new FieldState(null).validators(required),
        sleepingArrangements: new FormState([]),
        thumbnailImageId: new FieldState(null),
        status: new FieldState<PropertyStatus>(PropertyStatus.Unlisted),
        minNights: new FieldState(1).validators(requiredNumber),
        maxNights: new FieldState<number>(null),
        latitude: new FieldState<number>(null).validators(requiredNumber),
        longitude: new FieldState<number>(null).validators(requiredNumber),

        showAddressBeforeDays: new FieldState<number>(3).validators(int),

        partiesAllowed: new FieldState<boolean>(false).validators(required),
        smokingAllowed: new FieldState<boolean>(false).validators(required),
        quietHoursEnabled: new FieldState<boolean>(false).validators(required),
        quietHoursStartTime: new FieldState<number>(null).validators(int24hours),
        quietHoursEndTime: new FieldState<number>(null).validators(int24hours),

        contactId: new FieldState<number>(null).validators(required, long),
        houseRules: new FieldState<string>(null),
        checkInCheckOutInstructions: new FieldState<string>(null),
        isTest: new FieldState<boolean>(false).validators(required),
    });

    rates = new FormState({
        general: new FormState<FormState<any>[]>([]),
        generalPerNight: new FormState<FormState<any>[]>([]),
        guestPerNight: new FormState<FormState<any>[]>([]),
        onSubTotal: new FormState<FormState<any>[]>([]),
        petPerNight: new FormState<FormState<any>[]>([]),
        stayDiscount: new FormState<FormState<any>[]>([]),
        taxes: new FormState<FormState<any>[]>([])
    });

    constructor() {
        super();

        makeObservable(this, {
            propertyId: observable,
            fileLoading: observable,
            fileError: observable,
            images: observable,
            additionalImages: observable,
            policies: observable,
            contacts: observable,
            activeTab: observable,
            rates: observable,
            previewRateData: observable,
            integrations: observable,
            hostawayListingList: observable,

            init: action,
            save: action,
            amenitySelect: action,
            addRoom: action,
            setFileLoading: action,
            reloadImages: action,
            reloadAdditionalImages: action,
            addPolicy: action,
            removePolicy: action,
            onHostToolsSyncTypeChange: action
        });
    }

    changeTab = (type: PropertyTabType) => this.activeTab = type;

    setFileLoading = (loading: boolean) => this.fileLoading = loading;
    setFileError = (error: string) => this.fileError = error;

    addPolicy = (item: PolicySelectorResult) => {
        let found = this.policies.find(x => x.id == item.policyId);

        if(found == null) {
            this.policies.push(new PolicyShortListModel({
                id: item.policyId,
                name: item.name,
                text: item.text,
                type: item.type
            }))
        }
    }

    removePolicy = (policyId: number) => {
        let found = this.policies.find(x => x.id == policyId);
        this.policies.splice(this.policies.indexOf(found), 1);
    }

    onHostToolsSyncTypeChange = async () => {
        await this.wrapLoadingApiCall(async () => {
            if(!!this.formHostwaySyncState.$.integration.value) {
                this.hostawayListingList = await this.apiClients.adminPropertyClient.getHostawayListings(this.formHostwaySyncState.$.integration.value);
            } else {
                this.hostawayListingList = [];
            }
        })
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            let model = new PropertyEdit({
                address1: this.formState.$.address.value,
                address2: null,
                city: this.formState.$.city.value,
                state: this.formState.$.state.value,
                zipCode: this.formState.$.zip.value,
                bathrooms: this.formState.$.bathrooms.value,
                defaultRate: this.formState.$.defaultRate.value,
                description: JSON.stringify(convertToRaw(this.formState.$.editor.value.getCurrentContent())),
                id: this.propertyId,
                name: this.formState.$.name.value,
                internalName: this.formState.$.internalName.value,
                petMax: this.formState.$.petMax.value,
                maxGuests: this.formState.$.guestsMax.value,
                type: this.formState.$.type.value,
                amenities: this.formState.$.amenities.value,
                status: this.formState.$.status.value,
                thumbnailImageId: this.formState.$.thumbnailImageId.value,
                sleepingArrangements: this.formState.$.sleepingArrangements?.$?.map(x => new PropertySleepingArrangement({
                    type: x.$.type.value,
                    beds: x.$.beds?.$?.map(b => new PropertySleepingArrangementBed({
                        people: b.$.people.value,
                        type: b.$.type.value
                    }))
                })),
                minNights: this.formState.$.minNights.value,
                maxNights: this.formState.$.maxNights.value,
                policies: this.policies?.map(x => x.id) ?? [],
                latitude: this.formState.$.latitude.value,
                longitude: this.formState.$.longitude.value,
                showAddressBeforeDays: this.formState.$.showAddressBeforeDays.value,

                smokingAllowed: this.formState.$.smokingAllowed.value,
                partiesAllowed: this.formState.$.partiesAllowed.value,
                quietHoursEnabled: this.formState.$.quietHoursEnabled.value,
                quietHoursStartTime: this.formState.$.quietHoursStartTime.value,
                quietHoursEndTime: this.formState.$.quietHoursEndTime.value,
                contactId: this.formState.$.contactId.value,
                houseRules: this.formState.$.houseRules.value,
                checkInCheckOutInstructions: this.formState.$.checkInCheckOutInstructions.value,
                isTest: this.formState.$.isTest.value
            });

            if(this.propertyId > 0) {
                await this.apiClients.adminPropertyClient.upsertProperty(model);
            } else {
                this.propertyId = await this.apiClients.adminPropertyClient.upsertProperty(model);

                runInAction(() => {
                    this.isSavedSuccessfully = true;
                });
            }
        });
    }

    ifAmenitySelected = (id) => this.formState.$.amenities.value.indexOf(id) >= 0;

    amenitySelect = (id, checked) => {
        if(!checked) {
            this.formState.$.amenities.value.splice(this.formState.$.amenities.value.indexOf(id), 1);
        } else {
            this.formState.$.amenities.value.push(id);
        }
    }

    addRoom = () => {
        this.formState.$.sleepingArrangements.$.push(new FormState({
            type: new FieldState<PropertyRoomType>(PropertyRoomType.Bedroom).validators(required),
            beds: new FormState([]).validators(required)
        }));
    }

    removeRoom = (room) => {
        let found = this.formState.$.sleepingArrangements.$.indexOf(room);
        this.formState.$.sleepingArrangements.$.splice(found, 1);
    }

    addRoomBed = (room) => {
        room.$.beds.$.push(new FormState({
            type: new FieldState<PropertySleepingArrangementBedType>(PropertySleepingArrangementBedType.Queen).validators(required),
            people: new FieldState(0).validators(requiredNumber)
        }));
    }

    removeRoomBed = (room, bed) => {
        let found = room.$.beds.$.indexOf(bed);
        room.$.beds.$.splice(found, 1);
    }

    reloadImages = async () => {
        await this.wrapLoadingApiCall(async () => {
            let images = await this.apiClients.adminPropertyClient.getImages(this.propertyId);

            runInAction(() => {
                this.images = images;
            })
        });
    }

    reloadAdditionalImages = async () => {
        await this.wrapLoadingApiCall(async () => {
            let images = await this.apiClients.adminPropertyClient.getAdditionalImages(this.propertyId, PropertyAdditionalImageType.CheckInCheckOut);

            runInAction(() => {
                this.additionalImages = images;
            })
        });
    }

    makeThumbnail = async (imageId) => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.adminPropertyClient.makeThumbnail(this.propertyId, imageId);
            let images = await this.apiClients.adminPropertyClient.getImages(this.propertyId);

            runInAction(() => {
                this.images = images;
            })
        });
    }

    saveRate = async (type: PropertyRateType, item: FormState<any>) => {
        await item.validate();

        if(item.hasError) {
            return;
        }

        let data = null;

        switch (type) {
            case PropertyRateType.General:
            case PropertyRateType.GeneralPerNight:
                data = { Rate: +item.$.rate.value };
                break;
            case PropertyRateType.PetPerNight:
            case PropertyRateType.GuestPerNight:
                data = { IsPerNight: item.$.isPerNight.value, StartFrom: +item.$.startFrom.value, Rate: +item.$.rate.value };
                break;
            case PropertyRateType.StayDiscount:
                data = { StartFrom: +item.$.startFrom.value, Rate: +item.$.rate.value };
                break;
            case PropertyRateType.OnSubTotal:
                data = { IsTaxable: item.$.isTaxable.value, Rate: +item.$.rate.value };
                break;
            case PropertyRateType.Taxes:
                data = { Rate: +item.$.rate.value };
                break;
        }

        await this.wrapLoadingApiCall(async () => {
            let id = await this.apiClients.adminPropertyClient.upsertPropertyRate(this.propertyId, new RateEditModel({
                id: item.$.id.value,
                name: item.$.name.value,
                type: type,
                rate: JSON.stringify(data)
            }));

            item.$.id.value = id;
        });
    }

    deleteRate = async (array: any[], item: FormState<any>) => {
        if(item.$.id.value > 0) {
            await this.apiClients.adminPropertyClient.deletePropertyRate(this.propertyId, item.$.id.value);
        }

        let index = array.indexOf(item);

        if(index >= 0) {
            array.splice(index, 1);
        }
    }

    addNewRate = async (type: PropertyRateType) => {
        switch (type) {
            case PropertyRateType.General:
                this.rates.$.general.$.push(new FormState({
                    id: new FieldState<number>(null),
                    name: new FieldState<string>('').validators(required),
                    rate: new FieldState<number>(null).validators(required, int) }));
                break;
            case PropertyRateType.GeneralPerNight:
                this.rates.$.generalPerNight.$.push(new FormState({
                    id: new FieldState<number>(null),
                    name: new FieldState<string>('').validators(required),
                    rate: new FieldState<number>(null).validators(required, int) }));
                break;
            case PropertyRateType.GuestPerNight:
                this.rates.$.guestPerNight.$.push(new FormState({
                    id: new FieldState<number>(null),
                    name: new FieldState<string>('').validators(required),
                    isPerNight: new FieldState<boolean>(false).validators(required),
                    startFrom: new FieldState<number>(1).validators(required, int),
                    rate: new FieldState<number>(null).validators(required, int) }));
                break;
            case PropertyRateType.StayDiscount:
                this.rates.$.stayDiscount.$.push(new FormState({
                    id: new FieldState<number>(null),
                    name: new FieldState<string>('').validators(required),
                    startFrom: new FieldState<number>(1).validators(required, int),
                    rate: new FieldState<number>(null).validators(required, int) }));
                break;
            case PropertyRateType.PetPerNight:
                this.rates.$.petPerNight.$.push(new FormState({
                    id: new FieldState<number>(null),
                    name: new FieldState<string>('').validators(required),
                    isPerNight: new FieldState<boolean>(false).validators(required),
                    startFrom: new FieldState<number>(1).validators(required, int),
                    rate: new FieldState<number>(null).validators(required, int) }));
                break;
            case PropertyRateType.OnSubTotal:
                this.rates.$.onSubTotal.$.push(new FormState({
                    id: new FieldState<number>(null),
                    name: new FieldState<string>('').validators(required),
                    isTaxable: new FieldState<boolean>(false).validators(required),
                    rate: new FieldState<number>(null).validators(required, int) }));
                break;
            case PropertyRateType.Taxes:
                this.rates.$.taxes.$.push(new FormState({
                    id: new FieldState<number>(null),
                    name: new FieldState<string>('').validators(required),
                    rate: new FieldState<number>(null).validators(required, int) }));
                break;
        }
    }

    previewRate = async () => {
        await this.formRatePreviewState.validate();

        if(this.formRatePreviewState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            this.previewRateData = await this.apiClients.adminPropertyClient.getPreviewRates(
                this.propertyId,
                this.formRatePreviewState.$.nights.value,
                this.formRatePreviewState.$.guests.value,
                this.formRatePreviewState.$.pets.value,
                this.formRatePreviewState.$.promoCode.value,
            );
        });

    }

    setRates = async (rates: RateListModel[]) => {
        rates.filter(x => x.type == PropertyRateType.OnSubTotal)
            .forEach(x => {
                let rate = PropertyRateOnSubTotal.fromJS(JSON.parse(x.rate));
                this.rates.$.onSubTotal.$.push(new FormState({
                    id: new FieldState(x.id),
                    name: new FieldState(x.name).validators(required),
                    isTaxable: new FieldState(rate.isTaxable).validators(required),
                    rate: new FieldState(rate.rate).validators(required, int) }));
            });

        rates.filter(x => x.type == PropertyRateType.General)
            .map(x => {
                let rate = PropertyRateGeneral.fromJS(JSON.parse(x.rate));
                this.rates.$.general.$.push(new FormState({
                    id: new FieldState(x.id),
                    name: new FieldState(x.name).validators(required),
                    rate: new FieldState(rate.rate).validators(required, int) }));
            });

        rates.filter(x => x.type == PropertyRateType.GeneralPerNight)
            .map(x => {
                let rate = PropertyRateGeneralPerNight.fromJS(JSON.parse(x.rate));
                this.rates.$.generalPerNight.$.push(new FormState({
                    id: new FieldState(x.id),
                    name: new FieldState(x.name).validators(required),
                    rate: new FieldState(rate.rate).validators(required, int) }));
            });

        rates.filter(x => x.type == PropertyRateType.PetPerNight)
            .map(x => {
                let rate = PropertyRatePet.fromJS(JSON.parse(x.rate));
                this.rates.$.petPerNight.$.push(new FormState({
                    id: new FieldState(x.id),
                    name: new FieldState(x.name).validators(required),
                    isPerNight: new FieldState(rate.isPerNight).validators(required),
                    startFrom: new FieldState(rate.startFrom).validators(required, int),
                    rate: new FieldState(rate.rate).validators(required, int) }));
            });

        rates.filter(x => x.type == PropertyRateType.StayDiscount)
            .map(x => {
                let rate = PropertyRateStayDiscount.fromJS(JSON.parse(x.rate));
                this.rates.$.stayDiscount.$.push(new FormState({
                    id: new FieldState(x.id),
                    name: new FieldState(x.name).validators(required),
                    startFrom: new FieldState(rate.startFrom).validators(required, int),
                    rate: new FieldState(rate.rate).validators(required, int) }));
            });

        rates.filter(x => x.type == PropertyRateType.GuestPerNight)
            .map(x => {
                let rate = PropertyRateExtraGuest.fromJS(JSON.parse(x.rate));
                this.rates.$.guestPerNight.$.push(new FormState({
                    id: new FieldState(x.id),
                    name: new FieldState(x.name).validators(required),
                    isPerNight: new FieldState(rate.isPerNight).validators(required),
                    startFrom: new FieldState(rate.startFrom).validators(required, int),
                    rate: new FieldState(rate.rate).validators(required, int) }));
            });

        rates.filter(x => x.type == PropertyRateType.Taxes)
            .map(x => {
                let rate = PropertyRateTaxes.fromJS(JSON.parse(x.rate));
                this.rates.$.taxes.$.push(new FormState({
                    id: new FieldState(x.id),
                    name: new FieldState(x.name).validators(required),
                    rate: new FieldState(rate.rate).validators(required, int) }));
            });
    }

    saveIntegrations = async () => {
        await this.formHostToolsSyncState.validate();
        await this.formHostToolsWebHookState.validate();

        if(this.formHostToolsSyncState.hasError || this.formHostToolsWebHookState.hasError) {
            return;
        }

        let integrations : PropertyIntegrationModel[] = [];

        if(this.formHostToolsSyncState.$.integration.value > 0) {
            integrations.push(new PropertyIntegrationModel({
                integrationId: this.formHostToolsSyncState.$.integration.value,
                settings: this.formHostToolsSyncState.$.path.value
            }));
        }

        if(this.formHostwaySyncState.$.integration.value > 0) {
            integrations.push(new PropertyIntegrationModel({
                integrationId: this.formHostwaySyncState.$.integration.value,
                settings: this.formHostwaySyncState.$.listingId.value?.toString()
            }));
        }

        if(this.formHostToolsWebHookState.$.integration.value > 0) {
            if(this.formHostToolsWebHookState.$.airbnb.value?.length > 0) {
                integrations.push(new PropertyIntegrationModel({
                    integrationId: this.formHostToolsWebHookState.$.integration.value,
                    settings: 'Airbnb',
                    externalId: this.formHostToolsWebHookState.$.airbnb.value
                }));
            }

            if(this.formHostToolsWebHookState.$.booking.value?.length > 0) {
                integrations.push(new PropertyIntegrationModel({
                    integrationId: this.formHostToolsWebHookState.$.integration.value,
                    settings: 'Booking',
                    externalId: this.formHostToolsWebHookState.$.booking.value
                }));
            }
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.adminPropertyClient.updateIntegrations(this.propertyId, integrations);
        });
    }

    init = async (id?: number) => {
        this.propertyId = id;

        await this.wrapInitLoadingApiCall(async () => {
            if(this.propertyId > 0) {
                let result: PropertyDetails;
                let rates : RateListModel[];
                let propertyIntegration : PropertyIntegrationModel[];
                [result, this.images, this.additionalImages, this.contacts, rates, propertyIntegration, this.integrations] = [
                    await this.apiClients.adminPropertyClient.getProperty(this.propertyId),
                    await this.apiClients.adminPropertyClient.getImages(this.propertyId),
                    await this.apiClients.adminPropertyClient.getAdditionalImages(this.propertyId, PropertyAdditionalImageType.CheckInCheckOut),
                    await this.apiClients.propertyContactClient.getShortList(),
                    await this.apiClients.adminPropertyClient.getRateList(this.propertyId),
                    await this.apiClients.adminPropertyClient.getPropertyIntegrations(this.propertyId),
                    await this.apiClients.adminPropertyClient.getIntegrations(),
                ];

                runInAction(async () => {
                    this.name = result.name;

                    this.formState.$.address.value = result.address1;
                    this.formState.$.city.value = result.city;
                    this.formState.$.state.value = result.state;
                    this.formState.$.zip.value = result.zipCode;
                    this.formState.$.latitude.value = result.latitude;
                    this.formState.$.longitude.value = result.longitude;
                    this.formState.$.showAddressBeforeDays.value = result.showAddressBeforeDays;
                    this.formState.$.bathrooms.value = result.bathrooms;
                    this.formState.$.defaultRate.value = result.defaultRate;
                    this.formState.$.editor.value = EditorState.createWithContent(convertFromRaw(JSON.parse(result.description)));
                    this.formState.$.name.value = result.name;
                    this.formState.$.internalName.value = result.internalName;
                    this.formState.$.petMax.value = result.petMax;
                    this.formState.$.guestsMax.value = result.maxGuests;
                    this.formState.$.type.value = result.type;
                    this.formState.$.amenities.value = result.amenities;
                    this.formState.$.status.value = result.status;
                    this.formState.$.thumbnailImageId.value = result.thumbnailImageId;
                    this.policies = result.policies;

                    this.formState.$.maxNights.value = result.maxNights;
                    this.formState.$.minNights.value = result.minNights;

                    this.formState.$.quietHoursEnabled.value = result.quietHoursEnabled;
                    this.formState.$.quietHoursStartTime.value = result.quietHoursStartTime;
                    this.formState.$.quietHoursEndTime.value = result.quietHoursEndTime;
                    this.formState.$.partiesAllowed.value = result.partiesAllowed;
                    this.formState.$.smokingAllowed.value = result.smokingAllowed;
                    this.formState.$.contactId.value = result.contactId;
                    this.formState.$.checkInCheckOutInstructions.value = result.checkInCheckOutInstructions;
                    this.formState.$.houseRules.value = result.houseRules;
                    this.formState.$.isTest.value = result.isTest;

                    for(let i = 0; i < result.sleepingArrangements.length; i++) {
                        let room = result.sleepingArrangements[i];

                        let beds = [];
                        for(let j = 0; j < room.beds.length; j++) {
                            beds.push(new FormState({
                                type: new FieldState<PropertySleepingArrangementBedType>(room.beds[j].type).validators(required),
                                people: new FieldState(room.beds[j].people).validators(requiredNumber)
                            }));
                        }

                        this.formState.$.sleepingArrangements.$.push(new FormState({
                            type: new FieldState<PropertyRoomType>(room.type).validators(required),
                            beds: new FormState(beds).validators(required)
                        }));
                    }


                    let integrationIds = propertyIntegration.map(x => x.integrationId);

                    if(integrationIds.length > 0) {
                        let integrations = this.integrations.filter(x => integrationIds.indexOf(x.id) >= 0);

                        for(let i = 0; i < integrations.length; i++) {
                            let settings = propertyIntegration.filter(x => x.integrationId == integrations[i].id);

                            if(integrations[i].type == IntegrationType.HosttoolsApi) {
                                this.formHostToolsSyncState.$.integration.value = integrations[i].id;
                                this.formHostToolsSyncState.$.path.value = settings[0]?.settings;
                            }

                            if(integrations[i].type == IntegrationType.HosttoolsWebHook) {
                                this.formHostToolsWebHookState.$.integration.value = integrations[i].id;
                                this.formHostToolsWebHookState.$.booking.value = settings.find(x => x.settings == "Booking")?.externalId;
                                this.formHostToolsWebHookState.$.airbnb.value = settings.find(x => x.settings == "Airbnb")?.externalId;
                            }

                            if(integrations[i].type == IntegrationType.HostawayApi) {
                                this.formHostwaySyncState.$.integration.value = integrations[i].id;
                                await this.onHostToolsSyncTypeChange();
                                this.formHostwaySyncState.$.listingId.value = +settings[0]?.settings;
                            }
                        }
                    }

                    await this.setRates(rates);
                })
            } else {
                this.contacts = await this.apiClients.propertyContactClient.getShortList();
            }
        });
    }
}
