import React from "react";
import {observer} from "mobx-react";
import Timeline, {
    TodayMarker,
    CursorMarker,
    TimelineHeaders,
    SidebarHeader,
    DateHeader
} from 'react-calendar-timeline';
import moment from "moment";
import ModelDetailsIndex from "app/modules/main/components/admin/books/details/modal";
import ExternalModelDetailsIndex from "app/modules/main/components/admin/integration/reservation/external.modal";
import IntegrationModelDetailsIndex from "app/modules/main/components/admin/integration/reservation/integration.modal";
import {blue} from "@mui/material/colors";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {useInstance} from "react-ioc";
import {TimelineStore} from "app/modules/main/components/admin/integration/reservation/TimelineStore";
import {Box} from "@mui/material";

export default observer(() => {
    let store = useInstance(TimelineStore);

    return <Box className={"timeline"}>
        <br/>
        <br/>
        <Timeline
            groups={store.groups}
            items={store.groupItem}
            defaultTimeStart={moment().add(-3, 'day')}
            defaultTimeEnd={moment().add(1, 'month')}
            sidebarWidth={350}
            itemRenderer={({
                   item,
                   itemContext,
                   getItemProps,
                   getResizeProps
               }) => {
            const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
            const props = getItemProps(item.itemProps);
            const style = props.style;
            props.style = null;
            style.backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : "rgb(255, 193, 7)") : item.bgColor;
            return (
                <div {...props} style={style}>
                    {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

                    <div
                        className="rct-item-content"
                        style={{ maxHeight: `${itemContext.dimensions.height}` }}
                    >
                        {itemContext.title}
                    </div>

                    {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
                </div>
            )}

        }
        >
            <TodayMarker />
            <CursorMarker />
            <TimelineHeaders style={{ background: blue[50] }}>
                <SidebarHeader>
                    {({ getRootProps }) => {
                        return <div {...getRootProps()}><BoxCenter sx={{ pt: 2, fontWeight: "bold" }}>Property</BoxCenter></div>
                    }}
                </SidebarHeader>
                <DateHeader
                    unit="month"
                    labelFormat="MMMM, YYYY" />
                <DateHeader
                    unit="day"
                    labelFormat="DD"
                    intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
                        return <div {...getIntervalProps()}>
                            <BoxCenter sx={{ paddingTop: "4px" }}>{intervalContext.intervalText}</BoxCenter>
                        </div>
                    }}/>
            </TimelineHeaders>
        </Timeline>
        { store.selectedBook && <ModelDetailsIndex close={() => store.selectBook(null)} bookId={store.selectedBook.id}/>}
        { store.selectedExternalBook && <ExternalModelDetailsIndex close={() => store.selectBook(null)} book={store.selectedExternalBook} delete={() => store.deleteExternalBook()}/>}
        { store.selectedIntegrationExternalBook && <IntegrationModelDetailsIndex close={() => store.selectBook(null)} book={store.selectedIntegrationExternalBook} />}
    </Box>;
});
