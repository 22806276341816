export const NumberHelper = {
    roundDecimal: (value) => {
        return Math.floor(value * 100)/100;
    },
    formatUSDollar: (value: number) => USDollar.format(value),
    formatUSDollarNoDigits: (value: number) => USDollarNoDigits.format(value),
    numberFormat: (value: number) => value.toLocaleString(
        undefined,
        { minimumFractionDigits: 0 }
    )
}

let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

let USDollarNoDigits = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});
