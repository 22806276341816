import React, {useEffect, useRef} from "react";
import {Box, Button, Card, DialogActions, Divider, Fade, Grid, Link, Popper, Stack, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import {MobileDateRangePicker} from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import {DateRangePickerDay} from "@mui/x-date-pickers-pro/DateRangePickerDay";

import Details from "app/modules/main/components/common/details/index";
import {provider, useInstance} from "react-ioc";
import {DetailsStore} from "app/modules/main/components/main/search/details/DetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import queryString from 'query-string';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import {DateHelper} from "app/modules/main/components/common/DateHelper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {red} from "@mui/material/colors";
import { PickersActionBarProps } from "@mui/x-date-pickers-pro";

export default provider(DetailsStore)(observer(() => {
    const calendar = useRef(null);

    const navigate = useNavigate();
    const store = useInstance(DetailsStore);

    const [openFullScreenPricePopper, setFullScreenPricePopper] = React.useState(false);
    const [anchorElFullScreenPricePopper, setAnchorElFullScreenPricePopper] = React.useState<null | HTMLElement>(null);

    const urlParams = useParams();
    const { id } = urlParams;

    useEffect(
        () => {
            let propertyId = !!id && parseInt(id) || null;

            if(!(propertyId > 0)) {
                navigate("/search");
            }
            let queryParams = queryString.parse(location.search);

            store.init(
                !!id && parseInt(id) || null,
                queryParams.from?.length > 0 && parseInt(queryParams.from as string),
                queryParams.to?.length > 0 && parseInt(queryParams.to as string),
                queryParams.adults?.length > 0 && parseInt(queryParams.adults as string),
                queryParams.children?.length > 0 && parseInt(queryParams.children as string),
                queryParams.pets?.length > 0 && parseInt(queryParams.pets as string));
        },
        [id],
    );

    if(store.isInitLoading) {
        return <Loading />
    }

    let backButton = <Button variant={"text"} sx={{ fontSize: "12px" }} onClick={() => navigate("/search")}>{"<"} Back to search</Button>;
    let onBookClick = async () => {
        await store.book();
        navigate("/book");
    };

    let bookButton = <Grid container>
        <Grid item xs={12} md={7}>
            <MobileDateRangePicker
                disablePast
                ref={calendar}
                value={store.selectedDates as any}
                onAccept={(newValue) => {
                    store.setDates(newValue);
                    store.currentSelectedDates = [null, null];
                }}
                slotProps={{ textField: { sx: { width: "100%", pb: { xs: 1, sm: 1, md: 0 } } }, layout: { className: "DateRangePickerDetails" } }}
                slots={{ field: SingleInputDateRangeField,
                         day: (props) => {
                            const { day, ...other } = props;
                            let dayData = store.dates.get(DateHelper.convertDate(day));
                            return <Box>
                                <DateRangePickerDay {...other} day={day}>
                                    <Typography component={"div"} sx={{ fontSize: "15px", position: "absolute", top: other.disabled ? null : "4px", }}>{day.getDate()}</Typography>
                                    {!other.disabled && <Typography component={"div"} sx={{ fontSize: "8px", textAlign: "center", position: "absolute", top: "22px", left: "-1px", width: "100%", color: dayData.discountRate > 0 ? red[600] : null }}>
                                        { !dayData.discountRate && NumberHelper.formatUSDollarNoDigits(dayData.rate)}
                                        { dayData.discountRate > 0 && NumberHelper.formatUSDollarNoDigits(dayData.discountRate)}
                                    </Typography>}
                                </DateRangePickerDay>
                            </Box>;
                         },
                        actionBar: (props: PickersActionBarProps) => {
                            const { onAccept, onClear, onCancel, onSetToday, actions, ...other } = props;

                            if (actions == null || actions.length === 0) {
                                return null;
                            }

                            const buttons = actions?.map((actionType) => {
                                switch (actionType) {
                                    case 'cancel':
                                        return (
                                            <Button onClick={onCancel} key={actionType}>
                                                {'cancel'}
                                            </Button>
                                        );

                                    case 'accept':
                                        return (
                                            <Button onClick={onAccept} key={actionType}>
                                                {'ok'}
                                            </Button>
                                        );

                                    case 'today':
                                        return (
                                            <Button data-mui-test="today-action-button" onClick={onSetToday} key={actionType}>
                                                {'today'}
                                            </Button>
                                        );

                                    default:
                                        return null;
                                }
                            });

                            let clear = <Button data-mui-test="clear-action-button" onClick={() => (props as any).ownerState.onChange([null, null])} key={'clear'} color="error">
                                {'clear'}
                            </Button>;

                            return <DialogActions {...other}>{[clear].concat(buttons)}</DialogActions>;
                        }
                }}
                shouldDisableDate={(day: Date, position: 'start' | 'end') => {
                    return store.isDateDisabled(day);
                }}
                onChange={(value) => { store.currentSelectedDates = value; }}
            />
        </Grid>
        <Grid item xs={12} sx={{ display: { sm: "block", md: "none" }}}>
            <Button variant={"contained"} color={"warning"} sx={{ fontSize: "14px", width: "100%", borderRadius: 0, height: "54px" }} onClick={onBookClick}>{NumberHelper.formatUSDollar(store.rateDetails?.total ?? 0)} - BOOK</Button>
        </Grid>
        <Grid item sm={3} sx={{ pt: "12px", display: { xs: "none", sm: "none", md: "block" }}}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "right", mr: 2 }} onClick={(event: React.MouseEvent<HTMLElement>) => {
                setAnchorElFullScreenPricePopper(event.currentTarget);
                setFullScreenPricePopper((previousOpen) => !previousOpen);
            }}>
                <Typography variant={"h6"} sx={{ fontSize: "20px" }}>{NumberHelper.formatUSDollar(store.rateDetails?.total ?? 0)}</Typography>
                {store.rateDetails?.total > 0 && <Link sx={{ fontSize: "13px", cursor: "pointer" }}>Pricing details</Link>}
            </Box>
            {store.rateDetails && <Popper id={id} open={openFullScreenPricePopper} anchorEl={anchorElFullScreenPricePopper} transition placement={"bottom"}>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={(e) => { setFullScreenPricePopper(false); e.preventDefault(); }}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Card sx={{ p: 1, minWidth: "250px" }}>
                                <Stack direction={"column"} spacing={1}>
                                    <Stack direction={"row"}>
                                        <Typography component={"div"} sx={{ fontSize: "14px", width: "100%", fontWeight: "bold" }}>Rent ({store.rateDetails.dayRates.length} Night{store.rateDetails.dayRates.length > 1 ? "s" : ""})</Typography>
                                        <Typography component={"div"} sx={{ fontSize: "14px", fontWeight: "bold" }}>{NumberHelper.formatUSDollar(store.rateDetails.totalDayRent)}</Typography>
                                    </Stack>
                                    <Stack direction={"row"}>
                                        <Typography component={"div"} sx={{ fontSize: "14px", width: "100%", fontWeight: "bold" }}>Fees</Typography>
                                        <Typography component={"div"} sx={{ fontSize: "14px", fontWeight: "bold" }}>{NumberHelper.formatUSDollar(store.rateDetails.totalFees)}</Typography>
                                    </Stack>
                                    {store.rateDetails.fees.map((x, index) => <Stack direction={"row"} key={index} sx={{ pl: 2 }}>
                                        <Typography component={"div"} sx={{ fontSize: "13px", width: "100%" }}>{x.name}</Typography>
                                        <Typography component={"div"} sx={{ fontSize: "13px" }}>{NumberHelper.formatUSDollar(x.rate)}</Typography>
                                    </Stack>)}
                                    <Stack direction={"row"}>
                                        <Typography component={"div"} sx={{ fontSize: "14px", width: "100%", fontWeight: "bold" }}>Taxes</Typography>
                                        <Typography component={"div"} sx={{ fontSize: "14px", fontWeight: "bold" }}>{NumberHelper.formatUSDollar(store.rateDetails.totalTaxes)}</Typography>
                                    </Stack>
                                    {store.rateDetails.taxes.map((x, index) => <Stack direction={"row"} key={index} sx={{ pl: 2 }}>
                                        <Typography component={"div"} sx={{ fontSize: "13px", width: "100%" }}>{x.name}</Typography>
                                        <Typography component={"div"} sx={{ fontSize: "13px" }}>{NumberHelper.formatUSDollar(x.rate)}</Typography>
                                    </Stack>)}
                                    <Divider/>
                                    <Stack direction={"row"}>
                                        <Typography component={"div"} sx={{ fontSize: "14px", width: "100%", fontWeight: "bold" }}>TOTAL</Typography>
                                        <Typography component={"div"} sx={{ fontSize: "14px", fontWeight: "bold" }}>{NumberHelper.formatUSDollar(store.rateDetails.total)}</Typography>
                                    </Stack>
                                </Stack>

                            </Card>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>}
        </Grid>
        <Grid item xs={2} sx={{ display: { xs: "none", sm: "none", md: "block" }}}>
            <Button variant={"contained"} color={"warning"} sx={{ fontSize: "12px", width: "100%", borderRadius: 0, height: "54px" }} onClick={onBookClick}>BOOK</Button>
        </Grid>
    </Grid>;

    return (<Details info={store.propertyDetails} additionalBlock={bookButton} backButton={backButton} />);
}));

