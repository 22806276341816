import {BaseLoadingStore} from "app/modules/main/stores/BaseLoadingStore";
import {action, makeObservable, observable} from "mobx";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {
    BookRatesDetails,
    CalendarDayPublic,
    PropertyCalendarItemStatus,
    PropertyPublicDetails
} from "app/modules/main/api/main-clients.api";
import {DateHelper} from "app/modules/main/components/common/DateHelper";
import {CurrentBookStore} from "app/modules/main/stores/CurrentBookStore";
import {IGuests} from "app/modules/main/components/main/search/SearchStore";
import {AnalyticsService} from "app/modules/main/stores/AnalyticsService";
//import moment from "moment";

export class DetailsStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;
    @inject currentBookStore: CurrentBookStore;
    @inject analyticsService: AnalyticsService;

    propertyId: number = null;
    propertyDetails: PropertyPublicDetails = null;
    selectedDates = [null, null];
    currentSelectedDates = [null, null];
    rateDetails: BookRatesDetails = null;
    dates: Map<number, CalendarDayPublic> = new Map<number, CalendarDayPublic>();
    guests: IGuests = {
        adults: 1,
        children: 0,
        pets: 0
    }

    constructor() {
        super();

        makeObservable(this, {
            propertyDetails: observable,
            selectedDates: observable,
            dates: observable,
            rateDetails: observable,
            currentSelectedDates: observable,

            init: action,
            setDates: action
        });
    }

    setDates = async (dates) => {
        this.selectedDates = dates;

        if(!this.selectedDates[0] || !this.selectedDates[1]) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            this.rateDetails = await this.apiClients.propertyMainClient.getRatesByDateRange(this.propertyId, DateHelper.convertDate(this.selectedDates[0]), DateHelper.convertDate(this.selectedDates[1]), this.guests.adults + this.guests.children ?? 0, this.guests.pets ?? 0)
        });
    };

    init = async (propertyId?: number, from?: number, to?: number, adults?: number, children?: number, pets?: number) => {
        this.propertyId = propertyId;

        this.guests.adults = adults > 0 ? adults : 1;
        this.guests.children = children > 0 ? children : 0;
        this.guests.pets = pets > 0 ? pets : 0;

        await this.wrapInitLoadingApiCall(async () => {
            const fromDate = from > 0 ? DateHelper.convertDateObj(from) : null;
            const toDate = to > 0 ? DateHelper.convertDateObj(to) : null;

            let dates: CalendarDayPublic[];
            [this.propertyDetails, dates] = [
                await this.apiClients.propertyMainClient.getPublicDetails(propertyId),
                await this.apiClients.propertyMainClient.getDates(propertyId)
            ];

            this.dates.clear();

            for (let i = 0; i < dates.length; i++) {
                this.dates.set(dates[i].date, dates[i]);
            }

            this.setDates([fromDate, toDate]);

            this.analyticsService.lookAtProperty(this.propertyDetails.city, this.propertyDetails.state, this.propertyId);
        });
    }

    isDateDisabled = (date) => {
        let existDate = this.dates.get(DateHelper.convertDate(date));
        let isDisabled = !existDate || existDate.status != PropertyCalendarItemStatus.Available;

        /*if(this.currentSelectedDates[0] != null && this.currentSelectedDates[1] == null) {
            let a = moment(this.currentSelectedDates[0]);
            let b = moment(date);
            let diff = a.diff(b, 'days')   // =1

            //let maxNights = this.propertyDetails.maxNights;
            let minNights = this.propertyDetails.minNights;

            if(minNights > 1) {
                isDisabled = isDisabled || diff < minNights;
            }
        }*/

        return isDisabled;
    }

    book = async () => {
        await this.currentBookStore.setBookInfo(this.propertyId, DateHelper.convertDate(this.selectedDates[0]), DateHelper.convertDate(this.selectedDates[1]), this.guests.adults, this.guests.children,this.guests.pets);
    }
}
