import {Validator} from "formstate";
import {EditorState} from "draft-js";
import * as moment from "moment";

export const required: Validator<string | null | undefined | any> = (value) => {
    if (value == null || value == undefined || (value.trim && !value.trim())) {
        return "Value Required";
    }
    return null;
}

export const requiredMinLength = (minLength: number) : Validator<string | null | undefined> => (value) => {
    if (value?.length < minLength) {
        return "Min length is " + minLength;
    }
    return null;
}

export const requiredMaxLength = (maxLength: number, message: string) : Validator<string | null | undefined> => (value) => {
    if (value?.length > maxLength) {
        return message;
    }
    return null;
}

export const requiredEditor: Validator<EditorState> = (value) => {
    if (!value.getCurrentContent().hasText()) {
        return "Value Required";
    }
    return null;
}

export const requiredNumber: Validator<number | null | undefined> = (value) => {
    if (!value) {
        return "Value Required";
    }
    return null;
}

export const requiredDate: Validator<moment.Moment | null | undefined> = (value) => {
    if (!value) {
        return "Value Required";
    }
    return null;
}

export const int: Validator<number | null | undefined> = (value) => {
    if (value == null || value == undefined && value > 2147483647) {
        return "Value is too big";
    }
    return null;
}

export const int24hours: Validator<number | null | undefined> = (value) => {
    if (value != null && value != undefined && (value > 23 || value < 0)) {
        return "Value is wrong";
    }
    return null;
}

export const int24hoursRequired: Validator<number | null | undefined> = (value) => {
    if (value == null || value == undefined && value > 23) {
        return "Value is wrong";
    }
    return null;
}

export const int100Required: Validator<number | null | undefined> = (value) => {
    if (value == null || value == undefined && (value > 100 || value < 0)) {
        return "Value is wrong";
    }
    return null;
}

export const long: Validator<number | null | undefined> = (value) => {
    if (value != null && value != undefined && (value < -9223372036854775808 || value > 9223372036854775807)) {
        return "Value is wrong";
    }
    return null;
}

export const email: Validator<string | null | undefined> = (value) => {
    // Empty values are not invalid emails
    if (required(value)) return null;
    value = value.trim();
    // Src : http://emailregex.com/
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.exec(value)) {
        return "Not a valid email address";
    }
    return null;
}
