import Moment from "moment";

export const DateHelper = {
    convertDate: (date) => date.getFullYear()*10000 + (date.getMonth()+1)*100 + date.getDate(),
    convertDateObj: (date) => new Date(Math.floor(date / 10000), Math.floor(date % 10000/100) - 1, Math.floor(date % 100)),
    formatMomentDate(date: Moment.Moment) {
        return date ? date.format("MM/DD/YYYY") : 'N/A'
    },
    formatMomentDateFull(date: Moment.Moment) {
        return date ? date.format("MM/DD/YYYY HH:mmA") : 'N/A'
    },
}
