import {inject} from "react-ioc";
import {GAnalyticsService} from "app/modules/main/stores/GAnalyticsService";
import {IntercomeService} from "app/modules/main/stores/IntercomeService";

export class AnalyticsService {
    @inject gAnalyticsService : GAnalyticsService;
    @inject intercomeService: IntercomeService;

    changeLocation = (path: string) => {
        this.gAnalyticsService.changeLocation(path);
        this.intercomeService.changeLocation(path);
    }

    login = () => {
        this.gAnalyticsService.login();
        this.intercomeService.login();
    }

    register = () => {
        this.gAnalyticsService.register();
        this.intercomeService.register();
    }

    forgotPassword = () => {
        this.gAnalyticsService.forgotPassword();
        this.intercomeService.forgotPassword();
    }

    resetPasswordPassword = () => {
        this.gAnalyticsService.resetPasswordPassword();
        this.intercomeService.resetPasswordPassword();
    }

    marketingLink = (code) => {
        this.gAnalyticsService.marketingLink(code);
        this.intercomeService.marketingLink(code);
    }

    searchProperty = (city: string, state: string) => {
        this.gAnalyticsService.searchProperties(city, state);
        this.intercomeService.searchProperties(city, state);
    }

    lookAtProperty = (city: string, state: string, propertyId: number) => {
        this.gAnalyticsService.lookAtProperty(city, state, propertyId);
        this.intercomeService.lookAtProperty(city, state, propertyId);
    }

    startBookingProperty = (propertyId: number) => {
        this.gAnalyticsService.startBookingProperty(propertyId);
        this.intercomeService.startBookingProperty(propertyId);
    }

    paymentIntentBookingProperty = (id: number, reservationCode: string) => {
        this.gAnalyticsService.paymentIntentBookingProperty(id, reservationCode);
        this.intercomeService.paymentIntentBookingProperty(id, reservationCode);
    }

    paymentMadeProperty = (id: number, reservationCode: string) => {
        this.gAnalyticsService.paymentMadeProperty(id, reservationCode);
        this.intercomeService.paymentMadeProperty(id, reservationCode);
    }
}
