import {Component} from "react";
import React from "react";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {Grid} from "@mui/material";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Loading} from "app/modules/common/layout/components/loading";
import {inject, provider} from "react-ioc";
import {ListStore} from "app/modules/main/components/admin/policies/ListStore";
import {observer} from "mobx-react";
import {PolicyListModel, PolicyType} from "app/modules/main/api/admin-clients.api";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {Link} from "react-router-dom";

export const PolicyTypeNameMap: Map<PolicyType, string> = new Map<PolicyType, string>([
    [PolicyType.Stay, "Stay"],
    [PolicyType.Cancel, "Cancel"],
    [PolicyType.NoShow, "No Show"],
    [PolicyType.Pets, "Pets"],
    [PolicyType.Other, "Other"],
]);

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 50,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PolicyListModel>) => {
            return (
                <Link to={"/admin/policies/" + (params.row as PolicyListModel).id}>
                    {(params.row as PolicyListModel).id}
                </Link>
            );
        },
    },
    {
        field: 'name',
        headerName: 'Name',
        maxWidth: 350,
        flex: 3,
        renderCell: (params: GridRenderCellParams<PolicyListModel>) => {
            return (
                <Link to={"/admin/policies/" + (params.row as PolicyListModel).id}>
                    {(params.row as PolicyListModel).name}
                </Link>
            );
        },
    },
    {
        field: 'type',
        headerName: 'Type',
        maxWidth: 150,
        flex: 3,
        renderCell: (params: GridRenderCellParams<PolicyListModel>) => (
            PolicyTypeNameMap.get((params.row as PolicyListModel).type)
        )
    },
    {
        field: 'text',
        headerName: 'Text',
        minWidth: 300,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PolicyListModel>) => (
            (params.row as PolicyListModel).text
        )
    }
];

@provider(ListStore)
@observer
export class Index extends Component<any, any> {
    @inject listStore: ListStore;

    render() {
        if(this.listStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={"Policies"} TotalRows={this.listStore.totalRows}/>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={"/admin/policies/new"} Text="New Policy"/>
            </Grid>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.listStore.items}
                    columns={columns}
                    paginationModel={{ page: this.listStore.skipRows/this.listStore.pageSize, pageSize: this.listStore.pageSize }}
                    pageSizeOptions={this.listStore.pageSizeOptions}
                    rowCount={this.listStore.totalRows}
                    loading={this.listStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    rowSelection={false}
                    checkboxSelection={false}
                    onPaginationModelChange={(model) => this.listStore.setPage(model.page, model.pageSize)}/>
            </DataGridBody>
        </DataGridContainer>;
    }

    async componentDidMount() {
        this.listStore.init();
    }
}
