import {Box, Grid, Typography} from "@mui/material";
import React from "react";

const BreakElement = () => <div>
    <br/>
    <br/>
</div>

const Paragraph = (props: { children: any }) => <div>
    <Typography variant={"body1"} sx={{ paddingBottom: 1 }}>
        {props.children}
    </Typography>
</div>

const Title = (props: { children: any }) => <div>
    <BreakElement/>
    <Typography variant={"h4"}>
        {props.children}
    </Typography>
    <BreakElement/>
</div>

const Bold = (props: { children: any }) =>
    <Typography variant={"body1"} component={"span"} sx={{ fontWeight: "bold" }}>
        {props.children}
    </Typography>

export default () => {
    return <Grid container sx={{ width: "100%", paddingTop: "20px" }}>
        <Grid item xs={0} sm={1} md={2} />
        <Grid item xs={12} sm={10} md={8}>
            <Paragraph>
                <Bold>This Rental Agreement was updated at 12:00 a.m. Pacific Daylight Time on Dec 1, 2024.</Bold>
            </Paragraph>
            <Paragraph>
                By booking a reservation with SolArt Group Inc, you agree to all policies, terms, and conditions below.
            </Paragraph>

            <Title>
                Summary of General Rental Policies
            </Title>
            <Paragraph>
                A. The property shall not be used by more than the number of adults and children listed in your reservation.
            </Paragraph>
            <Paragraph>
                B. All guests shall abide by SolArt Group Inc’s good neighbor policy and shall not engage in illegal activity. Quiet hours are from 10 p.m. to 8 a.m.
            </Paragraph>
            <Paragraph>
                C. Pets are not permitted unless specifically noted in your reservation.
            </Paragraph>
            <Paragraph>
                D. Renter must be at least 21 years of age (or the specified minimum age required to rent the property, if different). Guests under the minimum age must be accompanied by a parent or legal guardian.
            </Paragraph>
            <Paragraph>
                E. No smoking is permitted anywhere on the premises.
            </Paragraph>
            <Paragraph>
                F. No commercial photography or filming is permitted on the property. Tents or other structures may not be erected. Use of charcoal grills is not allowed.
            </Paragraph>
            <Paragraph>
                G. Renter is liable for any damage to the property and agrees to accept charges to the card on file should damage occur during occupancy.
            </Paragraph>
            <Paragraph>
                H. <Bold>Cancellation Policy:</Bold> If you cancel within 24 hours of booking, you'll receive a full refund. After that (unless stated otherwise in the home description and unit-specific terms), if you cancel 30 or more days before the first night of your reservation, you'll receive a refund of any rental payments you've made, minus the booking fee.
            </Paragraph>
            <Paragraph>
                I. <Bold>Travel Insurance:</Bold> We recommend that you purchase travel insurance to help protect against financial loss if you must cancel your reservation for a covered reason.
            </Paragraph>
            <Title>
                VACATION RENTAL AGREEMENT
            </Title>
            <Paragraph>
                This Vacation Rental Agreement (“<Bold>Agreement</Bold>”) is for rental of the vacation rental property specified in a confirmed reservation (the “<Bold>Property</Bold>”). This Agreement is between: (1) SolArt Group Inc (“SolArt Group Inc,” “<Bold>we</Bold>,” or “<Bold>us</Bold>”), acting for itself and for the owner of the Property (“<Bold>Owner</Bold>”), and (2) the person who is the responsible renter of the Property (collectively “<Bold>Renter</Bold>” or “<Bold>you</Bold>”).
            </Paragraph>
            <Paragraph>
                By booking your rental of the Property with SolArt Group Inc, you acknowledge that you have read and understand, and agree to be bound by, all terms, conditions, and policies in this Agreement, including any Unit-Specific Terms (collectively, the “<Bold>Terms</Bold>”).
            </Paragraph>
            <Paragraph>
                <Bold>Unit-Specific Terms.</Bold> Certain jurisdictions, resorts, and specific homes require renters to agree to additional terms and conditions (“<Bold>Unit-Specific Terms</Bold>”). We will inform you during the reservation process of any Unit-Specific Terms that apply to rental of the Property. If any provision of Unit-Specific Terms differs from the Terms in this Agreement, the provision of the Unit-Specific Terms will control.
            </Paragraph>
            <Paragraph>
                <Bold>Channel Terms.</Bold> If you are booking through a third-party marketing channel, your booking may be subject to that channel’s own terms and conditions (“<Bold>Channel Terms</Bold>”). If any provision of applicable Channel Terms differs from the Terms in this Agreement, the provision of the Channel Terms will control.
            </Paragraph>

            <Title>
                RENTAL POLICIES AND GENERAL TERMS
            </Title>
            <Paragraph>
                <Bold>A. Minimum Age.</Bold> The “<Bold>Minimum Age</Bold>” to rent the Property is the age specified in any Unit-Specific Terms. If no age is specified in the Unit-Specific Terms, the Minimum Age is 21 years of age (or the minimum age required by law in the jurisdiction where the Property is located, if different). You agree to provide us with proof of age upon request. A parent or legal guardian must accompany all guests who are under the Minimum Age for the entire duration of the rental.
            </Paragraph>
            <Paragraph>
                <Bold>B. Responsible Renter.</Bold> As the Renter, you agree to be an occupant of the Property for the entire duration of the rental. All other occupants will be family members, friends, other responsible adults over the Minimum Age, or accompanied by a parent or legal guardian. You agree to be solely responsible for your actions and the actions of all family members, guests, and invitees (collectively, “<Bold>Occupants</Bold>”) present at the Property at any time during your stay, and to ensure that all Occupants understand and comply with the Terms.
            </Paragraph>
            <Paragraph>
                <Bold>C. Good Neighbor Policy.</Bold> Because the Property is a privately owned home, all Occupants must comply with this good neighbor policy. Please treat the Property with the same care you would use with your own residence and leave it in the same condition it was in when you arrived. To prevent theft of or damage to furnishings or your personal property, you agree to close and lock doors and windows when you are not present at the Property and upon check-out. You and other Occupants agree to conduct yourselves throughout your stay in a manner that is respectful of and not disruptive to neighbors, traffic flow, or the community and that will not prompt complaints to SolArt Group Inc from police, neighbors, or neighborhood or homeowner associations. Noise audible outside the Property is prohibited between 10 p.m. and 8 a.m. You and other Occupants agree to abide by all applicable parking restrictions and limitations.
            </Paragraph>
            <Paragraph>
                <Bold>D. Maximum Occupancy.</Bold> The number of people present at the Property may not exceed the maximum posted occupancy unless we have given you advance written permission to hold an event at the Property, in which case the number of people present at the Property may not exceed the maximum number of people authorized by SolArt Group Inc.
            </Paragraph>
            <Paragraph>
                <Bold>E. No Smoking.</Bold> No smoking is permitted at the Property (including porches, decks or outdoor areas) at any time.
            </Paragraph>
            <Paragraph>
                <Bold>F. No Permanent Residence.</Bold> You agree that your use of the Property is on a temporary and transient basis only; that you may not use the Property as a permanent residence; and that your permanent residence is and will remain elsewhere than at the Property.
            </Paragraph>
            <Paragraph>
                <Bold>G. Animals.</Bold> No animals or pets of any kind are permitted at the Property except (1) as specifically authorized in your reservation, or (2) bona fide service animals that we are required by law to allow. Emotional support animals are not permitted except as authorized pets. You agree that a prohibition on animals is not a guarantee than an animal has not been inside the Property or that the Property is free of animal or pet allergens.
            </Paragraph>
            <Paragraph>
                <Bold>H. Events and Commercial Photography; Structures; Charcoal Grills.</Bold> Events and commercial photography or filming are prohibited at the Property without our express written permission. (If approved, additional conditions and fees may apply.) No tents or other structures may be erected on the property without our express written permission. The use of charcoal grills is not permitted.
            </Paragraph>
            <Paragraph>
                <Bold>I. Criminal Activity Prohibited.</Bold> Use of the Property for any criminal activity is prohibited and may result in fines, prosecution, and/or your immediate removal from the Property. This prohibition extends to use of the Property’s internet service, if any, for criminal activity, including but not limited to unlawful downloads of copyrighted material, including movies, music, software, or other material. We may cooperate with any investigation of alleged criminal activity that occurred at the Property during your stay.
            </Paragraph>
            <Paragraph>
                <Bold>J. Payment Terms; Fraud.</Bold> If you are paying by credit card you represent that you are the account holder or an authorized user of the account. If for any reason, SolArt Group Inc believes your payment may be refused by the card processor, SolArt Group Inc may ask you for a different form of payment. Upon SolArt Group Inc’s request, you will immediately provide another form of payment and adequate proof that you are the account holder or an authorized user of the account used for payment (such as a government-issued ID that matches the name on the account). SolArt Group Inc may cancel your reservation or remove you and all Occupants from the Property immediately if you fail to provide a form of payment that can be validated by SolArt Group Inc. We may share your credit card information and other information about you with law enforcement if we suspect fraud.
            </Paragraph>
            <Paragraph>
                <Bold>K. Consequences of Breach; Charges for Damage.</Bold> Any failure by you or any other Occupants to comply with any of the terms, conditions, or policies above is a breach of this Agreement and may result in a forfeiture of your rights to rent the Property, up to and including immediate removal from the Property without refund and with or without the assistance of law enforcement. SolArt Group Inc may terminate this Agreement for breach, and if you are notified of such termination you agree to leave the Property immediately. In addition, you are responsible for, and you authorize us to bill your credit card on file for the full amount of: (1) any damage or loss that occurs at the Property during your stay; (2) a charge of up to $1,000 for violations of the pet policy, including for bringing more pets or different types of pets than specifically authorized; (3) an additional cleaning fee of up to $500 (or the actual cost of cleaning services, if greater) for violations of any of the guest policies above (other than the pet policy) or for excessive cleaning required by the acts or omissions of any Occupant during your stay; and (4) any fines issued by police, other government officials or agencies, utility providers, and/or homeowner associations for violation of any law, ordinance, or rule during your stay, and any damages (which may exceed the amount of fines) that result from the violation. If any unauthorized download of copyrighted material via the Property’s Internet service occurs during the period of your stay, you agree that we may share your contact information with the internet service provider or any other party we believe, for any reason, has enforcement rights.
            </Paragraph>

            <Title>
            ADDITIONAL TERMS AND CONDITIONS
            </Title>
            <Paragraph>
                <Bold>L. Cancellation Policy; No Shows.</Bold> Unless stated otherwise in the home description and unit-specific terms, rental payments are fully refundable (less the amount of SolArt Group Inc’s booking fee, which is earned on receipt and becomes nonrefundable 24 hours after booking) for cancellations received 30 or more days before the first night of your reservation. No full or partial refunds will be granted for no-shows or if you cancel less than 30 days before the first night of your reservation, arrive after the first night of your reservation, or depart before the last night of your reservation. Except as expressly provided in this Agreement, no refunds or compensation will be given and SolArt Group Inc shall not be liable to you for failure to make the property available for occupancy if the property is unavailable or becomes partially or wholly unusable for any reason outside SolArt Group Inc’s control, including but not limited to adverse weather conditions, natural disasters, mechanical failures, evacuation orders or other acts of government agencies, or utility outages. SolArt Group Inc advises guests to obtain appropriate and comprehensive travel insurance that covers Renter and all Occupants and that includes coverage for personal injury, illness or disease, and property damage or loss.
            </Paragraph>
            <Paragraph>
                <Bold>M. Travel Insurance.</Bold> SolArt Group Inc advises guests to obtain appropriate and comprehensive travel insurance that covers Renter and all Occupants and that includes coverage for personal injury, illness or disease, and property damage or loss, and to help protect against financial loss if you must cancel your reservation for a covered reason.
            </Paragraph>
            <Paragraph>
                <Bold>N. Early Check-In/Late Check-Out.</Bold> Early check-in or late check-out may be available; additional charges may apply. If you do not vacate by check-out time (or late check-out time as agreed by SolArt Group Inc), you authorize us to bill your credit card on file for a late departure fee of up to one night’s rental and we may remove all Occupants and their personal property from the Property.
            </Paragraph>
            <Paragraph>
                <Bold>O. Maintenance or Housekeeping Issues; Property Conditions.</Bold> The Property is provided as is, and we are not responsible for the inoperability or unavailability of any amenities. You agree to contact SolArt Group Inc, using the contact information provided in your confirmation email, as soon as you notice any maintenance or housekeeping problem, or any potentially hazardous condition, at the Property, or if any incident occurs at the Property that is related to such a problem or condition. You further agree to give SolArt Group Inc a reasonable amount of time to respond to your report and to cooperate with SolArt Group Inc’s efforts to address the concern or provide a remedy. We will take reasonable and appropriate steps to remedy any reported problem as soon as practicable. We may enter the Property at your invitation to remedy any problem you report, at reasonable times and with reasonable notice to inspect, maintain, or repair the Property, and to address any situation that we reasonably deem an emergency that threatens persons or property. You acknowledge that if the Property has access to amenities that are shared with other properties, such as a shared pool, hot tub, parking lot, or fitness center, that the availability and condition of those amenities is outside SolArt Group Inc’s control.
            </Paragraph>
            <Paragraph>
                <Bold>P. Choice of Law; Jurisdiction and Venue; Dispute Resolution.</Bold>
            </Paragraph>
            <Paragraph>
                <Bold>P.1. Choice of Law.</Bold> Unless otherwise specified in any Unit-Specific Terms or if a different choice of law is required by the law of the jurisdiction in which the Property is located, this Agreement shall be governed by laws of the State of California, U.S.A, without regard to its conflict-of-law provisions.
            </Paragraph>
            <Paragraph>
                <Bold>P.2. Dispute Resolution and Arbitration Agreement: United States.</Bold> If (a) your claim relates in any way to a reservation for or stay at a Property located in the United States, or (b) you bring any other claim against SolArt Group Inc in the United States (to the extent permitted by this Agreement), then you agree to resolve any dispute arising out of or relating in any way to this Agreement as follows:
            </Paragraph>
            <Paragraph>
                <Bold>a) Agreement to Arbitrate.</Bold> Except as otherwise provided in this section, you and SolArt Group Inc mutually agree that any controversy or claim arising out of or relating to this contract, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association ("AAA") in accordance with its Consumer Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Payment of filing, administration and arbitrator fees will be governed by the AAA's rules. To begin an arbitration, a party must send a letter to the other party requesting arbitration and describing the claim. If SolArt Group Inc requests arbitration, it will send the letter to your most recent email or physical address in SolArt Group Inc’s records. If you request arbitration, you must send the letter to SolArt Group Inc’s registered agent in the state in which the Property you reserved or stayed at is located, or if the dispute does not involve a reservation for or stay at a specific property, to SolArt Group Inc’s registered agent in the state of California.
            </Paragraph>
            <Paragraph>
                <Bold>b) Place of Arbitration.</Bold> Except as otherwise required by AAA's Consumer Arbitration Rules, the arbitration shall take place in the county in which the Property is located, or in Los Angeles, California, if the dispute does not involve a reservation for or stay at a specific property, or in such other location as you and SolArt Group Inc may mutually agree.
            </Paragraph>
            <Paragraph>
                <Bold>c) Exceptions to Arbitration Requirement.</Bold> As the only exceptions to the parties' agreement to submit all disputes to binding arbitration as provided herein, SolArt Group Inc and Renter both retain the right to pursue: (1) in small claims court in the county in which the Property is located (or if the dispute does not involve a reservation for a specific property, the small claims court for Los Angeles, California), any claim that is within that court’s jurisdiction, and the parties consent to the personal jurisdiction and venue of such court, and SolArt Group Inc further consents to Renter's appearance by telephone or video in lieu of personal appearance, if allowed under applicable court rules; and (2) a suit in any court with jurisdiction to enjoin (whether by temporary, preliminary, or permanent injunctive relief) infringement or other misuse of intellectual property rights.
            </Paragraph>
            <Paragraph>
                <Bold>P.3. Dispute Resolution: Properties Outside the United States.</Bold>
            </Paragraph>
            <Paragraph>
            If your claim relates in any way to a reservation for or stay at a Property located outside of the United States, you and SolArt Group Inc agree to bring any action in the courts of the country in which the Property is located, or in such other location and forum as you and SolArt Group Inc may mutually agree.
            </Paragraph>
            <Paragraph>
                <Bold>P.4. Class Action and Jury Trial Waiver. The parties agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration, the parties waive any right to a jury trial.</Bold>
            </Paragraph>
            <Paragraph>
                <Bold>Q. Acknowledgment & Disclaimer; Limitation of Liability.</Bold>
            </Paragraph>
            <Paragraph>
                <Bold>Q.1. Acknowledgment & Disclaimer. You acknowledge the Property may have features, amenities, and conditions that are unfamiliar to you and other Occupants you invite to the Property. You further acknowledge that your use of the Property and its amenities may carry inherent risk, including risk of bodily injury, illness or disease, disability, or death. For example, some Properties include natural habitats for wildlife, insects, and pests that may expose you to injury or disease; or docks, stairways, porches, ledges, cliffs, hot tubs, and other unmarked natural or manmade features, amenities, and conditions that carry inherent risk. You agree that, by using the Property or its amenities, you voluntarily and willfully assume those risks and assume full responsibility for the choices you make before, during and after your use of the Property and its amenities. You also acknowledge and agree that you and any other adult Occupants are solely responsible for closely supervising and protecting the health and safety of any and all Occupants that are minors, throughout the duration of your stay.</Bold>
            </Paragraph>
            <Paragraph>
                <Bold>Q.2. Disclaimer of Warranties. TO THE MAXIMUM EXTENT PERMITTED BY LAW, SOLART GROUP INC AND OWNER EACH EXPRESSLY DISCLAIM ANY WARRANTIES OF SAFETY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, AND AS TO THE ADEQUACY OF THE DIRECTIONS AND WARNINGS PROVIDED TO YOU.</Bold>
            </Paragraph>
            <Paragraph>
                <Bold>Q.3. Limitation of Liability. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO CASE SHALL THE OWNER OF THE PROPERTY OR SOLART GROUP INC, NOR THEIR AFFILIATES, NOR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR OWNERS, BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY, ILLNESS OR DISEASE, EMOTIONAL DISTRESS, OR DAMAGE TO PROPERTY, ARISING OUT OF OR IN CONNECTION WITH YOUR STAY AT THE PROPERTY. THIS LIMITATION APPLIES TO ALL CLAIMS FOR DAMAGES WHETHER BASED ON A THEORY OF WARRANTY, CONTRACT, TORT (INCLUDING ORDINARY NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, EVEN IF SolArt Group Inc HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE AND EVEN IF THE LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</Bold>
            </Paragraph>
            <Paragraph>
                <Bold>R. Indemnity.</Bold> You agree to hold harmless and indemnify SolArt Group Inc and Owner, and their respective affiliates, for and from all claims for property damage, personal injury, illness or disease, or monetary loss resulting from your actions or omissions, and the actions or omissions of other Occupants, during or relating to your stay.
            </Paragraph>
            <Paragraph>
                <Bold>S. Substitution of Property.</Bold> On rare occasions, due to ownership changes, properties being removed from rental use, or a need for extensive repairs or maintenance, the Property may not be available for rental on the dates of your reservation. In this unlikely event, SolArt Group Inc reserves the right to substitute a comparable property. If comparable accommodations are not available, you will have the option of (1) selecting from other available properties (in which case you are responsible for, or will receive the benefit of, any difference in cost) or (2) receiving a complete refund of your reservation payment. In the event of a substitution of rental properties, all Terms in this Agreement, plus any Unit-Specific Terms specific to the substituted property, shall apply to your stay in the substituted property.
            </Paragraph>
            <Paragraph>
                <Bold>T. Assignment.</Bold> SolArt Group Inc may assign this Agreement or any of its rights, or delegate any of its duties under this Agreement, at any time without your consent.
            </Paragraph>
            <Box sx={{ display: "none" }}>
                <Paragraph>
                    <Bold>U. Sex Offender Registry Check.</Bold> In certain U.S. markets, we are required to run a search of the U.S. sex offender registry, consistent with applicable law. In connection with this Agreement, you authorize SolArt Group Inc to order a sex offender registry check about you through a third-party consumer reporting agency: Guest Ranger LLC, 15475 N Greenway Hayden Loop b2, Scottsdale, Arizona 85260 / phone 480-628-0010 / Guestranger.com. California Residents: Contact SolArt Group Inc at bg.check@SolArt Group Inc.com if you would like a free copy of your sex offender registry check report. Washington State Residents: You have the right to request from the consumer reporting agency a written summary of your rights and remedies under the Washington Fair Credit Reporting Act.
                </Paragraph>
            </Box>
            <BreakElement/>
            <BreakElement/>
        </Grid>
        <Grid item xs={0} sm={1} md={2} />
    </Grid>
}
