import React, {Component} from "react";
import {provider} from "react-ioc";
import {ApiClients} from "app/modules/auth/services/AxiosBaseClient";
import {Route, Routes} from "react-router-dom";
import LoginIndex from "app/modules/auth/components/login/index";
import ResetPasswordIndex from "app/modules/auth/components/reset-password/index";
import SetPasswordIndex from "app/modules/auth/components/set-password/index";
import ConfirmEmailIndex from "app/modules/auth/components/confirm-email/index";
import RegisterIndex from "app/modules/auth/components/register/index";
import AccessDenied from "app/modules/auth/access-denied";
import postscribe from "postscribe"

export const centerBoxStyle = {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

@provider(ApiClients)
export class IndexAuth extends Component<any, any> {
    render() {
        return <React.Fragment>
            <div id={"scripts"}></div>
            <Routes>
                <Route path="/accessDenied" element={<AccessDenied />}/>
                <Route path="/forgot-password" element={<ResetPasswordIndex />}/>
                <Route path="/reset-password" element={<SetPasswordIndex />}/>
                <Route path="/register" element={<RegisterIndex />}/>
                <Route path="/confirm-email" element={<ConfirmEmailIndex />}/>
                <Route path="/*" element={<LoginIndex />}/>
            </Routes>
        </React.Fragment>;
    }

    componentDidMount() {
        postscribe('#scripts', '<script src="https://www.google.com/recaptcha/api.js?render=6LfTcPYoAAAAAFvlHS-goH_RZQLLCaI70WJDB4pX" async defer></script>');
    }
}
