import {observer} from "mobx-react";
import React from "react";
import {ReservationAccountModel} from "app/modules/main/api/main-clients.api";
import {Divider, Grid, Stack, Typography} from "@mui/material";
import moment from "moment";
import {BoxCenter} from "app/modules/common/layout/components/containers";

export default observer((props : { details: ReservationAccountModel }) => {
    return <React.Fragment>
        <Grid item xs={6} sm={6} md={5}>
            <Stack sx={{ textAlign: { xs: "left", sm: "left", md: "center" } }}>
                <Typography variant={"body2"}><b>CHECK IN</b></Typography>
                <Typography variant={"body2"}>{moment.utc(props.details.from).format("ll - LT")}</Typography>
            </Stack>
        </Grid>
        <Grid item xs={0} sm={0} md={2} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <BoxCenter sx={{ height: "100%" }}><Divider sx={{ width: "100%" }}/></BoxCenter>
        </Grid>
        <Grid item xs={6} sm={6} md={5}>
            <Stack sx={{ textAlign: { xs: "right", sm: "right", md: "center" } }}>
                <Typography variant={"body2"}><b>CHECK OUT</b></Typography>
                <Typography variant={"body2"}>{moment.utc(props.details.to).format("ll - LT")}</Typography>
            </Stack>
        </Grid>
    </React.Fragment>
})
