import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {action, makeObservable, observable} from "mobx";
import {ReservationModel} from "app/modules/main/api/admin-clients.api";

export class DetailsStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    bookId: number = null;
    reservation: ReservationModel = null

    constructor() {
        super();

        makeObservable(this, {
            bookId: observable,
            reservation: observable,

            cancel: action,
            confirm: action,
        });
    }

    cancel = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.calendarClient.cancelReservation(this.bookId, "Canceled");
            this.reservation = await this.apiClients.calendarClient.getReservationDetails(this.bookId);
        });
    }

    confirm = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.calendarClient.confirmReservation(this.bookId);
            this.reservation = await this.apiClients.calendarClient.getReservationDetails(this.bookId);
        });
    }

    init = async (bookId: number) => {
        this.bookId = bookId;

        await this.wrapInitLoadingApiCall(async () => {
            this.reservation = await this.apiClients.calendarClient.getReservationDetails(this.bookId);
        });
    }
}
