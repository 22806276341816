import {observer} from "mobx-react";
import {Box, Button, Card, Grid, Stack, Typography} from "@mui/material";
import React from "react";
import ContactsIcon from '@mui/icons-material/Contacts';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import {BoxCenter} from "app/modules/common/layout/components/containers";
import Details from "./details";
import {useInstance} from "react-ioc";
import {BookStore} from "app/modules/main/components/main/book/BookStore";
import {useNavigate} from "react-router-dom";
import {SectionTextField} from "app/modules/common/details/text-field";
import {MainStore} from "app/modules/main/stores/MainStore";
import {LoadingButton} from "@mui/lab";

export default observer(() => {
    const navigate = useNavigate();
    const bookStore = useInstance(BookStore);
    const mainStore = useInstance(MainStore);
    let formDetail = <Card sx={{ width: "100%" }}>
            <Box sx={{ p: 2 }}>
            <Stack direction={"row"} sx={{ pb: 2 }}>
                <BoxCenter><ContactsIcon/></BoxCenter>
                <BoxCenter><Typography variant={"h6"} sx={{ pl: 1 }}>Contact Info</Typography></BoxCenter>
            </Stack>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}><SectionTextField
                    label={"First Name"}
                    field={bookStore.formState.$.firstName}
                    fullWidth
                /></Grid>
                <Grid item xs={12} sm={12} md={6}><SectionTextField
                    label={"Last Name"}
                    field={bookStore.formState.$.lastName}
                    fullWidth
                /></Grid>
                <Grid item xs={12}><SectionTextField
                    label={"Email"}
                    field={bookStore.formState.$.email}
                    fullWidth
                    type={"email"}
                /></Grid>
                <Grid item xs={12}><SectionTextField
                    label={"Phone"}
                    field={bookStore.formState.$.phone}
                    fullWidth
                    type={"phone"}
                /></Grid>
            </Grid>
        </Box>
    </Card>;

    let formPromoCode = <Card sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>
            <Stack direction={"row"} sx={{ pb: 2 }}>
                <BoxCenter><LocalOfferIcon/></BoxCenter>
                <BoxCenter><Typography variant={"h6"} sx={{ pl: 1 }}>Promo Code</Typography></BoxCenter>
                <Box/>
            </Stack>
            <Grid container spacing={1}>
                <Grid item xs={12}><SectionTextField
                    label={"Promo Code"}
                    field={bookStore.formPromoCodeState.$.promoCode}
                    fullWidth
                /></Grid>
                <Grid item xs={12} textAlign={"center"}>
                    <LoadingButton loading={bookStore.isLoading} variant={"text"} onClick={bookStore.applyPromoCode}>Apply</LoadingButton>
                </Grid>
            </Grid>
        </Box>
    </Card>;

    let continueStep = <Button variant={"contained"}
                               color={"warning"}
                               sx={{ fontSize: "12px", width: { xs: "100%", sm: "100%", md: "30%" }, marginLeft: { xs: "0", sm: "0", md: "70%" }, borderRadius: 0, height: "54px" }}
                               onClick={() => bookStore.finishContactStep()}>Continue</Button>;

    let loginButton = <Box>
        <Stack direction={"column"} spacing={2}>
            <Typography variant={"body2"}>Please login to proceed with booking.</Typography>
            <Button variant={"contained"}
                    color={"warning"}
                    sx={{ fontSize: "12px", width: { xs: "100%", sm: "100%" }, marginLeft: { xs: "0", sm: "0" }, borderRadius: 0, height: "54px" }}
                    onClick={() => navigate("/login?book=1")}>LOGIN</Button>
        </Stack>
    </Box>;

    let backButton = <Button variant={"text"} sx={{ fontSize: "12px" }} onClick={() => navigate("/search/" + 1)}>{"<"} Back to details</Button>;

    return (<Grid container>
        <Grid item xs={12} sx={{ display: { xs: "block", md: "none" }, p: 1 }}>
            <Grid container>
                <Grid item xs={12}>{backButton}</Grid>
                <Grid item xs={12}><Details bookInfo={bookStore.bookInfo}/></Grid>
                {mainStore.userData.isAuthenticated && <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>{formPromoCode}</Grid>}
                {mainStore.userData.isAuthenticated && <Grid item xs={12}>{formDetail}</Grid>}
                {mainStore.userData.isAuthenticated && <Grid item xs={12}>{continueStep}</Grid>}
                {!mainStore.userData.isAuthenticated && <Grid item xs={12}>{loginButton}</Grid>}
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "block" }, pl: 2, pr: 2 }}>
            <Grid container spacing={1}>
                <Grid item xs={0} sm={1} md={1} lg={2} xl={3}></Grid>
                <Grid item xs={12} sm={10} md={10} lg={8} xl={6} sx={{ pr: 2 }}>
                    <div>{backButton}</div>
                </Grid>
                <Grid item xs={0} sm={1} md={1} lg={2} xl={3}></Grid>

                <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
                <Grid item xs={6} sm={6} md={5} lg={4} xl={3} sx={{ pr: 2 }}>
                    {mainStore.userData.isAuthenticated && <Stack spacing={2}>
                        {formDetail}
                    </Stack>}
                    {mainStore.userData.isAuthenticated && <Stack spacing={2} sx={{ pt: 2 }}>
                        {formPromoCode}
                    </Stack>}
                    {mainStore.userData.isAuthenticated && <Box sx={{ pt: 1 }}>
                        {continueStep}
                    </Box>}
                    {!mainStore.userData.isAuthenticated && <Box sx={{ pt: 1 }}>{loginButton}</Box>}
                </Grid>
                <Grid item xs={6} sm={6} md={5} lg={4} xl={3}><Details bookInfo={bookStore.bookInfo}/></Grid>
                <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
            </Grid>
        </Grid>
    </Grid>);
});
