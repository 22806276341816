import {Component} from "react";
import {inject} from "react-ioc";
import {MainStore} from "app/modules/main/stores/MainStore";
import React from "react";
import {Route, Routes} from "react-router-dom";
import ListIndex from "app/modules/main/components/account/book/list";
import DetailsIndex from "app/modules/main/components/account/book/details";

export class Index extends Component<any, any> {
    @inject mainStore: MainStore;

    render() {
        return <Routes>
            <Route path="/book/:id" element={<DetailsIndex/>} />
            <Route path="/" element={<ListIndex/>} />
        </Routes>;
    }

    async componentDidMount() {

    }
}
