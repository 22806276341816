import React, {Component} from "react";
import {inject, Provider} from "react-ioc";
import {observer} from "mobx-react";
import {Loading} from "app/modules/common/layout/components/loading";
import {PaymentCompleteStore} from "app/modules/main/components/main/book/PaymentCompleteStore";
import {useParams} from "react-router";
import Details from "app/modules/main/components/common/details";
import {Divider, Grid, Typography} from "@mui/material";
import StayDetails from "app/modules/main/components/common/details/stay-details";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import ReservationCode from "app/modules/main/components/common/details/reservation-code";

import PaymentDetails from "app/modules/main/components/common/details/payment-details";

@Provider(PaymentCompleteStore)
@observer
class Index extends Component<any, any> {
    @inject paymentCompleteStore : PaymentCompleteStore;

    render() {
        if(this.paymentCompleteStore.isInitLoading) {
            return <Loading>
                <Typography sx={{ fontWeight: 'bold' }} variant="body1">Payment is processing, it can take up to 3 minutes (Please wait).</Typography>
                <br/>
            </Loading>
        }

        let stayDetails = <Grid container spacing={2}>
            <Grid item xs={12}><Divider sx={{ mb: 1, p: 1 }}/></Grid>
            <StayDetails details={this.paymentCompleteStore.reservationInfo}/>

            <Grid item xs={12}>
                <BoxCenter>
                    <Divider sx={{ mb: 2, p: 1, width: 150 }}/>
                </BoxCenter>
                <ReservationCode reservationCode={this.paymentCompleteStore.reservationInfo.reservationCode}/>

                <BoxCenter>
                    <Divider sx={{ mb: 2, p: 1, width: 150 }}/>
                </BoxCenter>

                <PaymentDetails details={this.paymentCompleteStore.reservationInfo}/>
            </Grid>
        </Grid>;

        return <React.Fragment>
            <Details info={this.paymentCompleteStore.reservationInfo.publicDetails} additionalBlock={stayDetails} backButton={<br/>} />
        </React.Fragment>;
    }

    async componentDidMount() {
        const { code } = this.props.urlParams;
        await this.paymentCompleteStore.init(code);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
