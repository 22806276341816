import {Box, Grid, Typography} from "@mui/material";
import React from "react";

export const LocationRenderOption = (props, item) => <li {...props} key={`${item.city}, ${item.state}`}>
    <Grid container alignItems="center">
        <Grid item sx={{width: '100%', wordWrap: 'break-word'}}>
            <Box
                component="span"
                sx={{fontWeight: 'bold'}}
            >
                {item.city}
            </Box>
            <Typography variant="body2" color="text.secondary">
                {item.state}, USA
            </Typography>
        </Grid>
    </Grid>
</li>
