import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {int, required} from "app/modules/common/form/validators";
import {action, makeObservable, observable, runInAction} from "mobx";
import {
    PropertyListFilters,
    PropertyPromoCodeEditModel,
    PropertyPromoCodeModel, PropertyShortListModel
} from "app/modules/main/api/admin-clients.api";
import {Moment} from "moment";

export class DetailsStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    promoCodeId: number = null;
    active: boolean = true;
    propertyReservationId: number | null = null;
    properties: PropertyShortListModel[] | null = null;

    promoCode: string = null;

    formState = new FormState({
        promoCode: new FieldState(''),
        amount: new FieldState<number>(null).validators(required, int),
        expiredAt: new FieldState<Moment>(null).validators(required),
        minNights: new FieldState<number>(1).validators(int),
        propertyId: new FieldState<number>(null).validators(required),
        assignedToUserId: new FieldState<string>(null),
    });

    constructor() {
        super();

        makeObservable(this, {
            promoCodeId: observable,
            promoCode: observable,
            active: observable,
            properties: observable,
            propertyReservationId: observable,

            save: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if (this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            let model = new PropertyPromoCodeEditModel({
                promoCode: this.formState.$.promoCode.value,
                amount: +this.formState.$.amount.value,
                assignedToUserId: this.formState.$.assignedToUserId.value,
                expiredAt: this.formState.$.expiredAt.value,
                minNights: this.formState.$.minNights.value,
                propertyId: this.formState.$.propertyId.value,
            });

            if(!this.promoCodeId) {
                this.promoCodeId = await this.apiClients.propertyPromoCodeClient.createPropertyPromoCode(model);

                runInAction(() => {
                    this.isSavedSuccessfully = true;
                });
            }
        });
    }

    deactivate = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.propertyPromoCodeClient.deactivate(this.promoCodeId);

            runInAction(() => {
                this.active = false;
            });
        })
    }

    init = async (id?: number) => {
        this.promoCodeId = id;

        await this.wrapInitLoadingApiCall(async () => {
            this.properties = await this.apiClients.adminPropertyClient.getShortList(new PropertyListFilters())

            if(this.promoCodeId > 0) {
                let result : PropertyPromoCodeModel = await this.apiClients.propertyPromoCodeClient.getDetails(this.promoCodeId);

                runInAction(() => {
                    this.promoCode = result.promoCode;
                    this.active = result.active;

                    this.propertyReservationId = result.propertyReservationId;

                    this.formState.$.promoCode.value = result.promoCode;
                    this.formState.$.amount.value = result.amount;
                    this.formState.$.assignedToUserId.value = result.assignedToUserId;
                    this.formState.$.expiredAt.value = result.expiredAt;
                    this.formState.$.minNights.value = result.minNights;
                    this.formState.$.propertyId.value = result.propertyId;
                });
            }
        });
    }
}
