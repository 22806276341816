import * as React from "react";
import {provider, inject, useInstance} from "react-ioc";
import "react-widgets/styles.css";
import 'draft-js/dist/Draft.css';
import 'react-calendar-timeline/lib/Timeline.css'
import "react-image-gallery/styles/css/image-gallery.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./main.css";
import { observer } from "mobx-react";
import {HistoryStore} from "app/stores/HistoryStore";

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
    'd4b7472e959f33e60c7e04cc01d011fbTz03OTUxMCxFPTE3MzI3MjkxNzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

import Moment from 'moment'
import {Route, Routes} from "react-router-dom";
import { AdapterDateFns as AdapterDateFnsPro } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { LocalizationProvider as LocalizationProvider } from '@mui/x-date-pickers';
Moment.locale('en')

import "react-datepicker/dist/react-datepicker.css";
import {ThemeProvider} from "@mui/styles";
import {createTheme} from "@mui/material";

import { Index } from "app/modules/main/index";
import { IndexAuth } from "app/modules/auth/index";
import {ErrorHandler} from "app/modules/common/layout/error-handler";
import {NotificationHandler} from "app/modules/common/layout/notification-handler";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogContainer} from "app/modules/common/dialog/dialog.container";
import {LocalStorageService} from "app/modules/common/stores/LocalStorageService";
import {useLocation} from "react-router";
import {useEffect} from "react";
import {IntercomeService} from "app/modules/main/stores/IntercomeService";
import {AnalyticsService} from "app/modules/main/stores/AnalyticsService";
import {GAnalyticsService} from "app/modules/main/stores/GAnalyticsService";

const theme = createTheme({});

import ReactGA from 'react-ga';
ReactGA.initialize('G-833WBS2EHK');

@provider(HistoryStore, DialogStore, LocalStorageService, IntercomeService, GAnalyticsService, AnalyticsService)
@observer
export class App extends React.Component<any> {
  @inject historyStore: HistoryStore;

  render() {
    return <LocalizationProviderPro dateAdapter={AdapterDateFnsPro}>
        <LocalizationProvider dateAdapter={AdapterDateFnsPro}>
            <ThemeProvider theme={theme}>
            <Routes>
                <Route path="/auth/*" element={<IndexAuth/>} />
                <Route path="/login/*" element={<IndexAuth/>} />
                <Route path="/*" element={<Index/>} />
            </Routes>
          </ThemeProvider>
          <ErrorHandler/>
          <NotificationHandler/>
          <DialogContainer/>
          <HistoryListener/>
        </LocalizationProvider>
    </LocalizationProviderPro>;
  }

  UNSAFE_componentWillMount() {
    this.historyStore.history = this.props.history;
  }
}

function HistoryListener(){
    let location = useLocation();
    let analyticsService = useInstance(AnalyticsService);

    useEffect(() =>{
        if(window.location.hostname != 'localhost') {
            analyticsService.changeLocation(window.location.pathname);
        }
    },[location]);

    return <React.Fragment></React.Fragment>;
}
